import swal from 'sweetalert';
export default function checkoutGuard(to, from, next) {
    let cart = JSON.parse(localStorage.getItem('cartList'));

    if (cart.length == 0) {
        next('/shop');
        swal({
            toast: true,
            position: 'top-end',
            text: "Please add some product to cart to proceed!!",
            icon: "info",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
            }
        })
    } else {
        next();
    }
}