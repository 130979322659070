<template>
  <footer class="bd-footer py-4 py-md-5 mt-5">
    <div class="container py-4 py-md-5 px-4 px-md-3">
      <div class="row">
        <div class="col-lg-3 col-md-3 mb-3">
          <a class="d-inline-flex align-items-center mb-2 link-dark text-decoration-none" href="/">
            <img :src="store.footerCMSData.logo" :alt="'goldengal jewelry'" />
          </a>
          <ul class="list-unstyled small text-muted">
            <li class="mb-2" v-html="store.footerCMSData.description"></li>
            <li class="mb-1 mt-3">
              <label>Email:</label>
              {{ store.footerCMSData.email }}
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-3 col-lg-2 mb-3">
          <h5 class="ff-montserrat500 mb-5">Information</h5>
          <ul class="list-unstyled ff-montserrat500 text-site-black">
            <li class="mb-1">
              <a
                class="text-site-black fs-14"
                href="javascript:void(0)"
                @click="$router.push('/return-policy')"
              >Return / Exchange</a>
            </li>
            <li class="mb-1">
              <a
                class="text-site-black fs-14"
                href="javascript:void(0)"
                @click="$router.push('/privacy-policy')"
              >Privacy Policy</a>
            </li>
            <li class="mb-1">
              <a
                class="text-site-black fs-14"
                href="javascript:void(0)"
                @click="$router.push('/terms-condition')"
              >Terms Policy</a>
            </li>
            <li class="mb-1">
              <a
                class="text-site-black fs-14"
                href="javascript:void(0)"
                @click="$router.push('/gift-card')"
              >Gift Card</a>
            </li>
            <li class="mb-1">
              <a
                class="text-site-black fs-14"
                href="javascript:void(0)"
                @click="$router.push('/promotional')"
              >Promotion</a>
            </li>
            <li class="mb-1">
              <a
                class="text-site-black fs-14"
                href="javascript:void(0)"
                @click="$router.push('/contact')"
              >Contact Us</a>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-3 col-lg-2 mb-3">
          <h5 class="ff-montserrat500 mb-5">Quick Links</h5>
          <ul class="list-unstyled ff-montserrat500 text-site-black">
            <li class="mb-1" v-for="(data, index) in store.quickLinksData" :key="index">
              <a class="text-site-black fs-14" target="_blank" style="cursor: pointer" :href="data.link">{{ data.name }}</a>
            </li>
          </ul>
        </div>

        <div class="col-6 col-md-3 col-lg-2 mb-3">
          <h5 class="ff-montserrat500 mb-5">Social Pack</h5>
          <ul
            class="list-unstyled ff-montserrat500"
            
          >
            <li class="mb-1" v-for="(data, index) in store.socialPackData" :key="index">
              <a
                class="text-site-black fs-14"
                :href="data.siteLink"
                target="_blank"
              >{{ data.siteName }}</a>
            </li>
          </ul>
        </div>
        
        <div class="col-6 col-md-3 col-lg-3 mb-3">
          <h5 class="ff-montserrat700 mb-5">Subscribe</h5>
          <div class="input-group mb-3">
            <input type="email" v-model="email" class="form-control border-black shadow-none" placeholder="Enter your email" aria-label="enter your email" aria-describedby="email">
            <button @click="addSubscription" class="btn button-border-black text-white" type="button" id="email"> <i class="bi bi-chevron-double-right fs-14"></i></button>
          </div>
          <!-- <ul class="list-unstyled" v-for="(data, index) in store.customProducts" :key="index">
            <li class="mb-2 d-flex gap-3 align-items-center">
              <div>
                <img
                  style="cursor:pointer"
                  :src="data.featuredImage"
                  height="80"
                  width="80"
                  @click="$router.push('/product-details/'+data.id)"
                />
              </div>
              <div>
                <vue3-star-ratings
                  :showControl="false"
                  :starSize="'15'"
                  :disableClick="true"
                  :inactiveColor="'#FAC130'"
                />
                <p class="fs-16 mb-2">{{ data.name }}</p>
                <p class="mb-0 fs-16">
                  <span class="text-site-darkgoldenrod">$ {{ data.price }}</span>
                  <span class="text-site-silver"></span>
                </p>
              </div>
            </li>
          </ul> -->
        </div>
      </div>
      <nav class="navbar navbar-expand justify-content-center py-0">
        <div class>
          <ul class="navbar-nav justify-content-center flex-wrap flex-md-nowrap m-auto">
            <li class="nav-item">
              <a
                class="nav-link ff-montserrat300 fs-16 fs-xs-12 text-site-black"
                aria-current="page"
                href="javascript:void(0)"
                @click="$router.push('/')"
              >Home</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link ff-montserrat300 fs-16 fs-xs-12 text-site-black"
                aria-current="page"
                href="javascript:void(0)"
                @click="$router.push('/shop')"
              >Shop</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link ff-montserrat300 fs-16 fs-xs-12 text-site-black"
                aria-current="page"
                href="javascript:void(0)"
                @click="$router.push('/privacy-policy')"
              >Privacy Policy</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link ff-montserrat300 fs-16 fs-xs-12 text-site-black"
                aria-current="page"
                href="javascript:void(0)"
                @click="$router.push('/terms-condition')"
              >Terms Policy</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link ff-montserrat300 fs-16 fs-xs-12 text-site-black"
                aria-current="page"
                href="javascript:void(0)"
                @click="$router.push('/return-policy')"
              >Return / Exchange</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link ff-montserrat300 fs-16 fs-xs-12 text-site-black"
                aria-current="page"
                href="javascript:void(0)"
                @click="$router.push('/contact')"
              >Support</a>
            </li>
          </ul>
        </div>
      </nav>
      <div class="d-flex justify-content-between mt-4 flex-column flex-sm-row">
        <p
          class="mb-0 ff-montserrat300 align-self-end order-2 order-sm-1"
        >Copyright © <span style="text-decoration:none; color: #000; font-weight: bold;">GoldenGal Jewelry</span> | ( 
          Built by <a style="text-decoration:none; color: #000; font-weight: bold;" target="_blank" href="https://www.neutrix.co">Neutrix</a> 
          - Powered by <a style="text-decoration:none; color: #000; font-weight: bold;" target="_blank" href="https://www.neutrix.systems">Neutrix Systems</a> )</p>
        <img height="36" :src="store.footerCMSData.paymentMethodImage" class="order-1 order-sm-2" alt="GoldenGal Jewelry" />
      </div>
    </div>
  </footer>
</template>

<script src="../js/footer.js"></script>

<style scoped>
@import "../css/footer.css";
</style>
