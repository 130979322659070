<template>
  <div :style="{'background-color':uiComponent.backgroundFontColor}">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <section

      class="home-slider-section py-5 position-relative"
      v-if="Object.values(slider).length > 0"
    >
      <h1
        class="text-center h-fs-100 h-fs-md-80 h-fs-sm-50 ff-heading position-absolute z-index-5 w-100 lh-1 home-slider-section_heading"
        :style="{'color':uiComponent.sectionOneTitleFontColor}"
      >
        {{ pageCms.sectionOneTitle }}
        <br />
        <span class="home-slider-section_heading-bg" :style="{'--heading-bg-color': uiComponent.sliderTitleUnderlineColor}">{{pageCms.sectionOneSubTitle}}</span>
      </h1>
      <div class="container pt-5" >
        <Carousel class="pt-5" :settings="settings" :breakpoints="breakpoints">
          <Slide v-for="(data, index) in slider" :key="index">
            <div class="carousel__item position-relative w-100">
              <img class="w-100" :src="data.image" :alt="pageCms.carouselTitle" />
              <button
                class="btn home-slider-section-btn ff-montserrat400"
                style="width: auto !important;"
                @click="$router.push(data.buttonLink)"
              >
                {{
                data.buttonText
                }}
                <i
                  class="bi bi-arrow-up-right text-site-Red"
                ></i>
              </button>
              <span class="ff-heading carousel__item__text text-site-Red">{{pageCms.carouselTitle}}</span>
            </div>
          </Slide>
          <template #addons>
            <navigation />
            <pagination />
          </template>
        </Carousel>
      </div>
    </section>
    <section class="browser-our-items-section" v-if="Object.values(sectionTwo).length > 0">
        <div class="container">
        <h1
          class="text-site-Red fs-25 fs-xs-23 ff-montserrat400 text-center"
          :style="{'color':uiComponent.sectionTwoTitleFontColor+'!important'}"
        >{{ pageCms.sectionTwoTitle }}</h1>
        <div class="">
          
          <h1
          class="ff-heading h-fs-70 h-fs-md-50 h-fs-sm-40 text-center mb-4 "
          :style="{'color':uiComponent.sectionTwoSubTitleFontColor}"
        >
        
        {{ pageCms.sectionTwoSubTitle }}</h1>
        </div>
       
        <div class="row gy-3">
          <div class="col-12 col-sm-6" v-for="(data, index) in sectionTwo" :key="index">
            <div class="card mb-3">
              <div class="row g-0">
                <div class="col-sm-5">
                  <img
                    v-if="isSafari"
                    :src="data.image"
                    class="img-fluid w-xs-100 mt-5 rounded-start"
                    :alt="data.title"
                  />
                  <img v-else :src="data.image" class="img-fluid w-xs-100 h-100 rounded-start" :alt="data.title" />
                </div>
                <div class="col-sm-7">
                  <div class="card-body h-100 d-flex align-items-center">
                    <div>
                      <h5 class="card-title ff-heading h-fs-42 h-fs-md-30 fs-sm-25  fw-500">
                        {{
                        data.title
                        }}
                      </h5>
                      <p class="card-text" v-html="data.description"></p>
                      <button
                        class="btn bg-site-Red border-site-Red text-white" :style="{'background-color': uiComponent.sectionTwoButtonColor + '!important', 'border-color': uiComponent.sectionTwoButtonColor + '!important'}"
                        @click="$router.push(data.buttonLink)"
                      >{{ data.buttonText }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="create-product-section py-4" v-if="Object.values(sectionThree).length > 0">
      <div class="container" data-aos="fade-up"
     data-aos-duration="2000">
        <div class="row gy-3">
          <div class="col-12 col-md-6 col-lg-7" data-aos="fade-up-left" data-aos-duration="2000">
            <div class="d-flex align-items-center h-100">
              <div>
                <h1
                  class="ff-heading h-fs-70 h-fs-md-50 h-fs-sm-40 "
                  :style="{'color':uiComponent.sectionThreeTitleFontColor}"
                >{{ sectionThree.title }}</h1>
                <p class="ff-montserrat300 fs-16 w-75" v-html="sectionThree.description"></p>
                <button
                  class="btn bg-site-Red border-site-Red text-white" :style="{'background-color': uiComponent.sectionThreeButtonColor + '!important', 'border-color': uiComponent.sectionThreeButtonColor + '!important'}"
                  @click="$router.push(sectionThree.buttonLink)"
                >
                  {{ sectionThree.buttonText
                  }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-5" data-aos="fade-up-right" data-aos-duration="2000">
            <div class="position-relative w-100 h-100" data-aos="fade-up-left" data-aos-duration="2000" >
              <img class="create-product-section-right-bottom-image" :src="sectionThree.imageOne" :alt="sectionThree.title" />
              <div class="create-product-section-right-bottom-image-section">
                <img class="w-100 h-100" :src="sectionThree.imageTwo" :alt="sectionThree.title" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="create-luxury-products" v-if="Object.values(sectionFour).length > 0">
      <div class="container">
        <div class="row gy-3">
          <div class="col-12 col-md-7" data-aos="fade-up-right" data-aos-duration="2000">
            <div class="position-relative z-index-3 ps-5 h-100">
              <div class="create-luxury-products-left-bg"></div>
              <img class="w-100 h-100 ps-sm-0" :src="sectionFour.image" :alt="sectionFour.title" />
            </div>
          </div>
          <div class="col-12 col-md-5" data-aos="fade-up-left" data-aos-duration="2000">
            <div class="h-100 d-flex align-items-center w-100">
              <div class="w-100">
                <h2
                  class="ff-heading h-fs-49 h-fs-md-39 h-fs-sm-30"
                  :style="{'color':uiComponent.sectionFourTitleFontColor}"
                >{{ sectionFour.title }}</h2>
                <p class="ff-montserrat300 fs-16 lh-sm" v-html="sectionFour.description"></p>
                <div class="create-luxury-products-carousel w-100">
                  <Carousel class="pt-5" :settings="settings2" :breakpoints="breakpoints2">
                    <Slide v-for="(data, index) in sectionFourSliderData" :key="index">
                      <div class="carousel__item h-100 w-100" v-if="data.type == 'product'">
                        <div class="card h-100 w-100">
                          <div class="hover">
                            <img
                              :src="data.featuredImage"
                              class="card-img-top"
                              :alt="data.name"
                              height="200"
                            />
                            <div
                              class="hover-show d-flex justify-content-center align-items-center rounded-1"
                              :style="{'background-color': store.hoverColor}"
                            >
                              <div>
                                <a
                                  class="btn btn-outline-secondary border-white text-white text-uppercase ff-montserrat700 me-2"
                                  @click="$router.push('/product-details/' + data.urlSlug)"
                                >
                                  <i class="bi bi-eye"></i>
                                </a>
                                <a
                                  class="btn btn-outline-secondary border-white text-white text-uppercase ff-montserrat700 me-2"
                                  @click="addToWishList(data, 1)"
                                >
                                  <i class="bi bi-heart"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="card-body text-start">
                            <h5 class="card-title ff-montserrat400 text-site-gray-900 lh-sm fs-15">
                              New
                              Collection
                            </h5>
                            <p
                              class="card-text ff-montserrat400 text-site-gray-900 fs-17 mb-1 lh-sm"
                              style="cursor:pointer"
                              @click="$router.push('/product-details/' + data.urlSlug)"
                            >{{ data.name }}</p>
                            <a
                              class="btn border-0 ff-montserrat500 ext-site-gray-900 fs-17 lh-sm text-start p-0"
                              @click="$router.push('/product-details/' + data.urlSlug)"
                            >
                              Shop
                              Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="carousel__item h-100 w-100" v-else>
                        <div class="card h-100 w-100">
                          <div class="hover">
                            <img :src="data.image" :alt="data.name" class="card-img-top" height="200" />
                            <div
                              class="hover-show d-flex justify-content-center align-items-center rounded-1"
                              :style="{'background-color': store.hoverColor}"
                            >
                              <div>
                                <a
                                  class="btn btn-outline-secondary border-white text-white text-uppercase ff-montserrat700 me-2"
                                  @click="$router.push('/gift-card-details/' + data.id)"
                                >
                                  <i class="bi bi-eye"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="card-body text-start">
                            <h5 class="card-title ff-montserrat400 text-site-gray-900 lh-sm fs-15">
                              New
                              Collection
                            </h5>
                            <p
                              class="card-text ff-montserrat400 text-site-gray-900 fs-17 mb-1 lh-sm"
                              style="cursor:pointer"
                              @click="$router.push('/gift-card-details/' + data.id)"
                            >{{ data.name }}</p>
                            <a
                              class="btn border-0 ff-montserrat500 ext-site-gray-900 fs-17 lh-sm text-start p-0"
                              @click="$router.push('/gift-card-details/' + data.id)"
                            >
                              Shop
                              Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </Slide>
                    <template #addons>
                      <navigation />
                    </template>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="latest-arrivals-section py-4" v-if="Object.values(latestProduct).length > 0">
      <div class="container text-center">
        <h1
          class="ff-montserrat400 fs-25 text-site-Red text-uppercase"
          :style="{'color':uiComponent.sectionFiveTitleFontColor+'!important'}"
        >{{ pageCms.sectionFiveTitle }}</h1>
        <h1
          class="ff-heading h-fs-70 h-fs-md-50 h-fs-sm-40"
          :style="{'color':uiComponent.sectionFiveSubTitleFontColor}"
        >{{ pageCms.sectionFiveSubTitle }}</h1>
        <div class="latest-arrivals-section-carousel w-100" data-aos="fade-up"
     data-aos-duration="2000">
          <Carousel :settings="settingsLatest" :breakpoints="breakpointsLatest">
            <Slide v-for="(data, index) in latestProduct" :key="index">
              <div class="carousel__item h-100 w-100">
                <div class="card h-100 w-100 border-0">
                  <div class="hover mb-1 border round_5">
                    <img :src="data.featuredImage" style="height:307px; width:302px;" class="card-img-top" :alt="data.name" />
                    <div
                      class="hover-show d-flex justify-content-center align-items-center rounded-1"
                      :style="{'background-color': store.hoverColor}"
                    >
                      <div>
                        <a
                          class="btn btn-outline-secondary border-white text-white text-uppercase ff-montserrat700 me-2"
                          @click="$router.push('/product-details/' + data.urlSlug)"
                        >
                          <i class="bi bi-eye"></i>
                        </a>
                        <a
                          class="btn btn-outline-secondary border-white text-white text-uppercase ff-montserrat700 me-2"
                          @click="addToWishList(data, 1)"
                        >
                          <i class="bi bi-heart"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-1 text-start">
                    <h5
                      class="card-title ff-montserrat400 fs-20 fs-md-19 fs-sm-18"
                      style="cursor:pointer"
                      @click="$router.push('/product-details/' + data.urlSlug)"
                    ><strong>{{ data.name }}</strong></h5>
                    <p class="card-text ff-montserrat300 text-site-black fs-18 lh-sm">
                      ${{ data.discountPrice>0 ? data.discountPrice : data.price }} &nbsp;
                      <s
                        v-if="data.discountPrice > 0"
                      >${{ data.price }}</s>
                    </p>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </section>
    <section class="py-5" v-if="Object.values(categoryCMS).length > 0">
      <div class="container">
        <ul class="nav justify-content-center gap-4 gap-md-4 border-bottom-1">
          <template v-for="(data, index) in sectionSix" :key="index">
            <li class="nav-item nav-item-border d-flex justify-content-center" style="margin-bottom: -1px;">
              <div
                class="card collection-category-card border-0 align-items-center text-center position-relative py-3"
              >
                <img  height="100" :src="data.image" :alt="data.title" />
                <p class="ff-montserrat400 fs-18 mt-3">{{data.title}}</p>
                <button
                  class="btn collection-category-card-btn text-white"
                  :style="{'background-color': data.buttonColor + '!important', 'border-color': data.buttonColor + '!important',}"
                   @click="$router.push(data.buttonLink)"
                >{{data.buttonText}}</button>
              </div>
            </li>
<!--            <li class="nav-item d-none d-lg-block" v-if="index != 5">-->
<!--              <div class="vr flex-shrink-1 h-100"></div>-->
<!--            </li>-->
          </template>
        </ul>
      </div>
    </section>
    <section class="py-5" data-aos="zoom-in" data-aos-duration="2000">
      <section
        class="presentation-section position-relative"
        v-if="Object.values(sectionSeven).length > 0"
      >
        <img class="w-100 h-100" :src="sectionSeven.backgroundImage" />

        <template v-for="(row, index) in sectionSevenDotsFeature" :key="index">
          <div :style="{'position': 'absolute', 'top': row.top, 'left':row.left}" class="cicle_point">
              <button type="button" class="point_btn" :style="{'background':sectionSeven.productDotCicleColor+'!important'}"></button>
              <div class="presentation-section-dropdown">
               <button class="btn-close d-md-none position-absolute top-0 end-0 shadow-none presentation-section-dropdown-close a"></button>
                <div class="cp text-center"  @click="$router.push(row.productLink)">
                  <img height="120" :src="row.productImage" alt />
                </div>
                <div class="text-center pt-2 cp" @click="$router.push(row.productLink)">
                  <h5
                    class="ff-montserrat400 fs-20 fs-sm-20 fs-xs-18 mb-1 lh-sm">{{ row.productName }}</h5>
                  <p class="ff-montserrat400 fs-16 fs-xs-14 mb-0">${{ row.productPrice }}</p>
                </div>
              </div>
          </div>
        </template>

        <!-- Example split danger button -->
        <!-- <div class="first_point">
          <button type="button" class="point_btn"></button>
          <div class="presentation-section-dropdown">
            <div class>
              <img class="w-100" height="120" :src="sectionSeven.productImage" alt />
            </div>
            <div class="text-center pt-2 cp" @click="$router.push(sectionSeven.productLink)">
              <h5
                class="ff-montserrat400 fs-20 fs-sm-20 fs-xs-18 mb-1 lh-sm"
              >{{ sectionSeven.productName }}</h5>
              <p class="ff-montserrat400 fs-16 fs-xs-14 mb-0">${{ sectionSeven.productPrice }}</p>
            </div>
          </div>
        </div> -->
        <!-- <div class="second_point">
          <button type="button" class="point_btn"></button>
          <div class="presentation-section-dropdown">
            <div class>
              <img class="w-100" height="120" :src="sectionSeven.productTwoImage" alt />
            </div>
            <div class="text-center pt-2 cp" @click="$router.push(sectionSeven.productTwoLink)">
              <h5
                class="ff-montserrat400 fs-20 fs-sm-20 fs-xs-18 mb-1 lh-sm"
              >{{ sectionSeven.productTwoName }}</h5>
              <p class="ff-montserrat400 fs-16 fs-xs-14 mb-0">${{ sectionSeven.productTwoPrice }}</p>
            </div>
          </div>
        </div>
        <div class="third_point">
          <button type="button" class="point_btn"></button>
          <div class="presentation-section-dropdown">
            <div class>
              <img class="w-100" height="120" :src="sectionSeven.productThreeImage" alt />
            </div>
            <div class="text-center pt-2 cp" @click="$router.push(sectionSeven.productThreeLink)">
              <h5
                class="ff-montserrat400 fs-20 fs-sm-20 fs-xs-18 mb-1 lh-sm"
              >{{ sectionSeven.productThreeName }}</h5>
              <p class="ff-montserrat400 fs-16 fs-xs-14 mb-0">${{ sectionSeven.productThreePrice }}</p>
            </div>
          </div>
        </div> -->
      </section>
    </section>
    <!-- <section
      class="shop-section py-3"
      v-if="Object.values(pageCms).length>0 && instaSection.length>0"
    >
      <div class="container" 
           data-aos="fade-up"
           data-aos-duration="2000">
        <div class="text-center">
          <h1
            class="ff-montserrat400 fs-25 text-site-Red"
            :style="{'color':uiComponent.sectionEightTitleFontColor+'!important'}"
          >{{ pageCms.sectionEightTitle }}</h1>
          <h1
            class="ff-heading h-fs-49 h-fs-md-39 h-fs-sm-30 lh-sm"
            :style="{'color':uiComponent.sectionEightSubTitleFontColor}"
          >{{ pageCms.sectionEightSubTitle }}</h1>
          <div class="shop-section-heading-footer"></div>
        </div>
        <div class="shop-section-carousel w-100 pt-5">
          <Carousel :settings="settings4" :breakpoints="breakpoints4">
            <Slide v-for="(data, index) in instaSection" :key="index">
              <div class="carousel__item h-100 w-100">
                <div class="card h-100 shop-section-carousel-card w-100 border-0 rounded-1">
                  <img :src="data.image" class="card-img-top rounded-1 w-100 h-100" :alt="data.name" />
                  <div
                    class="shop-section-carousel-card-hover d-flex justify-content-center align-items-center rounded-1"
                    :style="{'background-color': store.hoverColor}"
                  >
                    <div>
                      <a class="text-white" href target="_blank">
                        <i class="bi bi-instagram fs-25"></i>
                      </a>
                      <br />
                      <br />
                      <a
                        class="btn btn-outline-secondary border-white text-white text-uppercase ff-montserrat700"
                        href="javascript:void(0)"
                        @click="$router.push(data.link)"
                      >{{data.name}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </section> -->

    <Newsletter v-if="store.isNewsLetter==true" :cms="newsletterCms" />
    <HomeSchemaJson :metaKeyword="metaKeyword" :metaDescription="metaDescription"></HomeSchemaJson>

    <div class="featured lazy py-5" v-if="Object.values(pageCms).length>0 && instaSection.length>0" >
      <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.5)">
        <div class="container margin_60">
          <div class="text-center pb-5">
            <h1
              class="ff-montserrat400 fs-25 text-site-Red"
              :style="{'color':uiComponent.sectionEightTitleFontColor+'!important'}"
            >{{ pageCms.sectionEightTitle }}</h1>
            <h1
              class="ff-heading h-fs-49 h-fs-md-39 h-fs-sm-30 lh-sm"
              :style="{'color':uiComponent.sectionEightSubTitleFontColor}"
            >{{ pageCms.sectionEightSubTitle }}</h1>
            <div class="shop-section-heading-footer"></div>
          </div>
          <carousel :settings="instaSettings" :breakpoints="instaBreakpoints">
            <slide v-for="(data, index) in instaData" :key="index">
              <div class="card round-16 h-100 mx-2 flex-row align-items-start card-insta"
                   :style="{'box-shadow':'none !important'}">
                <div>
                  <img class="w-100" :src="data?.media_url"/>

                </div>
                <div class="card-insta-box" @click="openLinkInNewTab(data.permalink)">
                  <div class="card-insta-box-inner">
                    <div class="card-insta-logo">
                      <i class="ti-instagram"></i>
                    </div>
                    <div class="card-insta-caption-text">
                      {{ data?.caption }}
                    </div>
                    <div class="card-insta-box-items">
                      <div class="card-insta-box-items-inner">
                        <div class="card-insta-details">
                          <div class="card-insta-username">Golden Gal</div>
                          <div class="card-insta-date">{{ countPostedTime(data.timestamp) }}</div>
                        </div>
                      </div>
                    </div>
                    <img class="card-insta-avatar"
                         src="https://golden-gal.nyc3.cdn.digitaloceanspaces.com/instagram.png"
                         alt="Golden Gal Jewelry Instagram"/>
                  </div>
                </div>
                <div class="card-insta-bottom-container">
                  <div class="d-flex gap-2" @click="openLinkInNewTab(data.permalink)">
                    <img class="card-insta-bottom-avatar"
                         src="https://golden-gal.nyc3.cdn.digitaloceanspaces.com/instagram.png"
                         alt="Golden Gal Jewelry Instagram"/>
                    <div class="d-flex flex-column gap-1">
                      <div class="card-insta-bottom-username">Golden Gal</div>
                      <div class="card-insta-bottom-date">{{ countPostedTime(data.timestamp) }}</div>
                    </div>
                  </div>
                  <div class="card-insta-bottom-logo">
                    <i class="ti-instagram"></i>
                  </div>
                </div>
              </div>
            </slide>
            <template #addons>
              <navigation/>
              <pagination/>
            </template>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/home.js">
</script>



<style scoped>
@import "../css/home.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";

/*instagram*/
.card-insta {
  height: 300px !important;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background: rgba(23, 23, 23, 0.8);
}

.card-insta-box {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all .3s ease;
}

.card-insta-box-inner {
  align-items: center;
  background: rgba(0, 0, 0, .7);
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  justify-content: center;
  padding: 20px;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 99999;
}

.card-insta-caption-text {
  -webkit-line-clamp: 1;
  font-size: 14px;
  gap: 10px;
  line-height: 22px;
  min-height: 16px;
  width: 100%;
}

.card-insta-bottom-container {
  background-image: linear-gradient(180deg, transparent, #000);
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  padding: 15px;
  position: absolute;
  transition: all .3s ease;
  width: 100%;
  z-index: 1;
}

.card-insta-avatar, .card-insta-bottom-avatar {
  border: 1px solid #eef1f7;
  border-radius: 50%;
  height: 40px;
  min-width: 40px;
  object-fit: cover;
  width: 40px;
}

.card-insta-bottom-username {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #fff;
  display: -webkit-box;
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.card-insta-caption-text, .card-insta-bottom-date {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-insta-bottom-date {
  -webkit-line-clamp: 1;
  color: #d6dae4;
  font-size: 12px;
  line-height: 14px;
  word-break: break-all;
}

.card-insta-logo, .card-insta-bottom-logo {
  font-size: 20px;
  color: #ffffff;
}

.card-insta:hover .card-insta-bottom-container {
  display: none;
}

.card-insta:hover .card-insta-box {
  opacity: 1;
}

.featured .carousel__pagination {
  display: none !important;
}

</style>
