import TabNav from '../../Include/NavTab'
import Dashboard from '../Dashboard/template/Dashboard'
import Order from '../Order/template/Order'
import Address from '../Address/template/Address'
import Account from '../AccountDetail/template/Account'
import CardSettings from '../CardSettings/template/CardSettings'
import ReturnProducts from '../ReturnProducts/template/ReturnProducts'
export default {
    components: {
        TabNav,
        Dashboard,
        Order,
        Address,
        Account,
        CardSettings,
        ReturnProducts
    },
    data() {
        return {}
    },
    async created() {
        this.scrollToTop();
    },
    methods: {

    }
}
