<template>
  <div class="login">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <h1 class="ff-heading h-fs-122 h-fs-md-98 fs-xs-27 text-center py-10">Registration</h1>
    <div class="container">
      <div class="row">
        <form @submit.prevent="register" class="col-xl-8 col-lg-8 col-md-8 col-sm-10 mx-auto form">
          <div class="row pb-4">
            <div class="form-group col-xs-12 col-sm-12">
              <label class="mb-3">Full Name</label>
              <input
                type="text"
                v-model="fullName"
                placeholder="Full Name"
                class="form-control mb-3"
              />
            </div>
            <div class="form-group col-xs-12 col-sm-12">
              <label class="mb-3">Phone</label>
              <input type="text" v-model="phone" placeholder="Phone" class="form-control mb-3" />
            </div>
            <div class="form-group col-xs-12 col-sm-12">
              <label class="mb-3">Email</label>
              <input type="email" v-model="email" placeholder="Email" class="form-control mb-3" />
            </div>
            <div class="form-group col-xs-12 col-sm-12">
              <label class="mb-3">Password</label>
              <input
                type="password"
                v-model="password"
                placeholder="Password"
                class="form-control mb-3"
              />
            </div>
            <div class="form-group col-xs-12 col-sm-12">
              <label class="mb-3">Re-Type Password</label>
              <input
                type="password"
                v-model="confirmPassword"
                placeholder="Re-Type Password"
                class="form-control"
              />
            </div>
            <div class="form-group col-xs-12 col-sm-12 mt-4">
              <input
                type="submit"
                class="btn btn-primary w-100 btn-contact border-site-Red bg-site-Red"
                value="Create Account"
              />
            </div>
            <div class="form-group text-center col-xs-12 col-sm-12 mt-2">
              Have an account?&nbsp;
              <a href="javascript:void(0)">
                <span @click="$router.push('/login')">Go to login</span>
              </a>
            </div>
          </div>
        </form>
      </div>
      <div class="row omb_row-sm-offset-3 omb_loginOr text-center">
        <div class="col-xs-12 col-sm-6 mb-3">
          <hr class="omb_hrOr" />
          <span class="omb_spanOr">or</span>
        </div>
        <div class="social-login">
          <button class="btn google-btn social-btn" type="button" @click="loginGoogle">
            <span>
              <i class="fab fa-google-plus-g"></i>
              Sign in with Google+
            </span>
          </button>
        </div>
      </div>
    </div>
    <RegistrationSchemaJson :metaKeyword="metaKeyword" :metaDescription="metaDescription"></RegistrationSchemaJson>
  </div>
</template>

<script src="../js/registration.js"></script>
