<template>
    <div :style="{'background-color': uiComponent.pageBackgroundColor}">
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <section class="bg-site-gray-700 py-5" v-if="Object.values(pageCMS).length > 0" :style="{'background-color': uiComponent.pageHeaderBackgroundColor +'!important'}">
            <div class="container text-center">
                <h1 class="text-site-black ff-heading h-fs-55 h-fs-md-45 h-fs-sm-35 h-fs-xs-32" :style="{'color': uiComponent.pageHeaderFontColor + '!important' }">{{ pageCMS.pageTitle }}</h1>
            </div>
        </section>
        <section v-if="Object.values(pageCMS).length > 0">
            <div class="container py-3">
                <h1 class="text-center ff-heading h-fs-122 h-fs-md-98 h-fs-sm-69 fs-xs-26" :style="{'color': uiComponent.sectionEightTitleFontColor + '!important' }">{{ pageCMS.sectionEightTitle }}</h1>
                <div class="w-90 mx-auto position-relative">
                    <div class="toggle-btn" :class="isShow ? 'show_d' : ''">
                        <button @click="isShow = !isShow" class="btn border-0 ff-montserrat500 fs-20">
                            <i class="bi bi-chevron-down"></i>
                        </button>
                    </div>
                    <div class="row row-cols-5 g-3 glossary-nav" :class="isShow ? 'show_d' : ''">
                        <div v-for="letter in alphabet" :key="letter" class="col">
                            <a @click="getGlossaryData(letter)" href="javascript:void(0)" class="btn border-0 bg-site-gray-700 rounded-0 w-100 py-3 text-uppercase ff-montserrat500 fs-20">{{ letter }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <section class="py-5" :id="letter" v-if="Object.values(glossary).length > 0">
                <div class="bg-site-gray-700 py-2 mb-5">
                    <div class="container">
                        <h1 class="ff-heading h-fs-122 text-uppercase">{{ this.currentLetter }}</h1>
                    </div>
                </div>
                <div class="container" v-for="(data, index) in glossary" :key="index">
                    <h3 class="ff-montserrat400 fs-20">{{ data.title }}</h3>
                    <p class="ff-montserrat300 fs-16" v-html="data.description"></p>
                </div>
            </section>
            <div class="container py-3">
                <div class="d-flex flex-column flex-md-row align-items-center gap-3 mb-4">
                    <h1 class="mb-0 ff-heading h-fs-35 w-100" :style="{'color': uiComponent.pageTitleFontColor + '!important' }">{{ pageCMS.pageSubTitle }}</h1>
                    <p class="mb-0 ff-montserrat300 fs-16 flex-grow-1" v-html="pageCMS.pageDescription"></p>
                </div>
            </div>
            <div class="container py-3">
                <h1 class="ff-heading h-fs-35" :style="{'color': uiComponent.sectionOneTitleFontColor + '!important' }">{{ pageCMS.sectionOneTitle }}</h1>
                <p class="ff-montserrat300 fs-16 mb-4" v-html="pageCMS.sectionOneDescription"></p>
                <ul class="nav nav-pills mb-3 justify-content-center w-100 flex-wrap" id="pills-tab" role="tablist">
                    <li v-for="(data, index) in dataList" :key="index" class="nav-item w-xs-auto" :class="tabWide" role="presentation">
                        <button :class="['nav-link', 'learn-nav-link', 'w-100', index==0 ? 'active' : '']" id="pills-rings-tab" data-bs-toggle="pill" :data-bs-target="'#pills-rings'+index" type="button" role="tab" aria-controls="pills-rings" aria-selected="true">
                            {{ data.title }}
                        </button>
                    </li>
                </ul>
                <div v-for="(data, index) in dataList" :key="index" class="tab-content" id="pills-tabContent">
                    <div :class="['tab-pane', 'fade', 'show', index==0 ? 'active' : '']" :id="'pills-rings'+index" role="tabpanel" aria-labelledby="pills-rings-tab" tabindex="0">
                        <div class="row">
                            <div class="col-12 col-sm-7">
                                <p v-html="data.description"></p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="tab-pane fade" id="pills-bracelets" role="tabpanel" aria-labelledby="pills-bracelets-tab" tabindex="0">
                        <BraceletsSizeTab :cmsData="cmsData" :tabImage="pageCMS.sectionOneImage"/>
                    </div>
                    <div class="tab-pane fade" id="pills-NecklacesPendants" role="tabpanel" aria-labelledby="pills-NecklacesPendants-tab" tabindex="0">
                        <NecklaceSizeTab :cmsData="cmsData" :tabImage="pageCMS.sectionOneImage"/>
                    </div> -->
                </div>
            </div>
            <!-- <div class="container py-3">
                <h1 class="ff-heading h-fs-69 fs-xs-27" :style="{'color': uiComponent.sectionTwoTitleFontColor + '!important' }">{{ pageCMS.sectionTwoTitle }}</h1>
                <p class="ff-montserrat300 fs-16 mb-4" v-html="pageCMS.sectionTwoDescription"></p>
                <div class="w-90 mx-auto mb-4">
                    <img class="w-100" :src="pageCMS.sectionTwoImage">
                </div>
                <h1 class="ff-heading h-fs-69 mb-4" :style="{'color': uiComponent.sectionThreeTitleFontColor + '!important' }">{{ pageCMS.sectionThreeTitle }}</h1>
                <p class="ff-montserrat300 fs-16 mb-4" v-html="pageCMS.sectionThreeDescription"></p>
            </div>
            <div class="container py-4">
                <p class="ff-montserrat500 fs-16 mb-4" :style="{'color': uiComponent.sectionFourTitleFontColor + '!important' }">{{ pageCMS.sectionFourTitle }}</p>
                <p class="ff-montserrat300 fs-16 mb-4" v-html="pageCMS.sectionFourDescription"></p>
                <div class="w-90 mx-auto mb-4">
                    <img class="w-100" :src="pageCMS.sectionFourImage">
                </div>
                <h1 class="ff-heading h-fs-69 fs-xs-27 mb-4" :style="{'color': uiComponent.sectionFiveTitleFontColor + '!important' }">{{ pageCMS.sectionFiveTitle }}</h1>
                <p class="ff-montserrat300 fs-16" v-html="pageCMS.sectionFiveDescription"></p>
            </div>
            <div class="container py-4">
                <div class="w-70 w-md-80 w-xs-90 mx-auto">
                    <h1 class="text-center ff-heading h-fs-98 h-fs-sm-69 fs-xs-27" :style="{'color': uiComponent.sectionSixTitleFontColor + '!important' }">{{ pageCMS.sectionSixTitle }}</h1>
                    <div class="mb-3">
                        <img class="w-100" :src="pageCMS.sectionSixImage">
                    </div>
                    <p class="ff-montserrat300 fs-16" v-html="pageCMS.sectionSixDescription"></p>
                </div>
            </div>
            <div class="container py-4">
                <h1 class="text-center ff-heading h-fs-98 h-fs-md-69  fs-xs-26 mb-5" :style="{'color': uiComponent.sectionSevenTitleFontColor + '!important' }">{{ pageCMS.sectionSevenTitle }}</h1>
                <p class="ff-montserrat300 fs-16 mb-4" v-html="pageCMS.sectionSevenDescription"></p>
                <div class="w-90 mx-auto mb-4">
                    <img class="w-100" :src="pageCMS.sectionSevenImage">
                </div>
            </div> -->
        </section>
    </div>
</template>

<script src="../js/learn.js"></script>

<style scoped>
    @import "../css/learn.css";
</style>
