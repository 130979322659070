import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    name: "Promotional",
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            type: 'Bracelets',
            promotionalCMS: {},
            productList: [],
            categoryList: [],
            uiComponent: {},
            page: 1,
            limit: 10,
            totalPage: 0
        }
    },
    async created(){
        await this.getPageTitles();
        document.title = (this.pageTitles?.promotionPageTitle ? this.pageTitles?.promotionPageTitle : '');
        this.scrollToTop();
    },
    async mounted() {
        let params = '?page=' + this.page + '&limit=' + this.limit;
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.promotional.requestURL + params,
        };
        await this.getPromotionalData(config);
    },
    methods: {
        navigate: function (id) {
            if(id) {
                this.$router.push('/product-details/' + id);
            }
        },
        updateHandler: async function () {
            let params = '?page=' + this.page + '&limit=' + this.limit;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.promotional.requestURL + params,
            };
            await this.getPromotionalData(config);
        },
        parseColorCode: function(code) {
            let colorCode = code.split(",");
            return colorCode;
        },
        getPromotionalData: async function (config) {
            this.isLoading = true;

            await this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.promotionalCMS = response.data.data.promotionalPageCMS[0] ? response.data.data.promotionalPageCMS[0] : {};
                    this.uiComponent = response.data.data.promotionalComponentCms.length > 0 ? response.data.data.promotionalComponentCms[0] : {};
                    this.categoryList = response.data.data.productCategoryList;
                    this.productList = response.data.data.discountProduct;
                    this.totalPage = response.data.data.pageCount;

                } else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! Please try again."
                    });
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            })
        }
    }
}
