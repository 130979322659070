<template>
  <div :style="{'background-color': uiComponent.pageBackgroundColor}">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <section class="mb-4" v-if="Object.values(promotionalCMS).length > 0">
      <div class="container">
      <div class="row my-0 g-3">
        <!-- <div class="col-6" @click="$router.push('/product-details/' + promotionalCMS.firstProductId)"> -->
        <div class="col-6" @click="navigate(promotionalCMS.firstProductId)">
          <div class="h-100 position-relative">
            <img class="w-100 h-100" :src="promotionalCMS.firstProductImage" :alt="promotionalCMS.firstProductLabel" />
            <p
              class="position-absolute w-100 bottom-0 text-center ff-montserrat400 fs-17 fs-xs-12 text-white"
            >{{promotionalCMS.firstProductLabel}}</p>
          </div>
        </div>
        <div class="col-6 pe-0">
          <div class="h-100 row g-3">
            <div
              class="col-6"
              @click="navigate(promotionalCMS.secondProductId)"
            >
              <div class="h-100 position-relative">
                <img class="w-100 h-100" :src="promotionalCMS.secondProductImage" :alt="promotionalCMS.secondProductLabel" />
                <p
                  class="position-absolute w-100 bottom-0 text-center ff-montserrat400 fs-17 fs-xs-12 text-white"
                >{{promotionalCMS.secondProductLabel}}</p>
              </div>
            </div>
            <div
              class="col-6"
              @click="navigate(promotionalCMS.thirdProductId)"
            >
              <div class="h-100 position-relative">
                <img class="w-100 h-100" :src="promotionalCMS.thirdProductImage" :alt="promotionalCMS.thirdProductLabel" />
                <p
                  class="position-absolute w-100 bottom-0 text-center ff-montserrat400 fs-17 fs-xs-12 text-white"
                >{{promotionalCMS.thirdProductLabel}}</p>
              </div>
            </div>
            <div
              class="col-6"
              @click="navigate(promotionalCMS.fourthProductId)"
            >
              <div class="h-100 position-relative">
                <img class="w-100 h-100" :src="promotionalCMS.fourthProductImage" :alt="promotionalCMS.fourthProductLabel" />
                <p
                  class="position-absolute w-100 bottom-0 text-center ff-montserrat400 fs-17 fs-xs-12 text-white"
                >{{promotionalCMS.fourthProductLabel}}</p>
              </div>
            </div>
            <div
              class="col-6"
              @click="navigate(promotionalCMS.fifthProductId)"
            >
              <div class="h-100 position-relative">
                <img class="w-100 h-100" :src="promotionalCMS.fifthProductImage" :alt="promotionalCMS.fifthProductLabel" />
                <p
                  class="position-absolute w-100 bottom-0 text-center ff-montserrat400 fs-17 fs-xs-12 text-white"
                >{{promotionalCMS.fifthProductLabel}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="text-center mb-4" v-if="Object.values(promotionalCMS).length > 0">
          <h1 class="ff-heading h-fs-98 h-fs-md-69  text-site-dark" :style="{'color': uiComponent.pageTitleFontColor + '!important' }">{{promotionalCMS.title}}</h1>
          <h2 class="ff-montserrat500 fs-18 text-site-dark" :style="{'color': uiComponent.pageSubTitleFontColor + '!important' }">{{promotionalCMS.subTitle}}</h2>
        </div>
        <div class="d-flex gap-3 justify-content-center flex-wrap mb-5">
          <div v-for="(data, index) in categoryList" :key="index">
            <!-- <input v-model="type" type="radio" class="btn-check promotional-btn-check" name="options" id="option1" value="Bracelets"> -->
            <label
              class="btn promotional-check-btn"
              for="option1"
              @click="$router.push('/shop/category/' + data.categorySlug)"
            >
              <img height="65" width="63" :src="data.image" :alt="data.name" />
              <span class="d-block ff-montserrat400 fs-20">{{data.name}}</span>
            </label>
          </div>
        </div>
        <div class="promotional-layout-section" v-if="productList.length > 0">
          <div v-for="(data, index) in productList" class="card w-100 border-0 cp" :key="index" @click="$router.push('/product-details/' + data.urlSlug)">
            <img
              height="440"
              class="promotional-layout-section-image"
              :src="data.featuredImage"
            />
            <div>
              <p @click="$router.push('/product-details/' + data.urlSlug)" class="mb-0 ff-montserrat500 cp fs-16">{{data.name}}</p>

              <p class="mb-0 d-flex justify-content-between">
                <span v-if="data.discountPrice" class>
                  <s>{{data.price}}$</s>
                </span>
                <span v-else class>{{data.price}}$</span>
                <span v-if="!data.discountPrice">
                  <span
                    class="bg-site-goldenrod d-inline-block me-2 lh-1"
                    style="width:15px;height:15px"
                  >&nbsp;</span>
                  <span
                    class="bg-site-gray-800 d-inline-block lh-1"
                    style="width:15px;height:15px"
                  >&nbsp;</span>
                </span>
              </p>
              <p class="mb-0 d-flex justify-content-between" v-if="data.discountPrice">
                <span>{{data.discountPrice}}$</span>
                <span v-if="data.colorCode">
                    <span
                      class="d-inline-block me-2 lh-1"
                      :style="[{width:'15px',height:'15px', 'background-color': row}]"
                      v-for="(row, index) in parseColorCode(data.colorCode)" :key="index"
                    >&nbsp;</span>
                  
                </span>
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="promotional-layout-section" v-else>
          <p class="text-center">No Data Found.</p>
        </div> -->
        <div class="text-center py-5">
          <!-- <button class="btn bg-transparent border-site-black fs-15 ff-montserrat600 text-uppercase">load more</button> -->
          <nav class="page-navigation justify-content-center d-flex" aria-label="page-navigation">
            <v-pagination
              v-if="productList.length > 0"
              v-model="page"
              @update:modelValue="updateHandler"
              :pages="totalPage"
              :range-size="1"
              active-color="#FF8400"
            ></v-pagination>
          </nav>
        </div>
      </div>
    </section>
  </div>
</template>

<script src="../js/promotional.js">
</script>

<style scoped>
@import url("../../../assets/pagination.css");
@import "../css/promotional.css";
</style>
