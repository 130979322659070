<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />

    <section class="py-5">
      <div class="container">
        <div class="row g-3">
          <span style="cursor:pointer" @click="$router.push('/shop')">
            <i class="fa-solid fa-arrow-left"></i> &nbsp;Continue Shopping
          </span>
          <div class="d-md-flex d-none">
            <div class="col-md-12 col-lg-12">
            <h1 class="ff-heading h-fs-98">Cart</h1>
            <div class="table-container">
              <div class="table-section mt-2">
                <div class="table">
                  <table class="custom_table"> 
                    <thead>
                      <tr>
                        <th class="sorting_asc ff-montserrat300 uppercase">Product</th>
                        <th class="sorting ff-montserrat300 uppercase">Price</th>
                        <th class="sorting ff-montserrat300 uppercase" style="min-width: 70px">Variants</th>
                        <th class="sorting ff-montserrat300 uppercase">Quantity</th>
                        <th class="sorting ff-montserrat300 uppercase">Subtotal</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody v-if="store.cartValue.length>0">
                      <template v-for="(data, index) in store.cartValue" :key="index">
                        <tr class="">
                        <td>
                          <div class="d-flex gap-2 align-items-center" style="width: 300px">
                            <img
                              @click.stop.prevent="$router.push('/product-details/'+data.data.urlSlug)"
                              :src="data.data.featuredImage ? data.data.featuredImage : data.data.image"
                              height="100"
                              width="100"
                              :style="{'cursor': 'pointer'}"
                            />
                            <p
                              class="mb-0 w-auto"
                              :style="{'cursor': 'pointer'}"
                              @click.stop.prevent="$router.push('/product-details/'+data.data.urlSlug)"
                            >{{data.data.name}}</p>
                          </div>
                        </td>
                        <td class="ff-montserrat500 fs-16">
                          <!-- {{ data.data.price }} -->
                          <!-- {{ data.data.discountPrice }} -->
                          ${{data.data.discountPrice === 0.00 ? data.data.discountPrice : data.data.price}}
                        </td>
                        <td class="ff-montserrat500 fs-16">
                          <div v-if="data.data.type=='product'">
                            <p
                              class="mb-0"
                              v-if="data.data.choosedColor && data.data.choosedColor !='N/A'"
                            >Color:&nbsp;{{data.data.choosedColor}}</p>
                            <p
                              class="mb-0"
                              v-if="data.data.choosedSize && data.data.choosedSize !='N/A'"
                            >Size:&nbsp;{{data.data.choosedSize}}</p>
                            <p
                              class="mb-0"
                              v-if="data.data.choosedMaterial && data.data.choosedMaterial !='N/A' "
                            >Material:&nbsp;{{data.data.choosedMaterial}}</p>
                          </div>
                          <div v-else>
                            <p class="mb-0">Gift Card</p>
                          </div>
                        </td>
                        <td>
                          <div
                            class="form-control d-flex align-items-center justify-content-between py-2"
                            style="min-width: 100px"
                          >
                            <a
                              @click="decreaseProductQuantity(index)"
                              class="btn border-0 py-0 px-0 bg-transparent"
                            >
                              <i class="bi bi-dash-lg"></i>
                            </a>
                            <span class="ff-montserrat400 fs-17">{{data.quantity}}</span>

                            <a
                              @click="increaseProductQuantity(index)"
                              class="btn border-0 py-0 px-0 bg-transparent"
                            >
                              <i class="bi bi-plus-lg"></i>
                            </a>
                          </div>
                        </td>
                        <td
                          class="ff-montserrat500 fs-16"
                        >${{parseFloat(data.quantity*(data.data.discountPrice === 0.00 ? data.data.discountPrice : data.data.price)).toFixed(2)}}</td>
                        <td>
                          <button
                            title="delete"
                            class="btn btn-sm btn-danger action_btn"
                            @click="removeFromCart(index)"
                          >
                            <i class="bi bi-trash"></i>
                          </button>
                        </td>
                        </tr>
                      </template> 
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="6">
                          <div
                            class="alert alert-info"
                            role="alert"
                            style="text-align:center"
                          >Cart Is Empty!!</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            
            <div class="d-flex py-3 gap-3 justify-content-between">
              <div class="">
              </div>
              <div class="input-group mb-3 gap-3 w-55">
              </div>
              <div class="">
                <span v-if="store.totalAmount">Total: ${{parseFloat(store.totalAmount).toFixed(2)}}</span>
              </div>
            </div>
            <div class="d-flex py-3 gap-3 justify-content-between">
              <div>
                <button
                  v-if="store.cartValue.length > 0 && !token"
                  class="btn d-inline-block mt-3 bg-site-Red border-site-Red ff-montserrat500 fs-12 text-white text-uppercase" style="margin-top:48px;"
                  @click="$router.push('/shop')"
                >Continue Shopping</button>
              </div>
              <div>
                
                <button
                  v-if="store.cartValue.length > 0 && !token"
                  class="btn d-inline-block mt-3 bg-site-Red border-site-Red ff-montserrat500 fs-12 text-white text-uppercase"
                  @click="$router.push('/checkout')"
                >Guest Checkout</button>
                
              </div>

              <div>
                <template v-if="!token">
                    <button
                      v-if="store.cartValue.length > 0"
                      class="btn d-inline-block  mt-3 bg-site-Red border-site-Red ff-montserrat500 fs-12 text-white text-uppercase mobile-space"
                      @click="$router.push(token?'/checkout':'/login')"
                    >Login & Checkout</button>
                </template>
                <template v-else>
                    <button
                      v-if="store.cartValue.length > 0"
                      class="btn d-inline-block  mt-3 bg-site-Red border-site-Red ff-montserrat500 fs-12 text-white text-uppercase mobile-space"
                      @click="$router.push(token?'/checkout':'/login')"
                    >Checkout</button>
                </template>
                

              </div>
            </div>
          </div>
          </div>




          <div class="d-md-none d-flex">
            <div class="">
            <h1 class="ff-heading h-fs-98">Cart</h1>
            <div class="table-container">
              <div class="table-section mt-2">
                <div class="table">
                  <table class="mobile_table_test"> 
                    <tbody v-if="store.cartValue.length>0">
                      <template v-for="(data, index) in store.cartValue" :key="index">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="">
                            <div class="Image">
                                <img
                                @click.stop.prevent="$router.push('/product-details/'+data.data.id)"
                                :src="data.data.featuredImage ? data.data.featuredImage : data.data.image"
                                height="100"
                                width="100"
                                class="border p-1"
                                :style="{'cursor': 'pointer'}"
                              />
                            </div>
                          </div>
                          <div class="">
                            <div class="text_box"> 
                              <p
                                class="mb-0 w-auto fw-bold"
                                :style="{'cursor': 'pointer'}"
                                @click.stop.prevent="$router.push('/product-details/'+data.data.id)"
                              >{{data.data.name}}</p>
                            </div>
                          </div> 
                        </div>
                        <tr class="">
                          <td class="ff-montserrat500 fs-16" data-lebel="Price">
                            <!-- {{ data.data.price }} -->
                            <!-- {{ data.data.discountPrice }} -->
                            ${{data.data.discountPrice === 0.00 ? data.data.discountPrice : data.data.price}}
                          </td>
                          <td class="ff-montserrat500 fs-16" data-lebel="Variants">
                            <div v-if="data.data.type=='product'">
                              <p
                                class="mb-0"
                                v-if="data.data.choosedColor && data.data.choosedColor !='N/A'"
                              >Color:&nbsp;{{data.data.choosedColor}}</p>
                              <p
                                class="mb-0"
                                v-if="data.data.choosedSize && data.data.choosedSize !='N/A'"
                              >Size:&nbsp;{{data.data.choosedSize}}</p>
                              <p
                                class="mb-0"
                                v-if="data.data.choosedMaterial && data.data.choosedMaterial !='N/A' "
                              >Material:&nbsp;{{data.data.choosedMaterial}}</p>
                            </div>
                            <div v-else>
                              <p class="mb-0">Gift Card</p>
                            </div>
                          </td>
                          <td data-lebel="Quantity">
                            <div
                              class="form-control d-flex align-items-center justify-content-between py-2"
                              style="min-width: 100px"
                            >
                              <a
                                @click="decreaseProductQuantity(index)"
                                class="btn border-0 py-0 px-0 bg-transparent"
                              >
                                <i class="bi bi-dash-lg"></i>
                              </a>
                              <span class="ff-montserrat400 fs-17">{{data.quantity}}</span>

                              <a
                                @click="increaseProductQuantity(index)"
                                class="btn border-0 py-0 px-0 bg-transparent"
                              >
                                <i class="bi bi-plus-lg"></i>
                              </a>
                            </div>
                          </td>
                          <td
                            data-lebel="Subtotal"
                            class="ff-montserrat500 fs-16"
                          >${{parseFloat(data.quantity*(data.data.discountPrice === 0.00 ? data.data.discountPrice : data.data.price)).toFixed(2)}}</td>
                          <td>
                            <button
                              title="delete"
                              class="btn btn-sm btn-danger action_btn"
                              @click="removeFromCart(index)"
                            >
                              <i class="bi bi-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </template> 
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="6">
                          <div
                            class="alert alert-info"
                            role="alert"
                            style="text-align:center"
                          >Cart Is Empty!!</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="d-flex py-3 gap-3 justify-content-between">
              <div>
                <button
                  v-if="store.cartValue.length > 0 && !token"
                  class="btn bg-site-Red border-site-Red ff-montserrat500 fs-15 text-white text-uppercase" style="margin-top:48px;"
                  @click="$router.push('/shop')"
                >Continue Shopping</button>
              </div>
              <div class="input-group mb-3 gap-3 w-50">
                <!-- <input
                  type="text"
                  class="form-control w-55 round_5"
                  placeholder="Coupon Code"
                  aria-label
                  aria-describedby="button-addon2"
                />
                <button
                  class="btn btn-outline-dark round_5"
                  type="button"
                  id="button-addon2"
                >Apply Coupon Code</button>-->
              </div>

              <div>
                <span v-if="store.totalAmount">Total: ${{parseFloat(store.totalAmount).toFixed(2)}}</span>
                <br />
                <br />
                <template v-if="!token">
                  <button
                    v-if="store.cartValue.length > 0"
                    class="btn bg-site-Red border-site-Red ff-montserrat500 fs-15 text-white text-uppercase mobile-space"
                    @click="$router.push(token?'/checkout':'/login')"
                  >Login & Checkout</button>
                  
                </template>
                <template>
                  <button
                    v-if="store.cartValue.length > 0"
                    class="btn bg-site-Red border-site-Red ff-montserrat500 fs-15 text-white text-uppercase mobile-space"
                    @click="$router.push('/checkout')"
                  >Checkout</button>
                </template>
                
                <button
                  v-if="store.cartValue.length > 0 && !token"
                  class="btn bg-site-Red border-site-Red ff-montserrat500 fs-15 text-white text-uppercase"
                  @click="$router.push('/checkout')"
                >Guest Checkout</button>
              </div>
            </div>
          </div>
          </div>
          <!-- <div class="d-md-none d-flex">
            <div>
              <h1>TEST COMPONENT</h1>
            <div>
              <p>Samsung</p>
              <p>Price</p>
              <p>color:Red</p>
              <p>material:Steel</p>
              <p>Quantity:1</p>
              <p>subtotal:123taka</p>
            </div>
            <div class="d-flex justify-content-center">
              <div>
                <button
                  v-if="store.cartValue.length > 0"
                  class="btn bg-site-Red border-site-Red ff-montserrat500 fs-15 text-white text-uppercase"
                  @click="$router.push(token?'/checkout':'/login')"
                >Checkout</button>
                &nbsp;
                <button
                  v-if="store.cartValue.length > 0 && !token"
                  class="btn bg-site-Red border-site-Red ff-montserrat500 fs-15 text-white text-uppercase"
                  @click="$router.push('/checkout')"
                >Continue as guest</button>
              </div>
              
            </div>
            </div>
            
          </div> -->
          

          <!-- <div class="col-12 col-md-5 col-lg-3">
        <h1 class="ff-montserrat300 fs-27">Cart Total</h1>
        <div class="d-flex align-items-center justify-content-between bg-site-gray-500 py-2 px-2 mb-3">
          <p class="mb-0 ff-montserrat500 fs-18">Subtotal:</p>
          <p class="mb-0 ff-montserrat500 fs-18">$76.00</p>
        </div>
        <div class="d-flex align-items-start justify-content-between bg-site-gray-500 py-2 px-2 mb-3">
          <p class="mb-0 ff-montserrat500 fs-18">Shipping</p>
          <div>
            <div class="form-check">
              <input class="form-check-input shadow-none Shipping_cart_radio cp" type="radio" name="Shipping" id="Shipping1">
              <label class="form-check-label ff-montserrat300 fs-16 cp" for="Shipping1">
                Flat rate
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input shadow-none Shipping_cart_radio cp" type="radio" name="Shipping" id="Shipping2">
              <label class="form-check-label ff-montserrat300 fs-16 cp" for="Shipping2">
                Free shipping
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input shadow-none Shipping_cart_radio cp" type="radio" name="Shipping" id="Shipping3">
              <label class="form-check-label ff-montserrat300 fs-16 cp" for="Shipping3">
                Local Pickup
              </label>
            </div>
            <p class="mb-1 ff-montserrat300 fs-16">Shipping to CA</p>
            <button class="btn btn-change-address">Change address</button>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label ff-montserrat300 fs-16">Country / Region *</label>
          <select class="form-select rounded-2 ff-montserrat500 fs-16">
            <option class="ff-montserrat500 fs-16">United States (US)</option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label ff-montserrat300 fs-16">State</label>
          <select class="form-select rounded-2 opacity-50 ff-montserrat500 fs-16">
            <option class="ff-montserrat500 fs-16">State</option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label ff-montserrat300 fs-16">Town / City</label>
          <input type="text" class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16" placeholder="Town / City">
        </div>
        <div class="mb-3">
          <label class="form-label ff-montserrat300 fs-16">Postal / Zip Code</label>
          <input type="text" class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16" placeholder="Postal / Zip Code">
        </div>
        <div class="mb-3">
          <button class="btn btn-outline-dark text-uppercase">update</button>
        </div>
        <div class="d-flex align-items-center justify-content-between bg-site-gray-500 py-2 px-2 mb-5">
          <p class="mb-0 ff-montserrat600 fs-18">Total:</p>
          <p class="mb-0 ff-montserrat600 fs-18">$76.00</p>
        </div>
        <div class="">
          <button class="btn bg-site-Red border-site-Red ff-montserrat600 fs-14 text-white text-uppercase">proceed to checkout</button>
        </div>
          </div>-->
        </div>
      </div>
    </section>
  </div>
</template>

<script src="../js/shopping_cart.js">
</script>

<style scoped>
@import "../css/shopping_cart.css";
.uppercase{ 
  text-transform: uppercase;
}
</style>
