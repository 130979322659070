import Loading from "vue-loading-overlay";
import { authHeader } from "../../../../../auth";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    name: "Return List",
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            returnList: [],
            page: 1,
            limit: 10,
            totalPage: 0,
        }
    },
    async created() {
        document.title = "Golden Gal - Dashboard";
        let params = '?page=' + this.page + '&limit=' + this.limit;
        await this.getReturnList(params);
    },
    methods: {
        getReturnList: async function (params) {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.return.listURL + params,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.returnList = response.data.data;
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "No Order found with this order id"
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            await this.getReturnList(params);
            this.totalPage = this.pageCount;
        },
    }
}
