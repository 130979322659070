<template>
  <div class="fixed-top d-flex flex-column bg-white shadow-sm top_navbar">
    <nav class="navbar navbar-expand py-0">
      <div class="container-xl">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item dropdown" v-if="token">
            <a
              class="nav-link dropdown-toggle notification"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >My Account</a>
            <ul class="dropdown-menu dropdown-menu-end notification_dropdown min-w-xs-auto">
              <li>
                <a
                  class="dropdown-item"
                  @click="$router.push('/user-dashboard')"
                  href="javascript:void(0)"
                >Dashboard</a>
              </li>
              <li>
                <a class="dropdown-item" href="javascript:void(0)" @click="logout()">Logout</a>
              </li>
            </ul>
          </li>
          <li v-else class="nav-item">
            <a class="nav-link login-btn-hover" @click="$router.push('/login')">Login / Register</a>
          </li>
          <li class="nav-item align-self-center">
            <div class="vr flex-shrink-1"></div>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link notification d-flex"
              href="javascript:void(0)"
              role="button"
              :title="'Wish List-' + store.wishlistCounter"
              data-bs-toggle="dropdown"
              @click="$router.push('/wish-list')"
              aria-expanded="false"
            >
              <i class="bi bi-heart"></i>
              <span class="ms-1">{{ store.wishlistCounter }}</span>
            </a>
          </li>
          <li class="nav-item">
            <button
              class="btn"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <i class="bi bi-bag"></i>
              <span class="ms-1">{{ store.cartCounter }}</span>
            </button>
          </li>
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link notification d-flex"
              href="#"
              role="button"
              :title="'Cart-' + store.cartCounter"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-bag"></i>
              <span class="ms-1">{{store.cartCounter}}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end notification_dropdown px-2">
              <div v-if="store.cartValue.length>0">
                <li class="d-flex gap-2 mb-2" v-for="(data, index) in store.cartValue" :key="index">
                  <div class="d-flex gap-2 border round_5 w-100">
                    <div style="height: 80px;width: 100px">
                      <img class="round_5 border-end w-100 h-100" @click.stop.prevent="$router.push('/product-details/'+data.data.id)"  :style="{'cursor': 'pointer'}"
                        :src="data.data.featuredImage ? data.data.featuredImage : data.data.image" 
                        :alt="data.data.name" 
                      />
                    </div>
                    <div style="width: calc(100% - 100px)">
                      <p @click.stop.prevent="$router.push('/product-details/'+data.data.id)"  :style="{'cursor': 'pointer'}" class="fs-13 mb-0 mt-3">{{data.data.name}}</p>
                      <span class="fs-13 mb-0 mt-3">Quantity: {{data.quantity}}</span>
                    </div>
                  </div>
                </li>
                <li class="d-flex align-items-center justify-content-center py-3">
                  <button
                    class="btn btn-sm bg-site-Red border-site-Red text-white"
                    @click="$router.push('/cart')"
                  >View Shopping TEST</button>
                </li>
              </div>
              <div v-else>
                <li class="d-flex align-items-center justify-content-center py-3">Cart is empty!</li>
              </div>
            </ul>
          </li>-->
        </ul>
      </div>
    </nav>
    <header class="navbar navbar-expand-lg bd-navbar py-0">
      <!-- {{isShopPage}} -->
      <nav class="container-xl bd-gutter flex-wrap flex-lg-nowrap" aria-label="Main navigation">
        <a
          class="navbar-brand p-0 me-0 me-lg-2"
          href="javascript:void(0)"
          @click="$router.push('/')"
        >
          <img :src="store.footerCMSData.logo" alt="Goldengal Jewelry" />
        </a>

        <div class="d-flex">
          <button
            class="navbar-toggler d-flex d-lg-none order-3 p-0 shadow-none text-site-black border-0"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#bdNavbar"
            aria-controls="bdNavbar"
            aria-label="Toggle navigation"
          >
            <i class="bi bi-list"></i>
          </button>
        </div>

        <div
          class="offcanvas-lg offcanvas-start flex-grow-1"
          tabindex="-1"
          id="bdNavbar"
          aria-labelledby="bdNavbarOffcanvasLabel"
          data-bs-scroll="true"
        >
          <div class="offcanvas-header px-4 pb-0">
            <h5 class="offcanvas-title text-white cp" id="bdNavbarOffcanvasLabel">
              <img src="../../../../public/core/assets/images/logo.png" alt="Goldengal Jewelry" />
            </h5>
            <button
              type="button"
              class="btn-close btn-close-black shadow-none"
              id="close_sidebar"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              data-bs-target="#bdNavbar"
            ></button>
          </div>

          <div class="offcanvas-body p-4 pt-0 p-lg-0">
            <hr class="d-lg-none text-white-50" />
            <ul class="navbar-nav flex-row flex-wrap bd-navbar-nav ms-lg-auto">
              <!-- <li @click="closeSidebar" class="nav-item col-12 col-lg-auto">
                <router-link
                  class="nav-link py-2 px-0 px-lg-3 ff-montserrat300 fs-16 text-site-black"
                  to="/shop"
                >SHOP</router-link>
              </li>-->

              <!-- Shop Menu -->
              <li class="nav-item col-12 col-lg-auto custom-nav-item dropdown">
                <!-- <a
                  v-if="isShopPage && !isMobileDevice"
                  type="button"
                  href="/shop"
                  class="nav-link custom-nav-link py-2 px-0 px-lg-3 ff-montserrat300 fs-16 text-site-black"
                  :class="isDropdownMenuShow1 ? 'show': ''"
                  @click="DropdownMenuShow1()"
                >SHOP</a>-->
                <a
                  type="button"
                  class="nav-link custom-nav-link py-2 px-0 px-lg-3 ff-montserrat300 fs-16 text-site-black"
                  :class="isDropdownMenuShow1 ? 'show' : ''"
                  @click="DropdownMenuShow1(), goShop()"
                >SHOP</a>
                <div class="custom-dropdown-menu">
                  <div class="row submenu-wrapper">
                    <div class="col-md-3 sub-menu mb-md-0 mb-4">
                      <a v-if="isShopPage" href="/shop" class="menu-item">
                        <h6 class="ff-montserrat500 text-site-black fs-19">SHOP ALL</h6>
                      </a>
                      <a
                        v-else
                        href="javascript:void(0)"
                        @click="$router.push('/shop')"
                        class="menu-item"
                      >
                        <h6 class="ff-montserrat500 text-site-black fs-19">SHOP ALL</h6>
                      </a>
                      <ul class="list-unstyled">
                        <li>
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/occasion')"
                          >SHOP BY OCCASION</a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-2 sub-menu mb-md-0 mb-4">
                      <h6 class="text-site-gray-900 fs-16 text-uppercase">BY CATEGORY</h6>
                      <ul class="list-unstyled">
                        <li v-for="(data, index) in categoryList" :key="index">
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop/category/' + data.categorySlug)"
                          >{{ data.name }}</a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-2 sub-menu mb-0">
                      <h6 class="text-site-gray-900 fs-16 text-uppercase text-uppercase">BY PRICE</h6>
                      <ul class="list-unstyled">
                        <li>
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop?minPrice=0&maxPrice=250')"
                          >UNDER $250</a>
                        </li>
                        <li>
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop?minPrice=250&maxPrice=500')"
                          >$250 - $500</a>
                        </li>
                        <li>
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop?minPrice=500&maxPrice=750')"
                          >$500 - $750</a>
                        </li>
                        <li>
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop?minPrice=750&maxPrice=1000')"
                          >$750 - $1,000</a>
                        </li>
                        <li>
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop?minPrice=1000&maxPrice=2000')"
                          >$1,000 - $2,000</a>
                        </li>
                        <li>
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop?minPrice=2000&maxPrice=10000')"
                          >$2,000+</a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-5 sub-menu">
                      <div class="d-flex gap-3 align-items-end">
                        <div class="position-relative">
                          <img width="240" height="240" alt="GoldenGal" :src="shopGuideCMS.image" />
                        </div>
                        <div>
                          <p
                            class="ff-montserrat300 fs-16 lh-sm mb-2"
                            v-html="shopGuideCMS.description"
                          ></p>
                          <a
                            v-if="isShopPage"
                            class="btn btn-outline-dark mb-4"
                            :href="shopGuideCMS.buttonLink"
                          >
                            {{
                            shopGuideCMS.buttonText
                            }}
                          </a>
                          <a
                            v-else
                            class="btn btn-outline-dark mb-4"
                            @click="$router.push(shopGuideCMS.buttonLink)"
                          >
                            {{
                            shopGuideCMS.buttonText
                            }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="dropdown-menu w-100 d-lg-none px-3"
                  :class="isDropdownMenuShow1 ? 'show' : ''"
                >
                  <div class="row">
                    <div class="col-12 sub-menu mb-md-0 mb-4">
                      <router-link to="/shop" class="menu-item">
                        <h6
                          @click="DropdownMenuShow1()"
                          class="ff-montserrat500 text-site-black fs-19"
                        >SHOP ALL</h6>
                      </router-link>
                      <ul class="list-unstyled">
                        <li>
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/occasion')"
                          >SHOP BY OCCASION</a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-12 sub-menu mb-md-0 mb-4">
                      <ul class="list-unstyled">
                        <li
                          @click="DropdownMenuShow1()"
                          v-for="(data, index) in categoryList"
                          :key="index"
                        >
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop/category/' + data.categorySlug)"
                          >{{ data.name }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <!-- Shop Menu -->

              <li class="nav-item col-12 col-lg-auto custom-nav-item dropdown">
                <a
                  type="button"
                  class="nav-link custom-nav-link py-2 px-0 px-lg-3 ff-montserrat300 fs-16 text-site-black"
                  :class="isDropdownMenuShow2 ? 'show' : ''"
                  @click="DropdownMenuShow2(), goGiftGuide()"
                >GIFT GUIDE</a>
                <div class="custom-dropdown-menu">
                  <div class="row submenu-wrapper">
                    <div class="col-md-3 sub-menu mb-md-0 mb-4">
                      <router-link to="/shop?routeName=giftGuide" class="menu-item">
                        <h6 class="ff-montserrat500 text-site-black fs-19">Shop all GIFT Guide</h6>
                      </router-link>
                    </div>
                    <div class="col-md-2 sub-menu mb-md-0 mb-4">
                      <h6 class="text-site-gray-900 fs-16 text-uppercase">BY CATEGORY</h6>
                      <ul class="list-unstyled">
                        <li v-for="(data, index) in categoryList" :key="index">
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop/category/' + data.categorySlug + '?routeName=giftGuide')"
                          >
                            {{
                            data.name
                            }}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-2 sub-menu mb-0">
                      <h6 class="text-site-gray-900 fs-16 text-uppercase text-uppercase">BY PRICE</h6>
                      <ul class="list-unstyled">
                        <li>
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop?minPrice=0&maxPrice=250')"
                          >UNDER $250</a>
                        </li>
                        <li>
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop?minPrice=250&maxPrice=500')"
                          >$250 - $500</a>
                        </li>
                        <li>
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop?minPrice=500&maxPrice=750')"
                          >$500 - $750</a>
                        </li>
                        <li>
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop?minPrice=750&maxPrice=1000')"
                          >$750 - $1,000</a>
                        </li>
                        <li>
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop?minPrice=1000&maxPrice=2000')"
                          >$1,000 - $2,000</a>
                        </li>
                        <li>
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop?minPrice=2000&maxPrice=10000')"
                          >$2,000+</a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-5 sub-menu">
                      <div class="d-flex gap-3 align-items-end">
                        <div class="position-relative">
                          <img width="240" height="240" alt="Gift Guide" :src="giftGuideCMS.image" />
                          <!-- <span class="offer">15% Off</span> -->
                        </div>
                        <div>
                          <p
                            class="ff-montserrat300 fs-16 lh-sm mb-2"
                            v-html="giftGuideCMS.description"
                          ></p>
                          <a class="btn btn-outline-dark mb-4" :href="giftGuideCMS.buttonLink">
                            {{
                            giftGuideCMS.buttonText
                            }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="dropdown-menu w-100 d-lg-none px-3"
                  :class="isDropdownMenuShow2 ? 'show' : ''"
                >
                  <div class="row">
                    <div class="col-12 sub-menu mb-md-0 mb-4">
                      <router-link to="/shop?routeName=giftGuide" class="menu-item">
                        <h6
                          @click="DropdownMenuShow2()"
                          class="ff-montserrat500 text-site-black fs-19"
                        >
                          Shop all GIFT
                          Guide
                        </h6>
                      </router-link>
                    </div>
                    <div class="col-12 sub-menu mb-md-0 mb-4">
                      <ul class="list-unstyled">
                        <li
                          @click="DropdownMenuShow2()"
                          v-for="(data, index) in categoryList"
                          :key="index"
                        >
                          <a
                            class="menu-item"
                            href="javascript:void(0)"
                            @click="$router.push('/shop/category/' + data.categorySlug + '?routeName=giftGuide')"
                          >{{ data.name }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>

              <li @click="closeSidebar" class="nav-item col-12 col-lg-auto">
                <router-link
                  class="nav-link py-2 px-0 px-lg-2 ff-montserrat300 fs-16 text-site-black"
                  to="/style"
                >STYLE</router-link>
              </li>
              <li @click="closeSidebar" class="nav-item col-12 col-lg-auto">
                <router-link
                  class="nav-link py-2 px-0 px-lg-2 ff-montserrat300 fs-16 text-site-black"
                  to="/sales"
                >SALES</router-link>
              </li>
              <li @click="closeSidebar" class="nav-item col-12 col-lg-auto">
                <router-link
                  class="nav-link py-2 px-0 px-lg-2 ff-montserrat300 fs-16 text-site-black"
                  :to="{ path: 'shop', query: { routeName: 'merch' } }"
                >MERCH</router-link>
              </li>
              <li @click="closeSidebar" class="nav-item col-12 col-lg-auto">
                <router-link
                  class="nav-link py-2 px-0 px-lg-2 ff-montserrat300 fs-16 text-site-black"
                  to="/learn"
                >LEARN</router-link>
              </li>
              <li @click="closeSidebar" class="nav-item col-12 col-lg-auto">
                <router-link
                  class="nav-link py-2 px-0 px-lg-2 ff-montserrat300 fs-16 text-site-black"
                  to="/gallery"
                >GALLERY</router-link>
              </li>
              <li @click="closeSidebar" class="nav-item col-12 col-lg-auto">
                <router-link
                  class="nav-link py-2 px-0 px-lg-2 ff-montserrat300 fs-16 text-site-black"
                  to="/about"
                >ABOUT</router-link>
              </li>
              <li @click="closeSidebar" class="nav-item col-12 col-lg-auto">
                <router-link
                  class="nav-link py-2 px-0 px-lg-2 ff-montserrat300 fs-16 text-site-black"
                  to="/collection"
                >COLLECTIONS</router-link>
              </li>
              <li @click="closeSidebar" v-if="!token" class="nav-item col-12 d-block d-lg-none">
                <button
                  class="btn border-0 login-btn-hover round_5 ps-0"
                  type="button"
                  id="button-addon2"
                  @click="$router.push('/login')"
                >Login / Register</button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  </div>
  <!-- <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="d-flex justify-content-between mx-3 mt-3">
      <h5 id="offcanvasRightLabel" class="my-auto">CART</h5>
      <button type="button" class="btn-close text-reset fs-13 close-cart-side-modal" data-bs-dismiss="offcanvas"
        aria-label="Close"></button>
    </div>
    <hr />
    <div class="offcanvas-body">
      <div v-if="store.cartValue.length > 0">
        <div class="" v-for="(data, index) in store.cartValue" :key="index">
          <div class="d-flex justify-content-between mx-3 py-3">
            <div style="height: 80px;width: 80px">
              <img @click.stop.prevent="$router.push('/product-details/' + data.data.id)"
                class="round_5 border w-100 h-100 cursor-pointer"
                :src="data.data.featuredImage ? data.data.featuredImage : data.data.image" :alt="data.data.name" />
            </div>
            <div class="w-50">
              <p class="fs-13 my-auto fw-bold cursor-pointer"
                @click.stop.prevent="$router.push('/product-details/' + data.data.id)">{{ data.data.name }}</p>
              <div class="fs-13 mt-2">
                <div v-if="data.data.type == 'product'">
                  <p class="mb-0" v-if="data.data.choosedColor && data.data.choosedColor != 'N/A'">
                    Color:&nbsp;{{ data.data.choosedColor }}</p>
                  <p class="mb-0" v-if="data.data.choosedSize && data.data.choosedSize != 'N/A'">
                    Size:&nbsp;{{ data.data.choosedSize }}</p>
                  <p class="mb-0" v-if="data.data.choosedMaterial && data.data.choosedMaterial != 'N/A'">
                    Material:&nbsp;{{ data.data.choosedMaterial }}</p>
                  <p>Price:&nbsp;${{ data.data.discountPrice === 0.00 ? data.data.discountPrice : data.data.price }}</p>
                </div>
                <div v-else>
                  <p class="mb-0">Gift Card</p>
                </div>
              </div>
              <p class="mt-3 fw-bold">${{
                parseFloat(data.quantity * (data.data.discountPrice === 0.00 ?
                  data.data.discountPrice : data.data.price)).toFixed(2)
              }}</p>

              <div class="w-25">
                <div class="form-control d-flex align-items-center justify-content-between py-2"
                  style="min-width: 100px">
                  <a @click="decreaseProductQuantity(index)" class="btn border-0 py-0 px-0 bg-transparent">
                    <i class="bi bi-dash-lg"></i>
                  </a>
                  <span class="ff-montserrat400 fs-17">{{ data.quantity }}</span>

                  <a @click="increaseProductQuantity(index)" class="btn border-0 py-0 px-0 bg-transparent">
                    <i class="bi bi-plus-lg"></i>
                  </a>
                </div>
              </div>
            </div>
            <div>
              <button title="delete" class="btn btn-sm btn-danger action_btn px-2" @click="removeFromCart(index)">
                X
              </button>
            </div>
          </div>
          <hr />

        </div>
        <div>
          <div class="d-flex justify-content-between mx-3">
            <p class="fs-13 my-auto">Subtotal</p>
            <p class="fw-bold fs-20 my-auto"><span v-if="store.totalAmount">${{
              parseFloat(store.totalAmount).toFixed(2)
            }}</span></p>
          </div>
          <div class="mx-3 mt-4">

            <button v-if="!token"
              class="w-48 btn bg-site-Red border-site-Red ff-montserrat500 fs-12 text-white text-uppercase mobile-space"
              @click="$router.push(token ? '/checkout' : '/login')"
              :style="{ 'background-color': cartData.checkoutButtonColor + '!important', 'border-color': cartData.checkoutButtonColor + '!important' }">Login
              & Checkout</button>

            <button v-else
              class="w-48 btn bg-site-Red border-site-Red ff-montserrat500 fs-12 text-white text-uppercase mobile-space"
              @click="$router.push(token ? '/checkout' : '/login')"
              :style="{ 'background-color': cartData.checkoutButtonColor + '!important', 'border-color': cartData.checkoutButtonColor + '!important' }">Checkout</button>

            <button
              class="w-48 align-self-stretch btn bg-site-Red border-site-Red ff-montserrat500 fs-12 text-white text-uppercase mobile-space"
              @click="$router.push('/checkout')"
              :style="{ 'background-color': cartData.checkoutButtonColor + '!important', 'border-color': cartData.checkoutButtonColor + '!important', 'float': 'right' }">Guest
              Checkout</button>


            <p class="mt-3 fs-13">Shipping, taxes, and discount codes are calculated at checkout</p>
          </div>
        </div>
        <li class="d-flex align-items-center justify-content-center py-3">
          <button class="btn btn-sm bg-site-Red border-site-Red text-white" data-bs-dismiss="offcanvas"
            :style="{ 'background-color': cartData.cartButtonColor + '!important', 'border-color': cartData.cartButtonColor + '!important' }"
            @click="$router.push('/cart')">View Shopping Cart</button>
        </li>
      </div>
      <div v-else>
        <li class="d-flex align-items-center justify-content-center py-3">Cart is empty!</li>
      </div>
    </div>
  </div>-->
</template> 
<script src="../js/header.js">
</script>
  
<style scoped>
@import "../css/header.css";

.cursor-pointer {
  cursor: pointer;
}
</style>
