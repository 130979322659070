import { store } from "../../../store";

export default {
    name: "Footer",
    data() {
        return {
            store,
            email: ""
        };
    },
    methods: {
        addSubscription: async function () {
            if (this.email) {
                let data = {
                    email: this.email
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.newsletter.subscription,
                    data: data
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: "You have successfully subscribed!",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.email = '';
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } else {
                this.$swal.fire({
                    icon: "error",
                    text: "Enter email address!"
                });
            }
        }
    }
};