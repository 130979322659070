import { store } from "../../../store";

export default {
    name: "Header",
    data() {
        return {
            store,
            token: localStorage.getItem("token"),
            categoryList: [],
            isDropdownMenuShow1: false,
            isDropdownMenuShow2: false,
            giftGuideCMS: {},
            shopGuideCMS: {},
            isShopPage: false,
            regexp: /android|iphone|kindle|ipad/i,
            isMobileDevice: false,
            cartData: {},

        }
    },
    created() {
        let device = navigator.userAgent;
        if (this.regexp.test(device)) {
            this.isMobileDevice = true;
        } else {
            this.isMobileDevice = false;
        }
    },
    async mounted() {
        await this.getGiftGuidMenu();
        await this.getShopGuidMenu();
        //await this.getCartCmsData();
    },
    methods: {
        getShopGuidMenu: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.shopGuideMenu.requestURL,
            };
            await this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.shopGuideCMS = response.data.data[0];
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            })
        },
        getGiftGuidMenu: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.giftGuide.menu,
            };
            await this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.giftGuideCMS = response.data.data.giftGuideCMS[0];
                    this.categoryList = response.data.data.productParentCategory;
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            })
        },
        // getCartCmsData: async function () {

        //     let config = {
        //         method: "GET",
        //         url: this.$serverURL + this.$api.cartCMS.cartCmsURL,
        //     };
        //     this.isLoading = true;
        //     await this.$axios(config).then((response) => {
        //         this.isLoading = false;
        //         if (response.data.statusCode == 200) {
        //             this.cartData = response.data.data.length>0 ? response.data.data[0] : {};
        //         }
        //     }).catch(error => {
        //         this.isLoading = false;
        //         console.log(error);
        //         this.$swal.fire({
        //             icon: "error",
        //             text: error.response.data.message
        //         });
        //     })
        // },
        closeSidebar() {
            document.getElementById('close_sidebar').click()
            this.isDropdownMenuShow1 = false;
            this.isDropdownMenuShow2 = false;
        },
        goShop() {
            this.$router.push('/shop')
        },
        goGiftGuide() {
            this.$router.push('/shop?routeName=giftGuide')
        },
        DropdownMenuShow1() {
            this.isDropdownMenuShow1 = !this.isDropdownMenuShow1;
            document.getElementById('close_sidebar').click()
        },
        DropdownMenuShow2() {
            this.isDropdownMenuShow2 = !this.isDropdownMenuShow2;
            document.getElementById('close_sidebar').click()
        },

    },
    watch: {
        $route() {
            this.token = localStorage.getItem("token");
            if (this.$route.path == "/shop") {
                this.isShopPage = true;

            } else {
                this.isShopPage = false;
            }
        }
    }
}
