import { authHeader } from "../../../../../auth";
import Loading from "vue-loading-overlay";
import { store } from "@/store";
export default {
    components: {
        Loading
    },
    data() {
        return {
            store,
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            
            card: {
                name: "",
                cardNumber: "",
                securityCode: "",
                expireDate: ""
            },
            singleCard: {
                id: "",
                name: "",
                cardNumber: "",
                securityCode: "",
                expireDate: ""
            },
        };
    },
    async created() {
        document.title = "Golden Gal - Dashboard";
        await this.getCardList();
    },
    methods: {
        

        formatCCnum: function () {
            if (this.card.cardNumber.length == 4) {
                this.card.cardNumber += " ";
            }
            if (this.card.cardNumber.length == 9) {
                this.card.cardNumber += " ";
            }
            if (this.card.cardNumber.length == 14) {
                this.card.cardNumber += " ";
            }
        },

        cardInfoValidation: function () {
            if (!this.card.name) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card holder name."
                });
                return false;
            } else if (!this.card.cardNumber) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card number."
                });
                return false;
            } else if (this.card.cardNumber.replace(' ', '').length <= 16) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Card number must be at least 16 digit"
                });
                return false;
            } else if (!this.card.expireDate) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card expire date."
                });
                return false;
            } else if (!this.card.securityCode) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card security code."
                });
                return false;
            } else {
                if (!this.card.expireDate.includes('/')) {
                    this.$swal.fire({
                        icon: 'info',
                        text: "Expire date format should be 02/22."
                    });
                    return false;
                }
                return true;
            }
        },

        addCard: async function () {
            if (this.cardInfoValidation()) {
                // console.log("num",this.card.cardNumber);
                // console.log("num",this.card.cardNumber.split(' ').join(''));
                let data = {
                    name: this.card.name,
                    ccNum: this.card.cardNumber.split(' ').join(''),
                    cvv: this.card.securityCode,
                    ccExp: this.card.expireDate ? this.card.expireDate.replace('/', '') : ''
                }
                this.isLoading = true;
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.card.cardURL,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 201) {
                        this.card = {
                            name: "",
                            cardNumber: "",
                            securityCode: "",
                            expireDate: ""
                        };
                        await this.getCardList();
                        document.getElementById('addModalCloseBtn').click()

                    }
                    this.isLoading = false;
                }).catch(error => {
                    console.log(error);
                });
            }
        },

        editCard: function (data) {
            this.singleCard.id = data.id;
            this.singleCard.name = data.name;
            this.singleCard.cardNumber = data.ccNum.slice(0, 4) + " " + data.ccNum.slice(4, 8) + " " + data.ccNum.slice(8, 12) + " " + data.ccNum.slice(12);
            this.singleCard.securityCode = data.cvv;
            if (data.ccExp.length > 2) {
                this.singleCard.expireDate = data.ccExp.slice(0, 2) + '/' + data.ccExp.slice(2);
            }

        },

        editFormatCCnum: function () {
            if (this.singleCard.cardNumber.length == 4) {
                this.singleCard.cardNumber += " ";
            }
            if (this.singleCard.cardNumber.length == 9) {
                this.singleCard.cardNumber += " ";
            }
            if (this.singleCard.cardNumber.length == 14) {
                this.singleCard.cardNumber += " ";
            }
        },

        updateCard: async function () {
            let data = {
                name: this.singleCard.name,
                ccNum: this.singleCard.cardNumber.split(' ').join(''),
                cvv: this.singleCard.securityCode,
                ccExp: this.singleCard.expireDate ? this.singleCard.expireDate.replace('/', '') : ''
            }
            this.isLoading = true;
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.card.cardURL + '/' + this.singleCard.id,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.singleCard = {
                        id: "",
                        name: "",
                        cardNumber: "",
                        securityCode: "",
                        expireDate: ""
                    };
                    await this.getCardList();
                    document.getElementById('updateModalCloseBtn').click()

                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },

        updateDefault: async function (data) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Make it default!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        let config = {
                            method: "PATCH",
                            url: this.$serverURL + this.$api.card.cardURL + '/default/' + data.id,
                            headers: authHeader()
                        };
                        await this.$axios(config).then(async (response) => {
                            this.isLoading = false
                            if (response.status == 200) {
                                await this.getCardList();
                            }
                        }).catch(error => {
                            this.isLoading = false
                            console.log(error);
                        });
                    }
                }
            })
        },

        processExpDate: function () {
            if (this.card.expireDate.length == 2) {
                this.card.expireDate += '/';
            }
            if (this.singleCard?.expireDate.length == 2) {
                this.singleCard.expireDate += '/';
            }
        },

        deleteCard: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: this.$serverURL + this.$api.card.cardURL + '/' + id,
                                headers: authHeader(),
                            }
                            await this.$axios(config).then(async (response) => {
                                //console.log(response.data);
                                this.isLoading = false
                                if (response.data.deleted) {
                                    await this.getCardList();
                                }
                            }).catch(error => {
                                this.isLoading = false
                                console.log(error);
                            });
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong, please try agian later."
                            });
                        }
                    }
                }
            })
        },

    },

};
