<template>
  <div class="address">
    <div class="row gx-4">
      <div class="col-sm-12 col-md-6 mb-4">
        <div class="border rounded border-site-gray-dark py-4 px-4">
          <h4 class="fs-20 fw-800 d-flex gap-2 align-items-center justify-content-between">
            Billing Address
            <button
              class="btn btn-warning"
              data-bs-toggle="modal"
              data-bs-target="#billingModal"
            >Edit</button>
          </h4>

          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">Name</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
            >{{this.billingAddressData.name? this.billingAddressData.name: "N/A" }}</div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">Address One</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
            >{{this.billingAddressData.addressOne? this.billingAddressData.addressOne: "N/A" }}</div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">Address Two</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
            >{{this.billingAddressData.addressOne? this.billingAddressData.addressTwo: "N/A" }}</div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">Phone</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
            >{{this.billingAddressData.phone? this.billingAddressData.phone: "N/A" }}</div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">Email</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
              style="word-break: break-all"
            >{{this.billingAddressData.email? this.billingAddressData.email: "N/A" }}</div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">City</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
            >{{this.billingAddressData.city? this.billingAddressData.city: "N/A" }}</div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">State code</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
            >{{this.billingAddressData.stateCode? this.billingAddressData.stateCode: "N/A" }}</div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">Post code</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
            >{{this.billingAddressData.postCode? this.billingAddressData.postCode: "N/A" }}</div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-4">
        <div class="border rounded border-site-gray-dark py-4 px-4">
          <h4 class="fs-20 fw-800 d-flex align-items-center gap-2 justify-content-between">
            Shipping Address
            <button
              class="btn btn-warning"
              data-bs-toggle="modal"
              data-bs-target="#shippingModal"
            >Edit</button>
          </h4>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">Name</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
            >{{this.shippingAddressData.name? this.shippingAddressData.name: "N/A" }}</div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">Address One</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
            >{{this.shippingAddressData.addressOne? this.shippingAddressData.addressOne: "N/A" }}</div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">Address Two</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
            >{{this.shippingAddressData.addressOne? this.shippingAddressData.addressTwo: "N/A" }}</div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">Phone</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
            >{{this.shippingAddressData.phone? this.shippingAddressData.phone: "N/A" }}</div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">Email</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
              style="word-break: break-all"
            >{{this.shippingAddressData.email? this.shippingAddressData.email: "N/A" }}</div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">City</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
            >{{this.shippingAddressData.city? this.shippingAddressData.city: "N/A" }}</div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">State code</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
            >{{this.shippingAddressData.stateCode? this.shippingAddressData.stateCode: "N/A" }}</div>
          </div>
          <div class="row">
            <div class="col-6 col-sm-6 col-md-3 fw-500">Post code</div>
            <div
              class="col-6 col-sm-6 col-md-9 fs-13"
            >{{this.shippingAddressData.postCode? this.shippingAddressData.postCode: "N/A" }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Billing Address-->
  <div
    class="modal fade"
    id="billingModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Update Billing Address</h1>
          <button
            type="button"
            id="closeBill"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form class="col-md-12 col-sm-10 mx-auto form">
            <div class="row pb-4">
              <div class="form-group col-xs-12 col-sm-12">
                <label>Name</label>
                <input
                  name="email"
                  v-model="billingAddressData.name"
                  placeholder="Name"
                  class="form-control mb-3"
                  type="text"
                />
              </div>
              <div class="form-group col-xs-12 col-sm-12">
                <label>Address One</label>
                <textarea class="form-control mb-3" rows="3" v-model="billingAddressData.addressOne"
                placeholder="Address"></textarea>
                <!-- <GMapAutocomplete
                  @place_changed="setBillingAddressOne"
                  :value="billingAddressData.addressOne"
                  :class="'form-control mb-3'"
                ></GMapAutocomplete> -->
              </div>
              <div class="form-group col-xs-12 col-sm-12">
                <label>Address Two</label>
                <textarea class="form-control mb-3" rows="3" v-model="billingAddressData.addressTwo"
                placeholder="Address"></textarea>
                <!-- <GMapAutocomplete
                  @place_changed="setBillingAddressOne"
                  :value="billingAddressData.addressOne"
                  :class="'form-control mb-3'"
                ></GMapAutocomplete> -->
              </div>
              <div class="form-group col-xs-12 col-sm-12">
                <label>Email</label>
                <input
                  name="email"
                  placeholder="Email"
                  v-model="billingAddressData.email"
                  class="form-control mb-3"
                  type="email"
                />
              </div>
              <div class="form-group col-xs-12 col-sm-12">
                <label>Phone</label>
                <input
                  name="phone"
                  placeholder="Phone"
                  v-model="billingAddressData.phone"
                  class="form-control mb-3"
                  type="text"
                />
              </div>
              <div class="form-group col-xs-12 col-sm-12">
                <label>City</label>
                <input
                  name="City"
                  placeholder="City"
                  class="form-control mb-3"
                  v-model="billingAddressData.city"
                  type="text"
                />
              </div>
              <div class="form-group col-xs-12 col-sm-12">
                <label>State</label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="billingStateCode"
                >
                  <option style="display: none" value selected>Select State</option>
                  <option
                    v-for="(data, index) in states"
                    :key="index"
                    :value="data.code"
                  >{{ data.name }}</option>
                </select>
              </div>
              <div class="form-group col-xs-12 col-sm-12">
                <label>Post code</label>
                <input
                  name="postCode"
                  placeholder="Post code"
                  class="form-control"
                  v-model="billingAddressData.postCode"
                  type="text"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
          <button
            type="button"
            class="btn btn-primary border-site-Red bg-site-Red"
            @click="updateBillingAddress"
          >
            Save
            changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Shipping  Address-->
  <div
    class="modal fade"
    id="shippingModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Update Shipping Address</h1>
          <button
            type="button"
            id="closeShip"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form class="col-md-12 col-sm-10 mx-auto form">
            <div class="row pb-4">
              <div class="form-group col-xs-12 col-sm-12">
                <label>Name</label>
                <input
                  name="email"
                  v-model="shippingAddressData.name"
                  placeholder="Name"
                  class="form-control mb-3"
                  type="text"
                />
              </div>
              <div class="form-group col-xs-12 col-sm-12">
                <label>Address One</label>
                <textarea
                  class="form-control mb-3"
                  v-model="shippingAddressData.addressOne"
                  rows="3"
                  placeholder="Address"
                ></textarea>
              </div>
              <div class="form-group col-xs-12 col-sm-12">
                <label>Address Two</label>
                <textarea
                  class="form-control mb-3"
                  v-model="shippingAddressData.addressTwo"
                  rows="3"
                  placeholder="Address"
                ></textarea>
              </div>
              <div class="form-group col-xs-12 col-sm-12">
                <label>Email</label>
                <input
                  name="email"
                  placeholder="Email"
                  v-model="shippingAddressData.email"
                  class="form-control mb-3"
                  type="email"
                />
              </div>
              <div class="form-group col-xs-12 col-sm-12">
                <label>Phone</label>
                <input
                  name="phone"
                  placeholder="Phone"
                  v-model="shippingAddressData.phone"
                  class="form-control mb-3"
                  type="text"
                />
              </div>
              <div class="form-group col-xs-12 col-sm-12">
                <label>City</label>
                <input
                  name="City"
                  placeholder="City"
                  v-model="shippingAddressData.city"
                  class="form-control mb-3"
                  type="text"
                />
              </div>
              <div class="form-group col-xs-12 col-sm-12">
                <label>State</label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="shippingStateCode"
                >
                  <option style="display: none" value selected>Select State</option>
                  <option
                    v-for="(data, index) in states"
                    :key="index"
                    :value="data.code"
                  >{{ data.name }}</option>
                </select>
              </div>
              <div class="form-group col-xs-12 col-sm-12">
                <label>Post code</label>
                <input
                  name="postCode"
                  placeholder="Post code"
                  v-model="shippingAddressData.postCode"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
          <button
            type="button"
            class="btn btn-primary border-site-Red bg-site-Red"
            @click="updateShippingAddress"
          >
            Save
            changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/address.js"></script>
