<template>
    <teleport to="head">
        <meta name="keywords" :content="metaKeyword">
        <meta name="description" :content="metaDescription">
    </teleport>
</template>

<script>
export default {
    name: 'ShopSchemaJson',
    props: ['metaKeyword', 'metaDescription']
}   
</script>
