import Loading from "vue-loading-overlay";
import { authHeader } from "../../../../../auth";

export default {
    name: "Dashboard",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            dataList: {}
        }
    },
    async created() {
        document.title = "Golden Gal - Dashboard";
        await this.getDashboardData();
    },
    methods: {
        getDashboardData: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.dashboard.requestURL,
                headers: authHeader()
            }
            this.$axios(config).then(response => {
                this.isLoading = false;
                if (response.status == 200) {
                    this.dataList = response.data;
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            })
        }
    }
}

