<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <div :style="{'background-color': uiComponent.pageBackgroundColor}">
    <div class="page-banner">
      <div
        class="banner-title"
        :style="{'background-color': uiComponent.pageHeaderBackgroundColor}"
      >
        <h1
          class="ff-heading h-fs-xs-69 h-fs-122 h-fs-md-98"
          :style="{'color': uiComponent.pageHeaderFontColor + '!important'}"
        >{{ pageCms.title }}</h1>
      </div>
    </div>
    <!-- <div class="maps">
      <iframe :src="pageCms.mapLink" width="100%" height="600" style="border: 0" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>-->
    <div class="container py-4">
      <div class="row gx-3" v-if="contactAddess && contactAddess.length > 0">
        <div
          v-for="(data, index) in contactAddess"
          :key="index"
          class="col-sm-4 mb-4"
          :style="[active ? {'margin': 'auto !important'} : '']"
        >
          <div class="bg-light-white py-4 px-4">
            <h4 style="text-align: center;" class="fs-18 fw-700">{{ data.title }}</h4>
            <!-- <div class="row" v-if="data.address">
              <div class="col-3 col-sm-3 col-md-3 fw-500">Address</div>
              <div class="col-9 col-sm-9 col-md-9 fs-13">
                {{ data.address }}
              </div>
            </div>-->
            <div class="row" v-if="data.phone">
              <div class="col-3 col-sm-6 col-md-3 fw-500">Phone</div>
              <div class="col-9 col-sm-6 col-md-9 fs-13">{{ data.phone }}</div>
            </div>
            <div class="row mt-3" v-if="data.email">
              <div class="col-3 col-sm-6 col-md-3 fw-500">Email</div>
              <div class="col-9 col-sm-6 col-md-9 fs-13">
                <a
                  style=" color: #212529;  text-decoration: none;"
                  :href="'mailto:'+data.email"
                >{{ data.email }}</a>
              </div>
            </div>
            <div class="row pt-2">
              <a
                @click="$router.push('/learn')"
                style="text-align: left; color: #212529; text-decoration: none;"
                class="fs-18 fw-700"
              >
                <span style="cursor: pointer; ">
                  <i class="bi bi-link-45deg"></i> Learn
                </span>
              </a>
              <a
                @click="$router.push('/return-policy')"
                style="text-align: left;  color: #212529; text-decoration: none;"
                class="fs-18 fw-700"
              >
                <span style="cursor: pointer;">
                  <i class="bi bi-link-45deg"></i> Return Policy
                </span>
              </a>
              <a
                @click="$router.push('/gift-card')"
                style="text-align: left;  color: #212529; text-decoration: none;"
                class="fs-18 fw-700"
              >
                <span style="cursor: pointer;">
                  <i class="bi bi-link-45deg"></i> Gift Card
                </span>
              </a>
            </div>
          </div>
        </div>
        <!-- <div class="col-sm mb-4">
        <div class="bg-light-white py-4 px-4">
          <h4 class="fs-18 fw-700">Norway</h4>
          <div class="row">
            <div class="col-3 col-sm-3 col-md-3 fw-500">Address</div>
            <div class="col-9 col-sm-9 col-md-9 fs-13">
              Salzburger Strasse 665431 GASTEIG
            </div>
          </div>
          <div class="row">
            <div class="col-3 col-sm-6 col-md-3 fw-500">Phone</div>
            <div class="col-9 col-sm-6 col-md-9 fs-13">0699 261 97 01</div>
          </div>
          <div class="row">
            <div class="col-3 col-sm-6 col-md-3 fw-500">Email</div>
            <div class="col-9 col-sm-6 col-md-9 fs-13">palle@example.com</div>
          </div>
        </div>
      </div>
      <div class="col-sm mb-4">
        <div class="bg-light-white py-4 px-4">
          <h4 class="fs-18 fw-700">Slovenia</h4>
          <div class="row">
            <div class="col-3 col-sm-3 col-md-3 fw-500">Address</div>
            <div class="col-9 col-sm-9 col-md-9 fs-13">
              Salzburger Strasse 665431 GASTEIG
            </div>
          </div>
          <div class="row">
            <div class="col-3 col-sm-6 col-md-3 fw-500">Phone</div>
            <div class="col-9 col-sm-6 col-md-9 fs-13">0699 261 97 01</div>
          </div>
          <div class="row">
            <div class="col-3 col-sm-6 col-md-3 fw-500">Email</div>
            <div class="col-9 col-sm-6 col-md-9 fs-13">palle@example.com</div>
          </div>
        </div>
        </div>-->
      </div>
    </div>
    <div class="contact-form">
      <h2
        class="ff-heading h-fs-122 h-fs-md-98 h-fs-xs-69 text-center"
        :style="{'color': uiComponent.pageTitleFontColor + '!important'}"
      >{{ pageCms.formTitle }}</h2>
      <h4
        class="fs-16 text-center mb-4"
        :style="{'color': uiComponent.pageSubTitleFontColor + '!important'}"
      >{{ pageCms.formSubTitle }}</h4>
      <div class="container">
        <div class="row">
          <form
            @submit.prevent="sendMessage"
            class="col-xl-8 col-lg-8 col-md-8 col-sm-10 mx-auto form"
          >
            <div class="row pb-4">
              <div class="form-group col-xs-12 col-sm-12">
                <label>Full Name</label>
                <input v-model="name" placeholder="Full Name" class="form-control" type="text" />
              </div>
            </div>
            <div class="row pb-4">
              <div class="form-group col-xs-12 col-sm-6">
                <label>Phone Number</label>
                <input v-model="phone" placeholder="Phone Number" class="form-control" type="text" />
              </div>
              <div class="form-group col-xs-12 col-sm-6">
                <label>Instagram User Name</label>
                <input
                  v-model="website"
                  placeholder="Instagram User Name"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
            <div class="row pb-4">
              <div class="form-group col-xs-12 col-sm-6">
                <label>Email Address</label>
                <input
                  v-model="email"
                  placeholder="Email Address"
                  class="form-control"
                  type="email"
                />
              </div>
              <div class="form-group col-xs-12 col-sm-6">
                <label>Preferred Method of Contact</label>
                <select
                  class="form-select border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16 shadow-none"
                  v-model="method"
                >
                  <option style="display:none" selected value>Select Method of Contact:</option>
                  <option value="Phone">Phone</option>
                  <option value="Email">Email</option>
                  <option value="DM">DM</option>
                </select>
              </div>
            </div>
            <div class="row pb-4">
              <div class="form-group col-sm-12">
                <label>Your Message</label>
                <textarea
                  v-model="message"
                  class="form-control"
                  placeholder="Your Message"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-primary btn-contact border-site-Red bg-site-Red"
            >Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/contact.js"></script>