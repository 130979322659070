<template>
  <div :style="{'background-color': uiComponent.pageBackgroundColor}">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <section class="shop-section py-3" v-if="galleryData.length > 0">
      <div class="container">
        <div class="text-center" v-if="Object.values(cms).length > 0">
          <h1 class="ff-montserrat400 fs-25 text-site-Red" :style="{'color': uiComponent.pageTitleFontColor + '!important' }">{{cms.title}}</h1>
          <h1 class="ff-heading h-fs-49 h-fs-md-39 h-fs-sm-30 lh-sm" :style="{'color': uiComponent.pageSubTitleFontColor + '!important' }">{{cms.subTitle}}</h1>
          <div class="shop-section-heading-footer"></div>
        </div>
        <div class="row justify-content-center g-3 pt-5">
          <div v-for="(data, index) in galleryData" :key="index" class="col-9 col-sm-6 col-md-3">
            <div class="card shop-section-carousel-card w-100 border-0 rounded-1" data-aos="fade-up-left" data-aos-duration="2000" >
              <img  :src="data.image" class="card-img-top rounded-1" alt="..." />
              <div
                class="shop-section-carousel-card-hover d-flex justify-content-center align-items-center rounded-1"
                :style="{'background-color': store.hoverColor, 'cursor': 'pointer'}"
              >
                <div class="text-center">
                  <a class="text-white" href="javascript:void(0)">
                    <i class="bi bi-instagram fs-25"></i>
                  </a>
                  <br />
                  <br />
                  <template v-if="data.linkType=='External'">
                      <a
                      class="btn btn-outline-secondary border-white text-white text-uppercase ff-montserrat700"
                      :href="data.link"
                    >{{data.name}}</a>
                  </template>
                  <template v-else>
                      <a
                      class="btn btn-outline-secondary border-white text-white text-uppercase ff-montserrat700"
                      href="javascript:void(0)"
                      @click="$router.push(data.link)"
                    >{{data.name}}</a>
                  </template>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="shop-section py-3">
      <div class="container-fluid">
        <div class="gallery">
          <img v-for="(data, index) in galleryData" :key="index" :src="data.image" />
        </div>
      </div>
    </section>-->
  </div>
</template>

<script src="../js/gallery.js">
</script>

<style scoped>
@import "../css/gallery.css";
</style>
