<template>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
  >
    <div
      v-if="isWelcomeCouponExist==true && store.isNewUser==true"
      class="d-flex justify-content-between mx-3 mt-3"
      style="display: flex"
    >
      <p class="free_text fw-bold">{{ cartCMS.newUserWelcomeMessage }}</p>
    </div>
    <div v-else class="d-flex justify-content-between mx-3 mt-3" style="display: flex">
      <p class="free_text fw-bold">{{ cartCMS.regularUserWelcomeMessage }}</p>
    </div>
    <div class="d-flex justify-content-between mx-3 mt-3">
      <h5 id="offcanvasRightLabel" class="my-auto">CART</h5>
      <button
        type="button"
        class="btn-close text-reset fs-13 close-cart-side-modal"
        data-bs-dismiss="offcanvas"
        id="offCanvasCloseBtn"
        aria-label="Close"
      ></button>
    </div>
    <div
      v-if="isFreeShipping==true"
      class="d-flex justify-content-between mx-3 mt-3"
      style="display: flex"
    >
      <p class="sub_text fw-bold">Congratulations! Your order qualifies for free shipping.</p>
    </div>
    <div v-else class="d-flex justify-content-between mx-3 mt-3" style="display: flex">
      <p class="sub_text fw-bold">You are ${{ dueAmount }} away from free shipping.</p>
    </div>
    <div class="progress mx-3">
      <div
        class="progress-bar cart_bar_bg"
        role="progressbar"
        :style="{ width: value + '%', }"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <!-- <div class="progress mx-3">
      <div class="progress-bar bg-danger" :class="value" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
    </div>-->

    <hr />
    <div class="offcanvas-body">
      <div v-if="store.cartValue.length > 0">
        <div class v-for="(data, index) in store.cartValue" :key="index">
          <div class="d-flex justify-content-between mx-3 py-3">
            <div style="height: 80px;width: 80px; cursor: pointer;" data-bs-dismiss="offcanvas">
              <img
                @click.stop.prevent="$router.push('/product-details/' + data.data.urlSlug)"
                class="round_5 border w-100 h-100 cursor-pointer"
                :src="data.data.featuredImage ? data.data.featuredImage : data.data.image"
                :alt="data.data.name"
              />
            </div>
            <div class="w-50">
              <p
                class="fs-13 my-auto fw-bold cursor-pointer"
                @click.stop.prevent="$router.push('/product-details/' + data.data.id)"
              >
                {{
                data.data.name
                }}
              </p>
              <div class="fs-13 mt-2">
                <div v-if="data.data.type == 'product'">
                  <p
                    class="mb-0"
                    v-if="data.data.choosedColor && data.data.choosedColor != 'N/A'"
                  >Color:&nbsp;{{ data.data.choosedColor }}</p>
                  <p
                    class="mb-0"
                    v-if="data.data.choosedSize && data.data.choosedSize != 'N/A'"
                  >Size:&nbsp;{{ data.data.choosedSize }}</p>
                  <p
                    class="mb-0"
                    v-if="data.data.choosedMaterial && data.data.choosedMaterial != 'N/A'"
                  >Material:&nbsp;{{ data.data.choosedMaterial }}</p>
                  <p>
                    Price:&nbsp;${{
                    data.data.discountPrice === 0.00 ? data.data.discountPrice :
                    data.data.price
                    }}
                  </p>
                </div>
                <div v-else>
                  <p class="mb-0">Gift Card</p>
                  <p>
                    Price:&nbsp;${{
                    data.data.discountPrice === 0.00 ? data.data.discountPrice :
                    data.data.price
                    }}
                  </p>
                </div>
              </div>
              <p class="mt-3 fw-bold">
                ${{
                parseFloat(data.quantity * (data.data.discountPrice === 0.00 ?
                data.data.discountPrice : data.data.price)).toFixed(2)
                }}
              </p>

              <div class="w-25">
                <div
                  class="form-control d-flex align-items-center justify-content-between py-2"
                  style="min-width: 100px"
                >
                  <a
                    @click="decreaseProductQuantity(index)"
                    class="btn border-0 py-0 px-0 bg-transparent"
                  >
                    <i class="bi bi-dash-lg"></i>
                  </a>
                  <span class="ff-montserrat400 fs-17">{{ data.quantity }}</span>

                  <a
                    @click="increaseProductQuantity(index)"
                    class="btn border-0 py-0 px-0 bg-transparent"
                  >
                    <i class="bi bi-plus-lg"></i>
                  </a>
                </div>
              </div>
            </div>
            <div>
              <button
                title="delete"
                class="btn btn-sm btn-danger action_btn px-2"
                @click="removeFromCart(index)"
              >X</button>
            </div>
          </div>
          <hr />
        </div>
        <div>
          <div class="d-flex justify-content-between mx-3">
            <p class="fs-13 my-auto">Subtotal:{{store.totalAmount}}</p>
            <p class="fw-bold fs-20 my-auto">
              <span v-if="store.totalAmount">
                ${{
                parseFloat(store.totalAmount).toFixed(2)
                }}
              </span>
            </p>
          </div>
          <div class="mx-3 mt-4">
            <div style="display: flex; justify-content: center;">
              <button
                v-if="!store.token"
                data-bs-dismiss="offcanvas"
                class="w-48 btn bg-site-Red border-site-Red ff-montserrat500 fs-12 text-white text-uppercase mobile-space m-2"
                @click="$router.push(token ? '/checkout' : '/login')"
                :style="{ 'background-color': cartData.checkoutButtonColor + '!important', 'border-color': cartData.checkoutButtonColor + '!important' }"
              >
                Login
                & Checkout
              </button>

              <button
                data-bs-dismiss="offcanvas"
                class="w-48 btn bg-site-Red border-site-Red ff-montserrat500 fs-12 text-white text-uppercase mobile-space m-2"
                @click="$router.push('/checkout')"
                :style="{ 'background-color': cartData.checkoutButtonColor + '!important', 'border-color': cartData.checkoutButtonColor + '!important' }"
              >
                {{
                store.token
                ? 'Checkout': 'Guest Checkout'
                }}
              </button>
            </div>

            <!-- <button
                            class="w-48 align-self-stretch btn bg-site-Red border-site-Red ff-montserrat500 fs-12 text-white text-uppercase mobile-space"
                            @click="$router.push('/checkout')"
                            :style="{ 'background-color': cartData.checkoutButtonColor + '!important', 'border-color': cartData.checkoutButtonColor + '!important', 'float': 'right' }">Guest
            Checkout</button>-->
            <p class="mt-3 fs-13">{{cartCMS.footerMessage}}</p>
          </div>
        </div>
        <li class="d-flex align-items-center justify-content-center py-3">
          <button
            class="btn btn-sm bg-site-Red border-site-Red text-white"
            data-bs-dismiss="offcanvas"
            :style="{ 'background-color': cartData.cartButtonColor + '!important', 'border-color': cartData.cartButtonColor + '!important' }"
            @click="$router.push('/cart')"
          >View Shopping Cart</button>
        </li>
      </div>
      <div v-else>
        <div class="alert alert-info" role="alert" style="text-align:center">Cart Is Empty!!</div>
      </div>
    </div>
  </div>
</template>

<script src="../js/cart-component.js"></script>
<style scoped>
@import "../css/cartComponent.css";
</style>