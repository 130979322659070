<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <section class="py-5">
      <div class="container">
        <div class="row g-3">
          <div class="col-12 col-md-7 col-lg-8" v-if="!showCard">
            <h1 class="ff-heading h-fs-md-69 mb-5">Checkout</h1>
            <div class="billing-details-section">
              <h2 class="ff-montserrat500 fs-18 mb-5">Billing Details</h2>
              <div class="row gy-3 mb-3">
                <div class="col-12 col-sm-12">
                  <label class="form-label ff-montserrat300 fs-16">
                    Name
                    <span style="color:red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                    placeholder="Enter name"
                    v-model="billingAddress.name"
                    @change="checkSB()"
                  />
                </div>
              </div>
              <div class="row gy-3 mb-3">
                <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">
                    Street Address
                    <span style="color:red">*</span>
                  </label>
                  <!-- <input
                    type="text"
                    class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                    placeholder="Street address one"
                    v-model="billingAddress.addressOne"
                    @change="checkSB()"
                  />-->
                  <GMapAutocomplete
                    @place_changed="setBillingAddressOne"
                    :value="billingAddress.addressOne"
                    :class="'form-control'"
                  ></GMapAutocomplete>
                </div>
                <!-- <div class="col-12 col-sm-6">
                <label class="form-label ff-montserrat300 fs-16">Street Address Two</label>-->
                <!-- <input
                    type="text"
                    class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                    placeholder="Brooklyn Simmons"
                    v-model="billingAddress.addressTwo"
                />-->
                <!-- <GMapAutocomplete
                    @place_changed="setBillingAddressTwo"
                    :value="billingAddress.addressTwo"
                    :class="'form-control'"
                ></GMapAutocomplete>-->
                <!-- </div> -->
                <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">
                    City
                    <span style="color:red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                    placeholder="Enter city"
                    v-model="billingAddress.city"
                    @change="checkSB()"
                  />
                </div>
              </div>

              <div class="row gy-3 mb-3">
                <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">
                    Zip Code
                    <span style="color:red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                    placeholder="Enter zip code"
                    v-model="billingAddress.postCode"
                    @change="checkSB()"
                  />
                </div>
                <!-- <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">Country / Region *</label>
                  <select class="form-select rounded-2 ff-montserrat500 fs-16">
                    <option class="ff-montserrat500 fs-16">United States (US)</option>
                  </select>
                </div>
                <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">Country / Region *</label>
                  <select class="form-select rounded-2 ff-montserrat500 fs-16">
                    <option class="ff-montserrat500 fs-16">Austria</option>
                  </select>
                </div>-->

                <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">
                    State
                    <span style="color:red">*</span>
                  </label>
                  <select
                    class="form-select rounded-2 ff-montserrat500 fs-16"
                    v-model="billingAddress.stateCode"
                    @change="checkSB()"
                  >
                    <option style="display:none" value>Select State</option>
                    <option
                      class="ff-montserrat500 fs-16"
                      v-for="(data, index) in states"
                      :key="index"
                      :value="data.code"
                    >{{ data.name }}</option>
                  </select>
                </div>
              </div>
              <div class="row gy-3 mb-3">
                <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">
                    Phone Number
                    <span style="color:red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                    placeholder="Enter phone number"
                    v-model="billingAddress.phone"
                    @change="checkSB()"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">
                    Email Address
                    <span style="color:red">*</span>
                  </label>
                  <input
                    type="email"
                    class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                    placeholder="Enter email address"
                    v-model="billingAddress.email"
                    @change="checkSB()"
                  />
                </div>
              </div>
            </div>
            <div class="billing-details-section mt-5">
              <h2 class="ff-montserrat500 fs-18 mb-3">Shipping Details</h2>
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value
                  id="flexCheckDefault"
                  v-model="sameAsBilling"
                  @change="setShippingAddress()"
                />
                <label class="form-check-label" for="flexCheckDefault">Same as Billing</label>
              </div>
              <div class="row gy-3 mb-3">
                <div class="col-12 col-sm-12">
                  <label class="form-label ff-montserrat300 fs-16">
                    Name
                    <span style="color:red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                    placeholder="Enter name"
                    v-model="shippingAddress.name"
                    @change="checkSB()"
                  />
                </div>
              </div>
              <div class="row gy-3 mb-3">
                <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">
                    Street Address
                    <span style="color:red">*</span>
                  </label>
                  <!-- <input
                    type="text"
                    class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                    placeholder="Street address one"
                    v-model="shippingAddress.addressOne"
                    @change="checkSB()"
                  />-->
                  <GMapAutocomplete
                    @place_changed="setShippingAddressOne"
                    :value="shippingAddress.addressOne"
                    :class="'form-control'"
                  ></GMapAutocomplete>
                </div>
                <!-- <div class="col-12 col-sm-6">
                <label class="form-label ff-montserrat300 fs-16">Street Address Two</label>-->
                <!-- <input
                    type="text"
                    class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                    placeholder="Street address two"
                    v-model="shippingAddress.addressTwo"
                />-->
                <!-- <GMapAutocomplete
                    @place_changed="setShippingAddressTwo"
                    :value="shippingAddress.addressTwo"
                    :class="'form-control'"
                ></GMapAutocomplete>-->
                <!-- </div> -->

                <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">
                    City
                    <span style="color:red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                    placeholder="Enter city"
                    v-model="shippingAddress.city"
                    @change="checkSB()"
                  />
                </div>
              </div>
              <div class="row gy-3 mb-3">
                <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">
                    Zip Code
                    <span style="color:red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                    placeholder="Enter zip code"
                    v-model="shippingAddress.postCode"
                    @change="checkSB()"
                  />
                </div>
                <!-- <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">Country / Region *</label>
                  <select class="form-select rounded-2 ff-montserrat500 fs-16">
                    <option class="ff-montserrat500 fs-16">United States (US)</option>
                  </select>
                </div>
                <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">Country / Region *</label>
                  <select class="form-select rounded-2 ff-montserrat500 fs-16">
                    <option class="ff-montserrat500 fs-16">Austria</option>
                  </select>
                </div>-->
                <!-- </div>
                <div class="row gy-3 mb-3">-->
                <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">
                    State
                    <span style="color:red">*</span>
                  </label>
                  <select
                    class="form-select rounded-2 ff-montserrat500 fs-16"
                    v-model="shippingAddress.stateCode"
                    @change="checkSB()"
                  >
                    <option style="display:none" value>Select State</option>
                    <option
                      class="ff-montserrat500 fs-16"
                      v-for="(data, index) in states"
                      :key="index"
                      :value="data.code"
                    >{{ data.name }}</option>
                  </select>
                </div>
              </div>
              <div class="row gy-3 mb-3">
                <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">
                    Phone Number
                    <span style="color:red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                    placeholder="Enter phone number"
                    v-model="shippingAddress.phone"
                    @change="checkSB()"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <label class="form-label ff-montserrat300 fs-16">
                    Email Address
                    <span style="color:red">*</span>
                  </label>
                  <input
                    type="email"
                    class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                    placeholder="Enter email address"
                    v-model="shippingAddress.email"
                    @change="checkSB()"
                  />
                </div>
              </div>
              <div class>
                <label class="form-label ff-montserrat600 fs-18">Additional Information</label>
                <textarea
                  type="text"
                  class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16"
                  placeholder="Enter additional information"
                  v-model="comment"
                ></textarea>
              </div>
            </div>
          </div>

          <div v-else class="col-12 col-md-7 col-lg-8">
            <div class="m-2" v-if="store.defaultCard.name" style="padding-left: 10px;">
              <label for="exampleFormControlInput1" class="form-label">Select Card Type:</label>
              <br />
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  value="new"
                  id="inlineRadio1"
                  v-model="cardType"
                  @change="handleCardType"
                />
                <label class="form-check-label" for="inlineRadio1">
                  <b>New</b>
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  value="default"
                  v-model="cardType"
                  id="inlineRadio2"
                  @change="handleCardType"
                />
                <label class="form-check-label" for="inlineRadio2">
                  <b>Default</b>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="payment-container preload">
                  <div class="creditcard">
                    <div class="front">
                      <div id="ccsingle"></div>
                      <svg
                        version="1.1"
                        id="cardfront"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 471"
                        style="enable-background: new 0 0 750 471"
                        xml:space="preserve"
                      >
                        <g id="Front">
                          <g id="CardBackground">
                            <g id="Page-1_1_">
                              <g id="amex_1_">
                                <path
                                  id="Rectangle-1_1_"
                                  class="lightcolor grey"
                                  d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                            C0,17.9,17.9,0,40,0z"
                                />
                              </g>
                            </g>
                            <path
                              class="darkcolor greydark"
                              d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z"
                            />
                          </g>
                          <text
                            transform="matrix(1 0 0 1 60.106 295.0121)"
                            id="svgnumber"
                            class="st2 st3 st4"
                          >
                            {{
                            paymentInfo.cardNumber
                            ? paymentInfo.cardNumber
                            : "0123 4567 8910 1112"
                            }}
                          </text>
                          <text
                            transform="matrix(1 0 0 1 54.1064 428.1723)"
                            id="svgname"
                            class="st2 st5 st6"
                          >
                            {{
                            paymentInfo.name ? paymentInfo.name : "JOHN DOE"
                            }}
                          </text>
                          <text
                            transform="matrix(1 0 0 1 54.1074 389.8793)"
                            class="st7 st5 st8"
                          >cardholder name</text>
                          <text
                            transform="matrix(1 0 0 1 479.7754 388.8793)"
                            class="st7 st5 st8"
                          >expiration</text>
                          <text
                            transform="matrix(1 0 0 1 65.1054 241.5)"
                            class="st7 st5 st8"
                          >card number</text>
                          <g>
                            <text
                              transform="matrix(1 0 0 1 574.4219 433.8095)"
                              id="svgexpire"
                              class="st2 st5 st9"
                            >
                              {{
                              paymentInfo.expireDate ? paymentInfo.expireDate : "01/23"
                              }}
                            </text>
                            <text
                              transform="matrix(1 0 0 1 479.3848 417.0097)"
                              class="st2 st10 st11"
                            >VALID</text>
                            <text
                              transform="matrix(1 0 0 1 479.3848 435.6762)"
                              class="st2 st10 st11"
                            >THRU</text>
                            <polygon class="st2" points="554.5,421 540.4,414.2 540.4,427.9 		" />
                          </g>
                          <g id="cchip">
                            <g>
                              <path
                                class="st2"
                                d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                        c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z"
                              />
                            </g>
                            <g>
                              <g>
                                <rect x="82" y="70" class="st12" width="1.5" height="60" />
                              </g>
                              <g>
                                <rect x="167.4" y="70" class="st12" width="1.5" height="60" />
                              </g>
                              <g>
                                <path
                                  class="st12"
                                  d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                            c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                            C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                            c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                            c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z"
                                />
                              </g>
                              <g>
                                <rect x="82.8" y="82.1" class="st12" width="25.8" height="1.5" />
                              </g>
                              <g>
                                <rect x="82.8" y="117.9" class="st12" width="26.1" height="1.5" />
                              </g>
                              <g>
                                <rect x="142.4" y="82.1" class="st12" width="25.8" height="1.5" />
                              </g>
                              <g>
                                <rect x="142" y="117.9" class="st12" width="26.2" height="1.5" />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g id="Back" />
                      </svg>
                    </div>
                    <div class="back">
                      <svg
                        version="1.1"
                        id="cardback"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 471"
                        style="enable-background: new 0 0 750 471"
                        xml:space="preserve"
                      >
                        <g id="Back">
                          <line class="st0" x1="35.3" y1="10.4" x2="36.7" y2="11" />
                        </g>
                        <g id="Back">
                          <g id="Page-1_2_">
                            <g id="amex_2_">
                              <path
                                id="Rectangle-1_2_"
                                class="darkcolor greydark"
                                d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                        C0,17.9,17.9,0,40,0z"
                              />
                            </g>
                          </g>
                          <rect y="61.6" class="st2" width="750" height="78" />
                          <g>
                            <path
                              class="st3"
                              d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                    C707.1,246.4,704.4,249.1,701.1,249.1z"
                            />
                            <rect x="42.9" y="198.6" class="st4" width="664.1" height="10.5" />
                            <rect x="42.9" y="224.5" class="st4" width="664.1" height="10.5" />
                            <path
                              class="st5"
                              d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z"
                            />
                          </g>
                          <text
                            transform="matrix(1 0 0 1 621.999 227.2734)"
                            id="svgsecurity"
                            class="st6 st7"
                          >
                            {{
                            paymentInfo.securityCode
                            ? paymentInfo.securityCode
                            : "985"
                            }}
                          </text>
                          <g class="st8">
                            <text
                              transform="matrix(1 0 0 1 518.083 280.0879)"
                              class="st9 st6 st10"
                            >security code</text>
                          </g>
                          <rect x="58.1" y="378.6" class="st11" width="375.5" height="13.5" />
                          <rect x="58.1" y="405.6" class="st11" width="421.7" height="13.5" />
                          <text
                            transform="matrix(1 0 0 1 59.5073 228.6099)"
                            id="svgnameback"
                            class="st12 st13"
                          >
                            {{
                            paymentInfo.name ? paymentInfo.name : "JOHN DOE"
                            }}
                          </text>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-container">
                  <div class="field-container">
                    <label class="payment-label" for="name">Name</label>
                    <input
                      class="payment-input"
                      id="name"
                      maxlength="20"
                      type="text"
                      v-model="paymentInfo.name"
                      :disabled="cardType == 'default'"
                    />
                  </div>
                  <div class="field-container">
                    <label class="payment-label" for="cardnumber">Card Number</label>
                    <!-- <span id="generatecard">generate random</span> -->
                    <input
                      id="cardnumber"
                      type="text"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      class="payment-input"
                      v-model="paymentInfo.cardNumber"
                      @keyup="formatCCnum"
                      :disabled="cardType == 'default'"
                    />
                    <svg
                      id="ccicon"
                      class="ccicon"
                      width="750"
                      height="471"
                      viewBox="0 0 750 471"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"></svg>
                  </div>
                  <div class="field-container">
                    <label class="payment-label" for="expirationdate">Expiration (mm/yy)</label>
                    <input
                      id="expirationdate"
                      class="payment-input"
                      type="text"
                      maxlength="5"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      v-model="paymentInfo.expireDate"
                      @keyup="handleExpireDate()"
                      :disabled="cardType == 'default'"
                    />
                  </div>
                  <div class="field-container">
                    <label class="payment-label" for="securitycode">Security Code</label>
                    <input
                      id="securitycode"
                      class="payment-input"
                      type="text"
                      maxlength="4"
                      pattern="[0-9]*"
                      v-model="paymentInfo.securityCode"
                      :disabled="cardType == 'default'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-5 col-lg-4">
            <h1 class="ff-montserrat400 fs-25">Your order</h1>
            <div class="border-bottom mb-3">
              <div class="d-flex align-items-center justify-content-between py-2 px-2">
                <p class="mb-0 ff-montserrat300 fs-16">Product</p>
                <p class="mb-0 ff-montserrat300 fs-18">Subtotal</p>
              </div>
              <div
                class="d-flex align-items-center justify-content-between py-2 px-2"
                v-for="(data, index) in store.cartValue"
                :key="index"
              >
                <p
                  @click.stop.prevent="$router.push('/product-details/' + data.data.urlSlug)"
                  :style="{ 'cursor': 'pointer' }"
                  class="mb-0 ff-montserrat300 fs-16 w-70"
                >
                  {{ data.data.name }} ×
                  {{ data.quantity }}
                </p>
                <p class="mb-0 ff-montserrat500 fs-18">
                  ${{ parseFloat((data.data.discountPrice === 0.00 ?
                  data.data.discountPrice : data.data.price) * data.quantity).toFixed(2)}}
                </p>
              </div>
            </div>
            <div class="bg-site-gray-500 py-2 px-2 mb-3" v-if="store.totalAmount > 0">
              <div class="d-flex align-items-center justify-content-between mb-3">
                <p
                  class="mb-0 ff-montserrat400 fs-18"
                  v-if="isFreeShipping == false"
                >Shipping Method:</p>
                <p class="mb-0 ff-montserrat400 fs-18" v-if="isFreeShipping == false">
                  <select
                    class="form-select rounded-2 ff-montserrat500 fs-16"
                    v-model="shippingMethodID"
                    @change="setSM()"
                  >
                    <option value selected style="display: none;">Choose</option>
                    <option
                    :hidden="data.name=='Free Shipping'"
                      class="ff-montserrat500 fs-16"
                      v-for="(data, index) in smList"
                      :key="index"
                      :value="data.id"
                    >{{ data.name }}</option>
                  </select>
                </p>
                <p
                  class="mb-0 ff-montserrat400 fs-18"
                  v-if="isFreeShipping == true"
                >{{ this.shippingMethodName }}</p>
                <p class="mb-0 ff-montserrat400 fs-18" v-if="isFreeShipping">0.00</p>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-3">
                <p class="mb-0 ff-montserrat400 fs-18">Subtotal:</p>
                <p
                  class="mb-0 ff-montserrat400 fs-18"
                >${{ parseFloat(store.totalAmount).toFixed(2) }}</p>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-3"
                v-if="(salesTaxAmount > 0)"
              >
                <p class="mb-0 ff-montserrat400 fs-18">{{ stRateTitle }} ({{ stRate }}%):</p>
                <p class="mb-0 ff-montserrat400 fs-18">(+)&nbsp;${{ salesTaxAmount }}</p>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-3"
                v-if="couponDiscount"
              >
                <p class="mb-0 ff-montserrat400 fs-18">Coupon Discount:</p>
                <p class="mb-0 ff-montserrat400 fs-18">(-)&nbsp;${{ couponDiscount }}</p>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-3"
                v-if="(shippingCost > 0)"
              >
                <p class="mb-0 ff-montserrat400 fs-18">Shipping Cost:</p>
                <p class="mb-0 ff-montserrat400 fs-18">(+)&nbsp;${{ shippingCost }}</p>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-3">
                <p class="mb-0 ff-montserrat400 fs-18">Grand Total:</p>
                <p class="mb-0 ff-montserrat400 fs-18">
                  ${{
                  couponDiscount?
                  parseFloat((((store.totalAmount - 0) + (shippingCost - 0) + (salesTaxAmount - 0)) - couponDiscount)).toFixed(2) :
                  parseFloat(((store.totalAmount - 0) + (shippingCost - 0) + (salesTaxAmount - 0))).toFixed(2)}}
                </p>
              </div>

              <!-- shipping -->
              <!-- <div class="d-flex align-items-start justify-content-between bg-site-gray-500 mb-3">
                <p class="mb-0 ff-montserrat400 fs-18">Shipping</p>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input shadow-none Shipping_cart_radio cp"
                      type="radio"
                      name="Shipping"
                      id="Shipping1"
                    />
                    <label
                      class="form-check-label ff-montserrat300 fs-16 cp"
                      for="Shipping1"
                    >Flat rate</label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input shadow-none Shipping_cart_radio cp"
                      type="radio"
                      name="Shipping"
                      id="Shipping2"
                    />
                    <label
                      class="form-check-label ff-montserrat300 fs-16 cp"
                      for="Shipping2"
                    >Free shipping</label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input shadow-none Shipping_cart_radio cp"
                      type="radio"
                      name="Shipping"
                      id="Shipping3"
                    />
                    <label
                      class="form-check-label ff-montserrat300 fs-16 cp"
                      for="Shipping3"
                    >Local Pickup</label>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-3">
                <p class="mb-0 ff-montserrat600 fs-18">Total</p>
                <p class="mb-0 ff-montserrat600 fs-18">$76.00</p>
              </div>-->
            </div>
            <!-- <div class="d-flex py-3 gap-3 justify-content-between">
            <div class="input-group mb-3 gap-3 w-55">-->
            <div v-if="isFreeShipping" style="display: flex">
              <p>
                <span class="me-1" style="color:red">*</span>Free shipping coupon is already in use
              </p>
            </div>
            <div class="row mb-3" v-if="(token && !isFreeShipping)">
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control w-100 round_5 mb-3"
                  placeholder="Coupon Code"
                  aria-label
                  aria-describedby="button-addon2"
                  v-model="couponCode"
                />
              </div>
              <div class="col-sm-3">
                <button
                  class="btn btn-outline-dark round_5"
                  type="button"
                  id="button-addon2"
                  @click="applyCoupon"
                >Apply</button>
              </div>
            </div>
            <div class="row mb-3" v-if="(!token && !isFreeShipping)">
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control w-100 round_5 mb-3"
                  placeholder="Coupon Code"
                  aria-label
                  aria-describedby="button-addon2"
                  v-model="couponCode"
                />
              </div>
              <div class="col-sm-3">
                <button
                  class="btn btn-outline-dark round_5"
                  type="button"
                  id="button-addon2"
                  @click="applyCoupon"
                >Apply</button>
              </div>
            </div>
            <!-- </div>
            </div>-->

            <!-- <div class="mb-3">
              <div class="form-check">
                <input
                  class="form-check-input shadow-none Shipping_cart_radio cp"
                  type="radio"
                  name="payments"
                  id="payments1"
                />
                <label
                  class="form-check-label ff-montserrat300 fs-16 cp"
                  for="payments1"
                >Direct bank transfer</label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input shadow-none Shipping_cart_radio cp"
                  type="radio"
                  name="payments"
                  id="payments2"
                />
                <label
                  class="form-check-label ff-montserrat300 fs-16 cp"
                  for="payments2"
                >Check payments</label>
              </div>
            </div>-->
            <p
              v-if="!showCard"
            >Please fill up billing, shipping information and shipping method to proceed.</p>
            <div class="mb-3">
              <button
                v-if="!showCard"
                class="btn bg-site-Red border-site-Red ff-montserrat500 fs-15 text-white text-uppercase"
                :disabled="disableShipping"
                @click="showCard = true"
                :hidden="showCard"
              >Placed order</button>
              <button
                v-else
                class="btn bg-site-Red border-site-Red ff-montserrat500 fs-15 text-white text-uppercase"
                @click="placeOrder()"
              >Confirm Payment</button>
            </div>
            <p class="mb-0">
              Your personal data will be used to process your order, support your experience throughout
              this website, and for other purposes described in our privacy policy.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script src="../js/checkout.js"></script>

<style scoped>
@import "../css/checkout.css";
</style>
