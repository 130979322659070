import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    name: "GiftCard",
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            page: 1,
            limit: 18,
            cardList: [],
            totalPage: 0,
            cms: {},
            uiComponent: {}
        }
    },
    async created() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.giftCardPageTitle ? this.pageTitles?.giftCardPageTitle : '');
        this.scrollToTop();
    },
    async mounted() {
        let params = '?page=' + this.page + '&limit=' + this.limit;
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.giftCard.requestURL + params,
        };
        await this.getGiftCardList(config);
    },
    methods: {
        next: async function() {
            if (this.page < parseInt(this.totalPage)){
                this.page ++;
            }
            await this.updateHandler();
        },
        previous: async function() {
            if (this.page > 1){
                this.page --;
            }
            await this.updateHandler();
        },
        getGiftCardList: async function (config) {
            this.isLoading = true;
            await this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.cardList = response.data.data.giftCard;
                    this.totalPage = response.data.data.pageCount;
                    this.cms = response.data.data.giftCardCms[0];
                    this.uiComponent = response.data.data.giftCardComponentCms.length > 0 ? response.data.data.giftCardComponentCms[0] : {};
                } else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! Please try again."
                    });
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            })
        },
        updateHandler: async function () {
            let params = '?page=' + this.page + '&limit=' + this.limit;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.giftCard.requestURL + params,
            };
            await this.getGiftCardList(config);
        }
    }
}