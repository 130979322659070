import {
    store
} from "./store";
import { authHeader } from "./auth";
export default {
    data() {
        return {
            store,
            cardList: [],
            pageTitles: {},
        }
    },
    methods: {
        getFooterData: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.footer.requestURL
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.store.footerCMSData = response.data.data.footerCms[0] ? response.data.data.footerCms[0] : {};
                    }
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong! Please try again."
                });
            }
        },
        getHoverColor: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.hoverComponent.hoverColorURL
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.store.hoverColor = response.data.data.length > 0 ? response.data.data[0].hoverColor : {};
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getPageTitles: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.pageTitleCMS.pageTitleCMSURL
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.pageTitles = response.data.data.length > 0 ? response.data.data[0] : {};
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getCardList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.card.cardURL,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                this.isLoading = false;
                if (response.status == 200) {
                    if (response.data.data.length > 0) {
                        this.cardList = await response.data.data;
                        this.cardList.map(el => {
                            if (el.isDefault) {
                                this.store.defaultCard.name = el.name;
                                this.store.defaultCard.number = el.ccNum;
                                this.store.defaultCard.securityCode = el.cvv;
                                this.store.defaultCard.expireDate = el.ccExp;
                            }
                        })
                    }
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            });
        },

        checkUserType: async function () {
            let userId = localStorage.getItem('id');
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.checkUser.checkUserURL + '?userId=' + userId,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.store.isNewUser = response.data.isNewUser;
                }else{
                    this.store.isNewUser = false;
                }
            }).catch(error => {
                console.log(error);
            });
        },

        scrollToTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },

        logout: function () {
            localStorage.removeItem('token');
            localStorage.removeItem("name");
            localStorage.removeItem("email");
            localStorage.removeItem("role");
            localStorage.removeItem("userType");
            localStorage.removeItem("id");
            this.store.token = null;
            window.location.replace("/");
        },

        clearLocalStorage() {
            localStorage.removeItem('token');
            localStorage.removeItem("name");
            localStorage.removeItem("email");
            localStorage.removeItem("role");
            localStorage.removeItem("userType");
            localStorage.removeItem("id");
            this.store.token = null;
        }

        // processWishListData(data, quantity) {
        //     data.choosedColor = "N/A";
        //     data.choosedSize = "N/A";
        //     data.choosedMaterial = "N/A";
        //     this.addToWishList(data, quantity);
        // },
        // processCartData(data, quantity) {
        //     data.choosedColor = "N/A";
        //     data.choosedSize = "N/A";
        //     data.choosedMaterial = "N/A";
        //     this.addToCart(data, quantity);
        // },
    }
}