import Loading from "vue-loading-overlay";
import { VueImageZoomer } from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';
import {
    Carousel,
    Navigation,
    Pagination,
    Slide
} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    name: "ProductDetails",
    components: {
        Loading,
        Carousel,
        Slide,
        Navigation,
        Pagination,
        VueImageZoomer,
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            id: this.$route.params.id,
            relevantProducts: [],
            productData: {},
            sizeObject: "",
            productImageSettings: {
                itemsToShow: 1,
                snapAlign: 'start',
                autoplay: 0,
                pauseAutoplayOnHover: true
            },
            thumbnailsSettings: {
                itemsToShow: 3,
                wrapAround: true,
                autoplay: 0,
                pauseAutoplayOnHover: true
            },
            relevantProductSettings: {
                itemsToShow: 1,
                wrapAround: true,
                autoplay: 0,
                pauseAutoplayOnHover: true
            },
            Productbreakpoints: {
                576: {
                    itemsToShow: 3
                },
                768: {
                    itemsToShow: 3
                },
                992: {
                    itemsToShow: 3
                }
            },
            relevantProductbreakpoints: {
                576: {
                    itemsToShow: 3
                },
                768: {
                    itemsToShow: 3
                },
                992: {
                    itemsToShow: 3
                }
            },
            currentSlide: 0,
            isGift: false,
            rating: 0,
            quantity: 1,
            size: "",
            color: "",
            material: "",
            uiComponent: {},
            isThisGift: false,
            hoverTitle: "",
            cmsData: {},
            extraDetails: [],
            sharing: {
                url: '',
                title: '',
                description: '',
                quote: '',
                hashtags: '',
                twitterUser: '',
                image:''
            },
            networks: [
                { network: 'email', name: 'Email', icon: 'fa fa-lg fa-envelope', color: '#333333' },
                { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook', color: '#1877f2' },
                { network: 'pinterest', name: 'Pinterest', icon: 'fab fah fa-lg fa-pinterest', color: '#bd081c' },
                { network: 'skype', name: 'Skype', icon: 'fab fah fa-lg fa-skype', color: '#00aff0' },
                { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
                { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
                { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
                { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
            ],
            friendEmail: "",
            secondFriendEmail: "",
            message: "",
            senderName: "",
            senderEmail: "",
            ringSize: "",
            isEmailCopy: false
        }
    },
    async created() {
        document.title = "Golden Gal - Product";
        this.scrollToTop();
        await this.getProductDetails();
        await this.getProductDetailsCMS();
        await this.getPageTitles();
        document.title = (this.pageTitles?.productDetailsPageTitle ? this.pageTitles?.productDetailsPageTitle : '') + ' ' + this.productData.name;
    },
    methods: {
        validateHintData: function () {
            if (!this.friendEmail) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please enter friend's email!"
                })
                return false;
            } else if (!this.senderName) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please enter your name!"
                })
                return false;
            } else if (!this.senderEmail) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please enter your email!"
                })
                return false;
            } else if (!this.message) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please write your message!"
                })
                return false;
            }
            return true;
        },
        saveHint: async function () {
            if (this.validateHintData()) {
                let data = {
                    friendEmail: this.friendEmail,
                    secondFriendEmail: this.secondFriendEmail,
                    senderName: this.senderName,
                    message: this.message,
                    senderEmail: this.senderEmail,
                    ringSize: this.ringSize,
                    productId: this.id,
                    isEmailCopy: this.isEmailCopy
                }
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.productHint.requestURL,
                    data: data
                }
                this.isLoading = true;
                this.$axios(config).then(response => {
                    this.isLoading = false;
                    if (response.data.statusCode == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        document.getElementById("closeButton").click();
                        this.friendEmail = "";
                        this.secondFriendEmail = "";
                        this.senderName = "";
                        this.senderEmail = "";
                        this.message = "";
                        this.ringSize = "";
                        this.isEmailCopy = false;
                    }
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: error.response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                })
            }
        },
        getProductDetails: async function () {
            try {
                this.isLoading = true;
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.product.requestURL + '/' + this.id
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.productData = response.data.data.productDetails;
                        this.uiComponent = response.data.data.productDetailsComponentCms.length > 0 ? response.data.data.productDetailsComponentCms[0] : {};
                        this.relevantProducts = response.data.data.relaventProduct;
                        this.rating = parseInt((this.productData.rating && this.productData.rating != 'null') ? this.productData.rating : 0);
                        this.isLoading = false;

                        let sizeData = this.productData.productSizeVariants;
                        this.sizeObject = sizeData.slice(0);
                        this.sizeObject.sort(function(a, b) {
                            return a.sizeName - b.sizeName;
                        });

                        this.size = this.productData.productSizeVariants.length > 0 ? this.sizeObject[0].sizeName : '';
                        // this.size = this.productData.productSizeVariants.length > 0 ? this.productData.productSizeVariants[0].sizeName : '';
                        this.color = this.productData.productColorVariants.length > 0 ? this.productData.productColorVariants[0].colorName : '';
                        this.extraDetails =  response.data.data.extraDetails.length > 0 ? response.data.data.extraDetails : [];
                        this.material = this.productData.productMaterialVariants.length > 0 ? this.productData.productMaterialVariants[0].materialName : '';

                   
                        this.sharing.url = this.$hostURL+'/product-details/'+this.id; 
                        this.sharing.title = this.productData.parentCategoryName+' | '+this.productData.name; 
                        this.sharing.description = this.productData.longDescription.replace(/<[^>]*>?/gm, '');
                        this.sharing.quote = this.productData.parentCategoryName+' | '+this.productData.name;
                        this.sharing.image = this.productData.featuredImage; 
                        this.sharing.hashtags = this.material?this.productData.parentCategoryName+', jewelry':this.productData.parentCategoryName+', goldengal, jewelry'; 
                        this.sharing.twitterUser = "goldengaljewelry.com"; 
                    }
                }).catch(error => {
                    this.isLoading = false;
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong! Please try again."
                });
            }
        },
        slideTo(val) {
            this.currentSlide = val;
        },
        decrease() {
            this.quantity--;
            if (this.quantity <= 0) {
                this.quantity = 1;
            }
        },
        increase() {
            if(this.productData.stock > this.quantity){
                this.quantity = this.quantity + 1;
            }
            else {
                this.$swal.fire({
                    icon: "info",
                    text: "In-stock only " + this.productData.stock + " items are available"
                })
            }
        },
        processCartData(data) {

            if (this.productData.productColorVariants.length > 0 && !this.color) {
                this.$swal.fire({
                    text: "Please select product color.",
                    icon: 'info'
                })
                return;
            } else if (this.productData.productSizeVariants.length > 0 && !this.size) {
                this.$swal.fire({
                    text: "Please select product size.",
                    icon: 'info'
                })
                return;
            } else if (this.productData.productMaterialVariants.length > 0 && !this.material) {
                this.$swal.fire({
                    text: "Please select product material.",
                    icon: 'info'
                })
                return;
            }
            //}
            data.choosedColor = this.color ? this.color : "N/A";
            data.choosedSize = this.size ? this.size : "N/A";
            data.choosedMaterial = this.material ? this.material : "N/A";
            data.isGift = this.isThisGift;
            data.type = "product"

            this.addToCart(data, this.quantity);
            this.$router.push('/shop')
        },
        goBack: function () {
            history.back();
        },
        getProductDetailsCMS: async function(){
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.productDetailCMS.hoverTitleURL,

                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        this.cmsData = response.data.data[0]
                     
                    }
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong! Please try again."
                });
            }

        }
    }
}
