import { store } from "../../../store";

export default {
    name: "Header",
    data() {
        return {
            store,
            token: localStorage.getItem("token"),
            cartData: [],
            couponList: [],
            isFreeShipping: false,
            isWelcomeCouponExist: false,
            welcomeCupon: {},
            cartCMS: [],
            dueAmount:0,
            value:'',
        
        }
    },
    async created() {
        this.calculateTotalAmount();
        await this.getCartUIData();
        await this.getCoupons();
        await this.getCartCmsData();
    },

    methods: {
        getCartUIData: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cart.uiData,
            };
            await this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.cartData = response.data.data[0];
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            })
        },
        getCartCmsData: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cart.cmsData
            };
            await this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.cartCMS = response.data.data[0];
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            })
        },
        getCoupons: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.coupon.couponsURL,
                };
                await this.$axios(config).then(response => {
                    this.couponList = response.data.data;
                    for (let index = 0; index < this.couponList.length; index++) {
                        const cupon = this.couponList[index];
                        if (cupon.couponType == "free shipping") {
                            if (this.store.totalAmount >= parseInt(cupon.discount)) {
                                this.isFreeShipping = true;
                                this.value= 100
                            } else {
                                this.isFreeShipping = false;
                                let res = parseInt(cupon.discount)-this.store.totalAmount;
                                this.dueAmount = res
                                let due =(this.store.totalAmount / parseInt(cupon.discount))*100
                                let remain = parseInt(due)
                                // console.log("TEST", remain);
                                // console.log("cupon: ", cupon.discount);
                                // console.log("total: ", this.store.totalAmount);
                                // console.log("Res: ", res);
                                this.value= remain
                            }
                            break;
                        }
                    }
                    let tempArr = this.couponList.filter(el => el.couponType == 'welcome');
                    if (tempArr.length > 0) {
                        this.welcomeCupon = tempArr[0];
                        this.isWelcomeCouponExist = true;
                    } else {
                        this.welcomeCupon = {};
                        this.isWelcomeCouponExist = false;
                    }

                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        },

    },
    computed: {
        checkAmount() {
            return this.store.totalAmount;
        },
        total: function () {
            return this.value * 10
        }
    },
    
    watch: {
        checkAmount: function () {
            this.getCoupons();
        },
        
    }
}
