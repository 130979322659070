<template>
  <div class="nav_tabs">
    <ul class="nav justify-content-center">
      <li class="nav-item">
        <a
          class="nav-link link-secondary active text-uppercase"
          id="dashboard-tab"
          data-bs-toggle="tab"
          data-bs-target="#dashboard"
          href="#"
        >Dashboard</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link link-secondary text-uppercase"
          id="orders-tab"
          data-bs-toggle="tab"
          data-bs-target="#orders"
          href="#"
        >Orders</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link link-secondary text-uppercase"
          id="return-product-tab"
          data-bs-toggle="tab"
          data-bs-target="#returnProductsTab"
          href="#"
        >Return Products</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link link-secondary text-uppercase"
          id="address-tab"
          data-bs-toggle="tab"
          data-bs-target="#address"
          href="#"
        >address</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link link-secondary text-uppercase"
          id="account-tab"
          data-bs-toggle="tab"
          data-bs-target="#account"
          href="#"
        >Account details</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link link-secondary text-uppercase"
          id="card-tab"
          data-bs-toggle="tab"
          data-bs-target="#cardSettings"
          href="#"
        >Payment Cards</a>
      </li>
    </ul>
  </div>
</template>