import Loading from "vue-loading-overlay";
import { authHeader } from "../../../../auth";
import html2pdf from "html2pdf.js";

export default {
    name: "Invoice",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "spinner",
            dataList: [],
            itemList: [],
            id: this.$route.params.id,
            billingAddress: {},
            shippingAddress: {},
            userDetails: {},
            productList: [],
            giftCardList: [],
            selectAll: "",
            selectedReturnProducts: [],
            returnProductList: [],
            returnReason: "",
            filteredRetrunList: [],
            hasNote: false,
            refundAmount: 0,
        };
    },
    async created() {
        document.title = "Golden Gal - Invoice";
        await this.getOrderDetails();
    },
    computed: {
        calculateTotal: function () {
            if (parseFloat(this.dataList.refundAmount) > 0) {
                return parseFloat(((this.dataList.total - 0) + (this.dataList.shippingMethodCost - 0) + (this.dataList.salesTaxAmount - 0)) - parseFloat(this.dataList.refundAmount)).toFixed(2)
            } else {
                return parseFloat(((this.dataList.total - 0) + (this.dataList.shippingMethodCost - 0) + (this.dataList.salesTaxAmount - 0))).toFixed(2)
            }
        }
    },
    methods: {
        getOrderDetails: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.order.detailsURL + "/" + this.id,
                headers: authHeader()
            };
            this.$axios(config)
                .then(response => {
                    this.isLoading = false;
                    if (response.data.statusCode == 200) {
                        this.itemList = []
                        this.dataList = response.data.Orders.length > 0 ? response.data.Orders[0] : {};
                        this.refundAmount = parseFloat(this.dataList.refundAmount);
                        this.userDetails = this.dataList.userDetails;
                        this.billingAddress = JSON.parse(this.dataList.shippingAddress);
                        this.shippingAddress = JSON.parse(this.dataList.shippingAddress);
                        this.productList = this.dataList.orderDetails;
                        this.giftCardList = this.dataList.giftCardDetails;
                        this.productList.map(el => {
                            if (el.quantity > el.returnQuantity) {
                                el.remainingQty = (el.quantity - el.returnQuantity);
                                el.insertQty = el.remainingQty;
                                el.note = "";
                                this.filteredRetrunList.push(el);
                            }
                            el.type = "Product";
                            el.price = el.itemPrice;
                            el.amount = el.itemSubTotal;
                            this.itemList.push(el);
                        });
                        this.giftCardList.map(el => {
                            let data = {
                                name: el.name,
                                featuredImage: el.image,
                                type: "Gift Card",
                                quantity: el.quantity,
                                price: el.itemPrice,
                                amount: el.itemSubTotal,
                                email: el.giftEmail ? el.giftEmail : ""
                            };
                            this.itemList.push(data);
                        });
                    }
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        generatePDF: function () {
            const doc = document.getElementById("invoice");
            var opt = {
                filename: this.dataList.orderId + ".pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
                pagebreak: { mode: "avoid-all", before: "#page2el" }
            };
            html2pdf()
                .from(doc)
                .set(opt)
                .save();
        },
        increaseQuantity: function (data) {
            if (data.insertQty >= data.remainingQty) {
                data.insertQty = data.remainingQty;
            } else {
                data.insertQty = data.insertQty + 1;
            }
        },
        decreaseQuantity: function (data) {
            if (data.insertQty <= 1) {
                data.insertQty = 1;
            } else {
                data.insertQty = data.insertQty - 1;
            }
        },
        handleSelectAll: function () {
            if (this.selectAll == true) {
                this.filteredRetrunList.map((el, index) => {
                    this.selectedReturnProducts[index] = true;
                    this.returnProductList.push(el);
                })
            } else if (this.selectAll == false) {
                this.filteredRetrunList.map((el, index) => {
                    this.selectedReturnProducts[index] = false;
                    this.returnProductList = [];
                })
            }
        },
        handleSelect: function (index, status, data) {
            if (status == true) {
                this.returnProductList[index] = data
            } else if (status == false) {
                this.returnProductList[index] = 0
            }
        },
        confirmReturn: async function () {
            if (this.returnProductList.length == 0 || this.selectedReturnProducts.length == 0) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select a product first."
                })
                return;
            }
            if (this.returnProductList.length > 0) {
                let requests = [...new Set(this.returnProductList)];
                let confirmedRequest = requests.filter(item => !(item == 0 || item == undefined));
                let productList = confirmedRequest.map(el => {
                    return {
                        id: el.productId,
                        name: el.name,
                        quantity: el.insertQty,
                        actualPrice: el.itemPrice,
                        note: el.note,
                    }
                })
                for (let i = 0; i < productList.length; i++) {
                    if (!productList[i].note) {
                        this.hasNote = false
                        break;
                    } else {
                        this.hasNote = true;
                    }
                }
                if (this.hasNote == false) {
                    this.$swal.fire({
                        text: "Please give reason for return products.",
                        icon: "info"
                    })
                    return;
                }
                this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Return Product!'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        document.getElementById('close-btn').click();
                        let data = {
                            orderId: this.dataList.orderId,
                            customerId: this.dataList.customerId,
                            customerName: this.dataList.customerName,
                            products: productList,
                        }
                        //console.log(data);
                        let config = {
                            method: "POST",
                            url: this.$serverURL + this.$api.return.requestURL,
                            data: data,
                            headers: authHeader()
                        };
                        await this.$axios(config).then(async (response) => {
                            if (response.data.statusCode == 201) {
                                this.filteredRetrunList = []
                                this.selectAll = false;
                                this.returnProductList = [];
                                this.selectedReturnProducts = [];
                                await this.getOrderDetails();

                                this.$swal.fire({
                                    icon: 'success',
                                    text: "Return request successful. Waiting for admin approval."
                                })

                            }
                        }).catch(error => {
                            console.log(error);
                        });

                    }
                })

            }
        }
    }
};