<template>
    <div class="tab_content">
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
        <div class="row gy-5 mb-4">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 dashboard-card-section">
                <div class="dashboard-card">
                  <div class="dashboard-card-top-section">
                    <div class="dashboard-card-top-section-icon-box">
                      <i class="fas fa-luggage-cart dashboard-card-top-section-icon"></i>
                    </div>
                    <div class="dashboard-card-top-section-right-section">
                      <h4 class="dashboard-card-top-section-right-section-count ff-montserrat700">{{ dataList.totalOrders }}</h4>
                    </div>
                  </div>
                  <hr class="dashboard-card-hr">
                  <div class="dashboard-card-footer">
                    <p class="dashboard-card-footer-text ff-montserrat400">
                      Total Order
                    </p>
                  </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 dashboard-card-section">
                <div class="dashboard-card">
                  <div class="dashboard-card-top-section">
                    <div class="dashboard-card-top-section-icon-box">
                      <i class="fas fa-business-time dashboard-card-top-section-icon"></i>
                    </div>
                    <div class="dashboard-card-top-section-right-section">
                      <h4 class="dashboard-card-top-section-right-section-count ff-montserrat700">{{ dataList.totalPendingOrders }}</h4>
                    </div>
                  </div>
                  <hr class="dashboard-card-hr">
                  <div class="dashboard-card-footer">
                    <p class="dashboard-card-footer-text ff-montserrat400">
                      Total Pending Orders
                    </p>
                  </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 dashboard-card-section">
                <div class="dashboard-card">
                  <div class="dashboard-card-top-section">
                    <div class="dashboard-card-top-section-icon-box">
                      <i class="fas fa-people-carry dashboard-card-top-section-icon"></i>
                    </div>
                    <div class="dashboard-card-top-section-right-section">
                      <h4 class="dashboard-card-top-section-right-section-count ff-montserrat700">{{ dataList.totalDeliveredOrders }}</h4>
                    </div>
                  </div>
                  <hr class="dashboard-card-hr">
                  <div class="dashboard-card-footer">
                    <p class="dashboard-card-footer-text ff-montserrat400">
                      Total Delivered Orders
                    </p>
                  </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 dashboard-card-section">
                <div class="dashboard-card">
                  <div class="dashboard-card-top-section">
                    <div class="dashboard-card-top-section-icon-box">
                      <i class="fas fa-shipping-fast dashboard-card-top-section-icon"></i>
                    </div>
                    <div class="dashboard-card-top-section-right-section">
                      <h4 class="dashboard-card-top-section-right-section-count ff-montserrat700">{{ dataList.totalShippedOrders }}</h4>
                    </div>
                  </div>
                  <hr class="dashboard-card-hr">
                  <div class="dashboard-card-footer">
                    <p class="dashboard-card-footer-text ff-montserrat400">
                      Total Shipped Orders
                    </p>
                  </div>
                </div>
            </div>
        </div>
        <table class="table w-100">
            <thead>
                <tr class="table-header">
                    <th class="cell">SL</th>
                    <th class="cell" style="min-width: 93px">ORDER ID</th>
                    <th class="cell">DATE</th>
                    <th class="cell">STATUS</th>
                    <th class="cell" style="min-width: 150px">TOTAL AMOUNT</th>
                    <th class="cell" style="min-width: 122px">ACTION</th>
                </tr>
            </thead>
            <tbody v-if="Object.values(dataList).length > 0">
                <tr v-for="(data, index) in dataList.todayOrderList" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>#{{ data.orderId }}</td>
                    <td>{{ this.$filters.formatDate(data.createdAt) }}</td>
                    <td><span   :class="[
                                    data.deliveryStatus == 'PENDING' ? 'bg-warning py-1 p-2 text-white rounded-pill' :
                                        data.deliveryStatus == 'PROCESSING' ? 'bg-primary py-1 p-2 text-white rounded-pill' :
                                            data.deliveryStatus == 'SHIPPED' ? 'bg-success py-1 p-2 text-white rounded-pill' :
                                                data.deliveryStatus == 'DELIVERED' ? 'bg-success py-1 p-2 text-white rounded-pill' : 'bg-success py-1 p-2 text-white rounded-pill'
                                ]">{{ data.deliveryStatus }}
                        </span>
                    </td>
                    <td>${{ (data.total-0)+(data.shippingMethodCost-0) }}</td>
                    <td><button type="button" @click="$router.push('/order-details/' + data.orderId)" class="btn btn-primary btn-sm border-site-Red bg-site-Red">View Invoice</button></td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="12">
                        <div class="alert alert-primary" role="alert" style="text-align: center">
                            No order found!
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script src="../js/dashboard.js"></script>

<style scoped>
.dashboard-card {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.065);
  border-bottom: none!important;
  border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  overflow: visible;
}
.dashboard-card-top-section {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  opacity: 1;
  background: transparent;
  color: rgb(52, 71, 103);
  box-shadow: none;
}
.dashboard-card-top-section-right-section {
  text-align: right;
  line-height: 1.25;
  opacity: 1;
  background: transparent;
  color: rgb(52, 71, 103);
  box-shadow: none;
}
.dashboard-card-top-section-icon-box {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  margin-top: -24px;
  opacity: 1;
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}
.dashboard-card-top-section-icon {
  user-select: none;
  overflow: hidden;
  display: inline-block;
  text-align: center;
   flex-shrink: 0;
  font-size: 1.5rem;
}
.dashboard-card-top-section-right-section-text {
  margin: 0px;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(123, 128, 154);
  font-weight: 300;
}
.dashboard-card-top-section-right-section-count {
  margin: 0px;
  font-size: 1.9rem;
  line-height: 1.375;
  letter-spacing: 0.00735em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
}
.dashboard-card-hr {
  flex-shrink: 0;
  border-top: 0px solid rgba(0, 0, 0, 0.12);
  border-right: 0px solid rgba(0, 0, 0, 0.12);
  border-left: 0px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  height: 0.0625rem;
  margin: 1rem 0px;
  border-bottom: none;
  opacity: 0.25;
  background-image: linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0)) !important;
}
.dashboard-card-footer {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  opacity: 1;
  background: transparent;
  color: rgb(52, 71, 103);
  box-shadow: none;
}
.dashboard-card-footer-text {
  margin: 0px;
  font-size:1rem;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  display: flex;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(123, 128, 154);
}
.dashboard-card-section:nth-child(1) .dashboard-card .dashboard-card-top-section-icon-box{
  background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
}
.dashboard-card-section:nth-child(2) .dashboard-card .dashboard-card-top-section-icon-box{
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
}
.dashboard-card-section:nth-child(3) .dashboard-card .dashboard-card-top-section-icon-box{
  background: linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));
}
.dashboard-card-section:nth-child(4) .dashboard-card .dashboard-card-top-section-icon-box{
  background: linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96));
}
</style>
