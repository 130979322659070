import Loading from "vue-loading-overlay";
export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            pageCms: {},
            contactAddess: [],
            name: "",
            phone: "",
            email: "",
            website: "",
            message: "",
            method: "",
            uiComponent: {},
            active: false
        }
    },
    async created() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.contactPageTitle ? this.pageTitles?.contactPageTitle : '');
        this.scrollToTop();
        await this.getPageCMS();
    },
    methods: {
        getPageCMS: async function () {
            let config = {
                method: 'GET',
                url: this.$serverURL + this.$api.contact.contactPageCmsURL,
            }
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.pageCms = response.data.data.contactPageCms[0];
                    this.uiComponent = response.data.data.contactComponetCms.length > 0 ? response.data.data.contactComponetCms[0] : {};
                    this.contactAddess = response.data.data.contact;
                    if (this.contactAddess.length == 1) {
                        this.active = true;
                    }
                    else {
                        this.active = false;
                    }
                }
            }).catch(error => {
                console.log(error)
            })
        },

        validate: function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please enter full name!"
                })
                return false;
            } else if (!this.phone) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please enter contact number!"
                })
                return false;
            } else if (this.phone.length <= 9) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Phone number must be atleast 10 digit!"
                })
                return false;
            } else if (!this.email) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please enter email!"
                })
                return false;
            } else if (!this.message) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please write a message!"
                })
                return false;
            }
            return true;
        },
        sendMessage: async function () {
            if (this.validate()) {
                let data = {
                    name: this.name,
                    phone: this.phone,
                    email: this.email,
                    message: this.message,
                    website: this.website,
                    method: this.method ? this.method : ""
                }
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.contact.contactMessageURL,
                    data: data
                }
                this.isLoading = true;
                this.$axios(config).then(response => {
                    this.isLoading = false;
                    if (response.data.statusCode == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: "Your message has been received, we’ll be in touch.",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.name = "";
                        this.phone = "";
                        this.email = "";
                        this.website = "";
                        this.message = "";
                        this.method = "";
                    }
                }).catch(error => {
                    this.isLoading = false
                    console.log(error);
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: error.response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                })
            }
        }
    }
}