<template>
    <div class="tab_content">
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
        <table class="table w-100">
            <thead>
                <tr class="table-header">
                    <th class="cell">SL</th>
                    <th class="cell">ORDER ID</th>
                    <th class="cell">DATE</th>
                    <th class="cell">STATUS</th>
                    <th class="cell" style="min-width: 142px;">TOTAL AMOUNT</th>
                    <th class="cell" style="min-width: 122px">ACTION</th>
                </tr>
            </thead>
            <tbody v-if="dataList.length > 0">
                <tr v-for="(data, index) in dataList" :key="index">
                    <td>{{ index+ 1 }}</td>
                    <td>#{{ data.orderId }}</td>
                    <td>{{ this.$filters.formatDate(data.createdAt) }}</td>
                    <td><span :class="[
                        data.deliveryStatus == 'PENDING' ? 'bg-warning py-1 p-2 text-white rounded-pill' :
                            data.deliveryStatus == 'PROCESSING' ? 'bg-primary py-1 p-2 text-white rounded-pill' :
                                data.deliveryStatus == 'SHIPPED' ? 'bg-success py-1 p-2 text-white rounded-pill' :
                                    data.deliveryStatus == 'DELIVERED' ? 'bg-success py-1 p-2 text-white rounded-pill' : 'bg-success py-1 p-2 text-white rounded-pill'
                    ]">{{ data.deliveryStatus }}
                        </span>
                    </td>
                    <td>${{ ((data.total - 0) + (data.shippingMethodCost - 0) + (data.salesTaxAmount - 0)).toFixed(2) }}</td>
                    <td><button type="button" @click="$router.push('/order-details/' + data.orderId)"
                            class="btn btn-primary btn-sm border-site-Red bg-site-Red">View Invoice</button></td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="12">
                        <div class="alert alert-primary" role="alert" style="text-align: center">
                            No order found!
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="text-center py-5">
        <nav class="page-navigation justify-content-center d-flex" aria-label="page-navigation">
            <v-pagination v-if="dataList.length > 0" v-model="page" @update:modelValue="updateHandler"
                :pages="totalPage" :range-size="1" active-color="#FF8400"></v-pagination>
        </nav>
    </div>
</template>

<script src="../js/order.js"></script>
