import { states } from '../../../../config/states-list'
import { authHeader } from '@/auth';
import { store } from '@/store';
import Loading from "vue-loading-overlay";
export default {
    components: {
        Loading,
    },
    data() {
        return {
            store,
            token: localStorage.getItem('token'),
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            states: states,
            products: [],
            giftCards: [],
            billingAddress: {
                name: "",
                addressOne: "",
                addressTwo: "",
                city: "",
                postCode: "",
                stateCode: "",
                phone: "",
                email: "",
            },
            shippingAddress: {
                name: "",
                addressOne: "",
                addressTwo: "",
                city: "",
                postCode: "",
                stateCode: "",
                phone: "",
                email: "",
            },
            sameAsBilling: false,
            comment: "",
            disableShipping: true,
            showCard: false,
            paymentInfo: {
                name: "",
                cardNumber: "",
                securityCode: "",
                expireDate: "",
            },
            cardType: "",
            cardCreated: false,
            couponList: [],
            couponCode: "",
            couponDiscount: 0,
            smList: [],
            shippingCost: 0,
            shippingMethodName: "",
            shippingMethodID: "",
            stRate: 0,
            stRateTitle: '',
            salesTaxAmount: 0,
            stURL: this.$serverURL + this.$api.order.stURL,
            isFreeShipping: false,
            freeShippingOrderAmount: 10000000000000000000000000,
        }
    },
    async created() {
        if(document.querySelector('.close-cart-side-modal'))
        {
            document.querySelector('.close-cart-side-modal').click();
        }
        
        await this.getPageTitles();
        document.title = this.pageTitles?.checkoutPageTitle ? this.pageTitles?.checkoutPageTitle : '';
        this.scrollToTop();
        this.calculateTotalAmount();
        await this.getSTList();
        await this.getShippingMethod();
        this.store.cartValue.map((el) => {
            if (el.data.type == 'product') {
                let variants = [];
                if (el.data.choosedColor) {
                    variants.push(el.data.choosedColor);
                }
                if (el.data.choosedSize) {
                    variants.push(el.data.choosedSize);
                }
                if (el.data.choosedMaterial) {
                    variants.push(el.data.choosedMaterial);
                }
                this.products.push({ productId: el.data.id, quantity: el.quantity, price: el.data.discountPrice === 0.00 ? el.data.discountPrice : el.data.price, variants: variants, isGift: el.data.isGift ? el.data.isGift : false });
            } else if (el.data.type == 'giftCard') {
                //console.log("Gift card array push");
                this.giftCards.push({ giftCardId: el.data.id, quantity: el.quantity, giftEmail: el.data.giftEmail ? el.data.giftEmail : '', price: el.data.price });
            }
        });
        await this.getCoupons();
        if (this.token) {
            await this.getAddress();
            this.checkSB();
            await this.getCardList();
            this.handleCardType();
        }
        

    },
    methods: {
        async getSTList() {

            let config = {
                method: "GET",
                url: this.stURL,
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    let stList = await response.data.data;
                    this.stRate = stList[0].salesTax;
                    this.stRateTitle = stList[0].name;
                    //console.log('this.stList = ', stList);
                    //console.log('this.stRate = ', this.stRate);

                    this.salesTaxAmount = parseFloat((this.store.totalAmount * this.stRate) / 100).toFixed(2);

                    //                    // this.shippingCost
                    // this.shippingMethodName
                    // this.shippingMethodID

                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        setSM: function () {
            //console.log(' shippingMethodID = ', this.shippingMethodID);
            // shippingCost
            // shippingMethodName
            // shippingMethodID

            if (this.shippingMethodID.length > 0) {
                for (let index = 0; index < this.smList.length; index++) {
                    const element = this.smList[index];
                    if (element.id == this.shippingMethodID) {
                        this.shippingCost = element.price;
                        this.shippingMethodName = element.name;
                    }

                }
            }
            else {
                this.shippingCost = 0;
                this.shippingMethodName = "";
            }

            this.checkSB();
        },
        getShippingMethod: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.order.smURL,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                this.$swal.hideLoading();
                //console.log('DD = ', response.data);
                if (response.data.statusCode == 200 && response.data.data) {
                    let res = await response.data.data;
                    this.smList = res;
                    //console.log('this.smList  = ', this.smList );
                }
            }).catch(error => {
                this.$swal.hideLoading()
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        getAddress: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.getAddress,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                if (response.data.statusCode == 200 && response.data.data) {
                    let res = response.data.data
                    if (res.shippingAddress) {
                        if (Object.values(res.shippingAddress).length > 0) {
                            this.shippingAddress = response.data.data.shippingAddress;
                        }
                    }

                    if (res.billingAddress) {
                        if (Object.values(res.billingAddress).length > 0) {
                            this.billingAddress = response.data.data.billingAddress;
                        }
                    }


                }
            }).catch(error => {
                this.$swal.hideLoading()
                console.log('Error ', error);
                // this.$swal.fire({
                //     icon: "error",
                //     text: error.response.data.message
                // });
            });
        },
        checkSB: async function () {
            this.disableShipping = true;
            if (!this.billingAddress.name) {
                return false;
            } else if (!this.billingAddress.addressOne) {
                return false;
            } else if (!this.billingAddress.city) {
                return false;
            } else if (!this.billingAddress.postCode) {
                return false;
            } else if (!this.billingAddress.stateCode) {
                return false;
            } else if (!this.billingAddress.phone) {
                return false;
            } else if (this.billingAddress.phone && this.billingAddress.phone.length <= 9) {
                this.$swal.fire({
                    icon: 'info',
                    text: 'Billing Contact no must be atleast 10 digits!!'
                });
                return false;
            } else if (!this.billingAddress.email) {
                return false;
            }
            //shipping
            else if (!this.shippingAddress.name) {
                return false;
            } else if (!this.shippingAddress.addressOne) {
                return false;
            } else if (!this.shippingAddress.city) {
                return false;
            } else if (!this.shippingAddress.postCode) {
                return false;
            } else if (!this.shippingAddress.stateCode) {
                return false;
            } else if (this.shippingAddress.phone && this.shippingAddress.phone.length <= 9) {
                this.$swal.fire({
                    icon: 'info',
                    text: 'Shipping Contact no must be atleast 10 digits!!'
                });
                return false;
            } else if (!this.shippingAddress.phone) {
                return false;
            }
            else if (!this.shippingAddress.email) {
                return false;
            }
            else if (!this.shippingMethodID) {
                return false;
            }
            else {
                this.disableShipping = false;
                //this.shippingType = 'fedex';
                // await this.handleShipping();
            }
        },

        setBillingAddressOne(locationData) {

            let location = locationData.formatted_address;
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            //this.billingAddress.addressOne = location;

            this.billingAddress.city = "";
            this.billingAddress.postCode = "";
            this.billingAddress.stateCode = "";

            for (let i = 0; i < addressComponent.length; i++) {

                if (addressComponent[i].types[0] == "street_number") {
                    this.billingAddress.addressOne = addressComponent[i].long_name;
                }

                if (addressComponent[i].types[0] == "route") {

                    this.billingAddress.addressOne += " " + addressComponent[i].long_name;
                }

                if (addressComponent[i].types[0] == "locality") {
                    this.billingAddress.city = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.billingAddress.postCode = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
                    this.billingAddress.stateCode = addressComponent[i].short_name;
                }
            }
        },
        setBillingAddressTwo(locationData) {
            let location = locationData.formatted_address;
            //console.log(locationData)
            let locationSplit = location.split(',');
            //console.log(locationSplit);
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.billingAddress.addressTwo = location;
        },
        setShippingAddressOne(locationData) {
            let location = locationData.formatted_address;
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }

            //this.shippingAddress.addressOne = location;

            this.shippingAddress.city = "";
            this.shippingAddress.postCode = "";
            this.shippingAddress.stateCode = "";

            for (let i = 0; i < addressComponent.length; i++) {

                if (addressComponent[i].types[0] == "street_number") {
                    this.shippingAddress.addressOne = addressComponent[i].long_name;
                }

                if (addressComponent[i].types[0] == "route") {

                    this.shippingAddress.addressOne += " " + addressComponent[i].long_name;
                }

                if (addressComponent[i].types[0] == "locality") {
                    this.shippingAddress.city = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.shippingAddress.postCode = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
                    this.shippingAddress.stateCode = addressComponent[i].short_name;
                }
            }
            // this.checkSB();
        },
        setShippingAddressTwo(locationData) {
            let location = locationData.formatted_address;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.shippingAddress.addressTwo = location;
            //this.checkSB();
        },

        updateShippingAddress: async function () {
            let data = {
                name: this.shippingAddress.name,
                addressOne: this.shippingAddress.addressOne,
                addressTwo: this.shippingAddress.addressTwo,
                email: this.shippingAddress.email,
                phone: this.shippingAddress.phone,
                city: this.shippingAddress.city,
                postCode: this.shippingAddress.postCode,
                stateCode: this.shippingAddress.stateCode,
                country: "United States Of America",
                countryCode: "US",
            }
            let config = {
                method: "POST",
                data: data,
                url: this.$serverURL + this.$api.user.shippingAddress,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200 && response.data.data) {
                    this.shippingAddressData = response.data.data;
                }
            }).catch(error => {
                console.log(error)
            });
        },
        updateBillingAddress: async function () {
            let data = {
                name: this.billingAddress.name,
                addressOne: this.billingAddress.addressOne,
                addressTwo: this.billingAddress.addressTwo,
                email: this.billingAddress.email,
                phone: this.billingAddress.phone,
                city: this.billingAddress.city,
                postCode: this.billingAddress.postCode,
                stateCode: this.billingAddress.stateCode,
                country: "United States Of America",
                countryCode: "US",
            }
            let config = {
                method: "POST",
                data: data,
                url: this.$serverURL + this.$api.user.billingAddress,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                if (response.data.statusCode == 200 && response.data.data) {
                    this.billingAddressData = response.data.data;
                }
            }).catch(error => {
                console.log(error)
            });
        },
        setShippingAddress: async function () {
            if (this.sameAsBilling) {
                this.shippingAddress.name = this.billingAddress.name;
                this.shippingAddress.addressOne = this.billingAddress.addressOne;
                this.shippingAddress.addressTwo = this.billingAddress.addressTwo;
                this.shippingAddress.city = this.billingAddress.city;
                this.shippingAddress.postCode = this.billingAddress.postCode;
                this.shippingAddress.stateCode = this.billingAddress.stateCode;
                this.shippingAddress.phone = this.billingAddress.phone;
                this.shippingAddress.email = this.billingAddress.email;
            } else {
                this.shippingAddress = {
                    name: "",
                    addressOne: "",
                    addressTwo: "",
                    city: "",
                    postCode: "",
                    stateCode: "",
                    phone: "",
                    email: "",
                };
            }
            await this.checkSB()
        },
        getCoupons: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.coupon.couponsURL,
                };
                await this.$axios(config).then(response => {
                    this.couponList = response.data.data;
                    //console.log("Coupon List: ",this.couponList);
                    //isFreeShipping
                    for (let index = 0; index < this.couponList.length; index++) {
                        const cupon = this.couponList[index];
                        if (cupon.couponType == "free shipping") {
                            if (this.store.totalAmount >= parseInt(cupon.discount)) {
                                this.isFreeShipping = true;
                                this.shippingCost = 0;
                                this.shippingMethodName = "Free Shipping";
                                this.shippingMethodID = "FreeShipping";
                            } else {
                                this.isFreeShipping = false
                            }
                        }
                        // if (cupon.couponType == "welcome") {
                        //     if (this.store.isNewUser == true) {
                        //         this.couponCode = cupon.couponCode;
                        //         this.applyCoupon();
                        //     }
                        // }

                    }

                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        },
        applyCoupon: function () {
            if (!this.couponCode) {
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    text: "Please provide a valid coupon code.",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2000,
                    animation: false,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    }
                })
                return;
            }
            let data = this.couponList.find(el => el.couponCode == this.couponCode);
            if (data) {
                this.couponDiscount = 0;
                console.log('el =', data)

                let currentDate = new Date();
                let givenDate = new Date(data.expireDate);
                let isExpire = givenDate < currentDate;
                console.log('isExpire = ', isExpire);

                if(!isExpire)
                {
                    if (data.couponType == 'percent') {
                        this.couponDiscount = parseFloat(parseFloat(this.store.totalAmount) * parseFloat((data.discount) / 100)).toFixed(2);
                    } else if (data.couponType == 'fixed') {
                        this.couponDiscount = parseFloat(data.discount).toFixed(2);
                    } else if (data.couponType == 'welcome') {
                        this.couponDiscount = parseFloat(parseFloat(this.store.totalAmount) * parseFloat((data.discount) / 100)).toFixed(2);
                    }
                    else if (data.couponType == 'onetime') {
                        if (data.cuponUsedTotal >= 1) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: "Invalid coupon!",
                                icon: "error",
                                showConfirmButton: false,
                                timer: 2000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            })
                        }
                        else {
                            this.couponDiscount = parseFloat(data.discount).toFixed(2);
                        }

                    }

                    this.$swal.fire({
                        toast: true,
                        position: 'top-center',
                        text: "coupon added!!",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    })
                }
                else
                {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: "Not a valid coupon!!",
                        icon: "error",
                        showConfirmButton: false,
                        timer: 2000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    })
                }
            } else {
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    text: "Not a valid coupon!!",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2000,
                    animation: false,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    }
                })
            }
        },
        handleExpireDate: function () {
            if (this.paymentInfo.expireDate.length == 2) {
                this.paymentInfo.expireDate += '/';
            }
        },
        formatCCnum: function () {
            if (this.paymentInfo.cardNumber.length == 4) {
                this.paymentInfo.cardNumber += " ";
            }
            if (this.paymentInfo.cardNumber.length == 9) {
                this.paymentInfo.cardNumber += " ";
            }
            if (this.paymentInfo.cardNumber.length == 14) {
                this.paymentInfo.cardNumber += " ";
            }
            if (this.paymentInfo.cardNumber.length == 19) {
                this.paymentInfo.cardNumber += " ";
            }
        },
        paymentValidation: function () {
            if (!this.paymentInfo.name) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card holder name."
                });
                return false;
            } else if (!this.paymentInfo.cardNumber) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card number."
                });
                return false;
            } else if (this.paymentInfo.cardNumber.length <= 14) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Card number must be at least 14 digit"
                });
                return false;
            } else if (!this.paymentInfo.expireDate) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card expire date."
                });
                return false;
            } else if (!this.paymentInfo.securityCode) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card security code."
                });
                return false;
            } else {
                if (!this.paymentInfo.expireDate.includes('/')) {
                    this.$swal.fire({
                        icon: 'info',
                        text: "Expire date format should be 02/22."
                    });
                    return false;
                }
                return true;
            }
        },
        addCard: async function () {
            let data = {
                name: this.paymentInfo.name,
                ccNum: this.paymentInfo.cardNumber.split(' ').join(''),
                cvv: this.paymentInfo.securityCode,
                ccExp: this.paymentInfo.expireDate ? this.paymentInfo.expireDate.replace('/', '') : ''
            }
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.card.cardURL,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 201) {
                    this.cardCreated = true;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        placeOrder: async function () {
            if (this.paymentValidation()) {
                try {
                    this.isLoading = true;
                    if (this.token) {
                        await this.updateBillingAddress();
                        await this.updateShippingAddress();
                    }
                    let data = {
                        shippingAddress: JSON.stringify(this.shippingAddress),
                        billingAddress: JSON.stringify(this.billingAddress),
                        products: this.products,
                        giftCard: this.giftCards,
                        remarks: this.comment,
                        shippingMethodCost: this.shippingCost,
                        shippingMethodName: this.shippingMethodName,
                        shippingMethodID: this.shippingMethodID,
                        salesTaxTitle: this.stRateTitle,
                        salesTaxRate: this.stRate,
                        salesTaxAmount: this.salesTaxAmount,
                        // itemSubTotal: this.store.totalAmount,
                        // itemTotal: this.store.totalAmount,
                        ccNum: this.paymentInfo.cardNumber.split(' ').join(''),
                        ccExp: this.paymentInfo.expireDate ? this.paymentInfo.expireDate.replace('/', '') : '',
                        cvv: this.paymentInfo.securityCode,
                        amount: this.couponDiscount ? parseFloat(((this.store.totalAmount - 0) + (this.shippingCost - 0) + (this.salesTaxAmount - 0)) - this.couponDiscount).toFixed(2) : parseFloat(((this.store.totalAmount - 0) + (this.shippingCost - 0) + (this.salesTaxAmount - 0))).toFixed(2),
                        paymentStatus: 'PAID',
                        paymentMethodName: 'Credit/Debit Card',
                        ...(this.token && { coupon: this.couponCode }),
                    }
                    // console.log("Payload amount: " + data.amount);
                    let config = {
                        method: "POST",
                        url: this.$serverURL + this.$api.order.customerOrderURL,
                        data: data,
                        headers: authHeader()
                    };
                    let guestConfig = {
                        method: "POST",
                        url: this.$serverURL + this.$api.order.guestOrderURL,
                        data: data,
                    }

                    await this.$axios(this.token ? config : guestConfig).then(async (response) => {
                        this.isLoading = false;
                        if (response.data.statusCode == 200) {
                            if (this.token && (this.cardType == 'new' || this.cardType == "")) {
                                await this.addCard();
                            }
                            this.store.cartValue = [];
                            this.store.cartCounter = 0;
                            localStorage.setItem(this.$cartList, JSON.stringify(this.store.cartValue));
                            if (this.store.isNewUser == true) {
                                this.store.isNewUser = false;
                            }
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 2000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            }).then(result => {
                                if (result) {
                                    if (this.cardCreated) {
                                        this.$swal.fire({
                                            toast: true,
                                            position: 'top-end',
                                            text: "Card information saved successfully.",
                                            icon: "success",
                                            showConfirmButton: false,
                                            timer: 2000,
                                            animation: false,
                                            timerProgressBar: true,
                                            didOpen: (toast) => {
                                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                            }
                                        });
                                    }
                                }
                            });
                            this.token ? this.$router.push('/user-dashboard') : this.$router.push('/shop');
                        } else if (response.data.statusCode == 400) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "error",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            }).then(result => {
                                console.log(result);
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: response.data.errors.responsetext,
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                })
                            });
                        }
                    }).catch(error => {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    })
                } catch (error) {
                    this.isLoading = false;
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong, please try agian later."
                    });
                }
            }

        },
        handleCardType: function () {
            if (this.cardType == "") {
                this.cardType = this.store.defaultCard?.name ? "default" : "";
            }
            if (this.cardType == 'new') {
                this.paymentInfo = {
                    name: "",
                    cardNumber: "",
                    securityCode: "",
                    expireDate: "",
                };
            }
            else if (this.cardType == 'default') {
                this.paymentInfo.name = this.store?.defaultCard.name
                this.paymentInfo.cardNumber = this.store?.defaultCard?.number ? this.store?.defaultCard?.number.slice(0, 4) + " " + this.store?.defaultCard?.number.slice(4, 8) + " " + this.store?.defaultCard?.number.slice(8, 12) + " " + this.store?.defaultCard?.number.slice(12) : '';
                this.paymentInfo.securityCode = this.store?.defaultCard?.securityCode
                if (this.store?.defaultCard?.expireDate.length > 2) {
                    this.paymentInfo.expireDate = this.store.defaultCard.expireDate.slice(0, 2) + '/' + this.store.defaultCard.expireDate.slice(2);
                }

            }
        }
    },
}