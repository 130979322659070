<template>
  <div :style="{'background-color': uiComponent.pageBackgroundColor}">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <section
      class="bg-site-gray-700 py-5"
      :style="{'background-color': uiComponent.headerBackgroundColor+'!important' }"
    >
      <div class="container text-center">
        <h2
          class="text-site-black ff-montserrat500 fs-19"
          :style="{'color': uiComponent.pageTitleFontColor+'!important' }"
        >{{merchTitle ? merchTitle : pageTitle}}</h2>
        <h1
          class="text-site-black ff-heading h-fs-55 h-fs-md-45 h-fs-sm-35 h-fs-xs-32"
          :style="{'color': uiComponent.pageSubTitleFontColor+'!important' }"
        >{{pageCms.pageSubTitle}}</h1>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="d-flex flex-column flex-md-row align-items-center gap-3 py-4">
          <h1
            class="mb-0 ff-heading h-fs-35 fs-xs-26"
            :style="{'color': uiComponent.headerTitleFontColor}"
          >{{pageCms.headerTitle}}</h1>
          <p
            class="mb-0 ff-montserrat300 fs-16"
            :style="{'color': uiComponent.headerSubTitleFontColor}"
          >{{pageCms.headerSubTitle}}</p>
        </div>
        <div class="row mb-4">
          <div v-if="!isMerch" class="col-12 col-lg-3">
            <div class="px-2 py-4 py-lg-3 filter_section" :class="isFilterShow ? 'show' : ''">
              <div class="all_categories mb-3">
                <h2 aria-controls="collapseAllCategory" data-bs-toggle="collapse" href="#collapseAllCategory" class="d-flex justify-content-between align-items-center ff-montserrat400 fs-20 dropdown-toggle cp  bg-site-gray p-1 round_5 position-relative">
                  ALL CATEGORIES
                  <a
                    @click="isFilterShow = false"
                    class="btn ff-montserrat900 border-0 position-absolute z-index-3 d-block d-lg-none"
                    style="right: 0;top:-30px"
                  >
                    <i class="bi bi-x-lg"></i>
                  </a>
                </h2>
                <div class="collapse show" id="collapseAllCategory">
                  <ul class="list-group filter_section_list">
                    <li
                        v-for="(data, index) in categoryList"
                        :key="index"
                        class="itemlist-group- border-0 ps-0 py-1"
                        :style="{'background-color': uiComponent.pageBackgroundColor+'!important'}"
                    >
                      <!-- <input class="form-radio-input filter_section_radio_input me-1 shadow-none cp" type="checkbox" :id="'flexCheckDefault'+index" :value="data.id" v-model="categoryId"> -->
                      <input
                          class="form-radio-input filter_section_radio_input me-1 shadow-none cp"
                          type="radio"
                          :value="data.id"
                          v-model="categoryId"
                          :id="'rad'+data.name"
                          @click="radioToogle(data.id)"
                      />
                      <label class="form-check-label cp" :for="'rad'+data.name">{{data.name}}</label>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="all_categories mb-3" v-if="subCategoryList.length>0">
                <h2 aria-controls="collapseSubCategory" data-bs-toggle="collapse" href="#collapseSubCategory" class="d-flex justify-content-between align-items-center ff-montserrat400 fs-20 dropdown-toggle cp  bg-site-gray p-1 round_5 position-relative">
                  SUB CATEGORIES
                  <!--a
                    @click="isFilterShow = false"
                    class="btn ff-montserrat900 border-0 position-absolute z-index-3 d-block d-lg-none"
                    style="right: 0;top:-10px"
                  >
                    <i class="bi bi-x-lg"></i>
                  </a-->
                </h2>
                <div class="collapse show" id="collapseSubCategory">
                  <ul class="list-group filter_section_list">
                    <li
                        v-for="(data, index) in subCategoryList"
                        :key="index"
                        class="list-group-item border-0 ps-0 py-1"
                        :style="{'background-color': uiComponent.pageBackgroundColor+'!important'}"
                    >
                      <input
                          class="form-radio-input filter_section_radio_input me-1 shadow-none cp"
                          type="radio"
                          :value="data.id"
                          v-model="subCategoryId"
                          :id="index+data.name"
                          @click="radioToogle2(data.id)"
                      />
                      <label class="form-check-label cp" :for="index+data.name">{{data.name}}</label>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="all_categories mb-3" v-if="childCategoryList.length>0">
                <h2 aria-controls="collapseChildCategory" data-bs-toggle="collapse" href="#collapseChildCategory" class="d-flex justify-content-between align-items-center ff-montserrat400 fs-20 dropdown-toggle cp  bg-site-gray p-1 round_5 position-relative">
                  CHILD CATEGORIES
                  <!--a
                    @click="isFilterShow = false"
                    class="btn ff-montserrat900 border-0 position-absolute z-index-3 d-block d-lg-none"
                    style="right: 0;top:-10px"
                  >
                    <i class="bi bi-x-lg"></i>
                  </a-->
                </h2>
                <div class="collapse show" id="collapseChildCategory">
                  <ul class="list-group filter_section_list">
                    <li
                        v-for="(data, index) in childCategoryList"
                        :key="index"
                        class="list-group-item border-0 ps-0 py-1"
                        :style="{'background-color': uiComponent.pageBackgroundColor+'!important'}"
                    >
                      <input
                          class="form-radio-input filter_section_radio_input me-1 shadow-none cp"
                          type="radio"
                          :value="data.id"
                          v-model="childCategoryId"
                          @click="radioToogle3(data.id)"
                      />
                      <label class="form-check-label cp" :for="index+data.name">{{data.name}}</label>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="color_section py-3">
                <h2 aria-controls="collapseColor" data-bs-toggle="collapse" href="#collapseColor" class="d-flex justify-content-between align-items-center ff-montserrat400 fs-20 dropdown-toggle cp  bg-site-gray p-1 round_5">COLOR</h2>
                <div class="collapse" id="collapseColor">
                  <div
                      v-for="(data, index) in colorList"
                      :key="index"
                      class="form-check form-check-inline"
                  >
                    <input
                        class="form-check-input shadow-none color_section-check-input cp"
                        :style="{'background-color': '#'+data.colorCode + '!important'}"
                        type="checkbox"
                        :id="data.colorCode"
                        :value="data.name"
                        v-model="colors"
                        @change="updateHandler()"
                    />
                  </div>
                </div>
              </div>

              <div class="size_section py-3">
                <h2 aria-controls="collapseSizes" data-bs-toggle="collapse" href="#collapseSizes" class="d-flex justify-content-between align-items-center ff-montserrat400 fs-20 dropdown-toggle cp  bg-site-gray p-1 round_5">SIZES</h2>
                <div class="collapse" id="collapseSizes">
                  <div class="d-flex gap-3 flex-wrap">
                    <ul class="list-group filter_section_list">
                      <li
                          v-for="(data, index) in sizeList"
                          :key="index"
                          class="list-group-item border-0 ps-0 py-1"
                          :style="{'background-color': uiComponent.pageBackgroundColor+'!important'}"
                      >
                        <input
                            class="form-check-input filter_section_check_input me-1 shadow-none cp"
                            type="checkbox"
                            :value="data.name"
                            :id="(index+data.name)"
                            v-model="sizes"
                            @change="updateHandler()"
                        />
                        <label class="form-check-label cp" :for="(index+data.name)">{{data.name}}</label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="size_section py-3">
                <h2 aria-controls="collapseTags" data-bs-toggle="collapse" href="#collapseTags" class="d-flex justify-content-between align-items-center ff-montserrat400 fs-20 dropdown-toggle cp  bg-site-gray p-1 round_5 text-uppercase">Tags</h2>
                <div class="collapse" id="collapseTags">
                  <div class="d-flex gap-3 flex-wrap">
                  <span v-for="(data, index) in tagList" :key="index">
                    <input
                        type="checkbox"
                        class="btn-check btn_check"
                        :id="index+data.name"
                        :value="data.name"
                        v-model="tags"
                        @change="updateHandler()"
                    />
                    <label
                        class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16"
                        :for="index+data.name"
                    >{{data.name}}</label>
                  </span>
                  </div>
                </div>
              </div>

              <div class="highlight_section py-3">
                <h2 aria-controls="collapseHighlight" data-bs-toggle="collapse" href="#collapseHighlight" class="d-flex justify-content-between align-items-center ff-montserrat400 fs-20 dropdown-toggle cp  bg-site-gray p-1 round_5 text-uppercase">HIGHLIGHT</h2>
                <div class="collapse" id="collapseHighlight">
                  <ul class="list-group filter_section_list">
                    <li
                        v-for="(data, index) in highlightList"
                        :key="index"
                        class="list-group-item border-0 ps-0 py-1"
                        :style="{'background-color': uiComponent.pageBackgroundColor+'!important'}"
                    >
                      <input
                          class="form-check-input filter_section_check_input me-1 shadow-none cp"
                          type="checkbox"
                          :value="data.name"
                          :id="data.id"
                          v-model="highlights"
                          @change="updateHandler()"
                      />
                      <label class="form-check-label cp" :for="data.id">{{data.name}}</label>
                    </li>
                    <li>
                      <hr />
                    </li>
                    <li
                        class="list-group-item border-0 ps-0 py-1"
                        :style="{'background-color': uiComponent.pageBackgroundColor+'!important'}"
                    >
                      <input
                          class="form-check-input filter_section_check_input me-1 shadow-none cp"
                          type="checkbox"
                          :value="'1'"
                          :id="'readytoship'"
                          v-model="filterReadyToShip"
                          @change="updateHandler()"
                      />
                      <label
                          class="form-check-label ff-montserrat500 fs-16"
                          :for="'readyToshipfor'"
                      >Ready To Ship</label>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="price_section py-3">
                <h2 aria-controls="collapsePrice" data-bs-toggle="collapse" href="#collapsePrice" class="d-flex justify-content-between align-items-center ff-montserrat400 fs-20 dropdown-toggle cp  bg-site-gray p-1 round_5 text-uppercase">PRICE</h2>
                <div class="collapse" id="collapsePrice">
                <MultiRangeSlider
                  baseClassName="multi-range-slider-bar-only"
                  :minValue="minPrice"
                  :maxValue="maxPrice"
                  :max="5000"
                  :min="0"
                  :step="5"
                  :rangeMargin="0"
                  @input="UpdateValues"
                  @click="updateHandler()"
                />
                <div class="row">
                  <div class="col-6">
                    <label for>Min Price:</label>
                    <input
                      style="height: 10px"
                      type="number"
                      v-model="minPrice"
                      :min="0"
                      :max="5000"
                      @keydown="checkZero($e)"
                      class="form-control form-control-sm"
                      @change="updateHandler()"
                    />
                  </div>
                  <div class="col-6">
                    <label for>Max Price:</label>
                    <input
                      style="height: 10px"
                      type="number"
                      v-model="maxPrice"
                      min="0"
                      max="5000"
                      class="form-control form-control-sm"
                      @change="updateHandler()"
                    />
                  </div>
                </div>
                <!-- <p class="d-flex align-items-center justify-content-between gap-1">
                  <span class="ff-montserrat300 fs-16">Min. Price: ${{minPrice}}</span>
                  <span class="ff-montserrat300 fs-16">Max. Price: ${{maxPrice}}</span>
                </p>-->
                </div>
              </div>

              <div class="material-section py-3">
                <h2 aria-controls="collapseMaterial" data-bs-toggle="collapse" href="#collapseMaterial" class="d-flex justify-content-between align-items-center ff-montserrat400 fs-20 dropdown-toggle cp  bg-site-gray p-1 round_5 text-uppercase">Material</h2>
                <!-- <div class="mb-3">
                  <input type="search" class="form-control" placeholder="Search" />
                </div>-->
                <div class="collapse" id="collapseMaterial">
                  <ul class="list-group filter_section_list">
                    <li
                        v-for="(data, index) in materialList"
                        :key="index"
                        class="list-group-item border-0 ps-0 py-1 d-flex justify-content-between align-items-center"
                        :style="{'background-color': uiComponent.pageBackgroundColor+'!important'}"
                    >
                      <div>
                        <input
                            class="form-check-input filter_section_check_input me-1 shadow-none cp"
                            type="checkbox"
                            name="material"
                            :value="data.name"
                            :id="data.id"
                            v-model="materials"
                            @change="updateHandler()"
                        />
                        <label class="form-check-label cp" :for="data.id">{{data.name}}</label>
                      </div>
                    </li>
                  </ul>
                </div>

              </div>

              <div class="text-left py-2 mb-3">
                <button
                  class="btn bg-transparent border-site-black fs-15 ff-montserrat600 text-uppercase"
                  @click="clearFilter()"
                >Clear Filter</button>
              </div>
              <div
                class="discount-section px-3 pt-5 pb-4"
                :style="{'background-image': `url(${pageCms.discountImage})`, 'background-repeat': 'no-repeat', 'min-height': '300px'}"
              >
                <!-- <img :src="pageCms.discountImage" height="100%" width="100%" alt=""> -->
                <!-- <h1 class="ff-montserrat900 mb-0 lh-1 text-site-gold h-fs-69 text-uppercase">50%</h1>
                <h1 class="ff-montserrat900 mb-0 lh-1 text-site-gold h-fs-69 text-uppercase">Extra</h1>
                <h1 class="ff-montserrat700 mb-0 lh-1 text-site-gray-800 fs-26 text-uppercase">Discount</h1>
                <h2 class="ff-montserrat600 mb-0 lh-1 text-site-gray-800 fs-20 text-uppercase">20-28 December</h2>-->
              </div>
            </div>
          </div>
          <div :class="['col-12', isMerch ? 'col-lg-12' : 'col-lg-9']">
            <div
              class="d-flex flex-wrap flex-sm-nowrap justify-content-end gap-2 align-items-center mb-4"
            >
              <p class="mb-0 text-site-silver d-none d-md-block">{{ parseInt(totalData) }} products</p>
              <button
                class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16 d-block d-lg-none"
                @click="isFilterShow =! isFilterShow"
              >
                <i class="bi bi-sliders2"></i> Filters
              </button>
              <select
                class="form-select w-auto border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16 shadow-none"
                aria-label="Default select example"
                v-model="sortBy"
                @change="updateHandler()"
              >
                <option style="display:none" selected value>Sort by:</option>
                <option value="new">New</option>
                <option value="low">Low to High (Price)</option>
                <option value="high">High to Low (Price)</option>
              </select>
              <input
                type="radio"
                class="btn-check btn_check"
                name="grid"
                id="grid1"
                v-model="grid"
                value="true"
              />
              <label
                class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16"
                for="grid1"
              >
                <i class="bi bi-grid-3x3-gap"></i>
              </label>

              <input
                type="radio"
                class="btn-check btn_check"
                name="grid"
                id="grid2"
                v-model="grid"
                value="false"
              />
              <label
                class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16"
                for="grid2"
              >
                <i class="bi bi-list-task"></i>
              </label>
            </div>
            <div v-if="productList.length>0">
              <div
                class="product-layout-section"
                :class="[grid === 'true' ? 'd_grid': 'd_list',isMerch? 'd_grid4' :'']"
                
              >
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  v-for="(data, index) in productList"
                  class="card w-100"
                  :key="index"
                  :class="grid === 'true' ? 'border-0': 'flex-sm-row gap-4 border-1'"
                >
                  <template v-if="(data.stock > 0)">
                    <div
                      v-if="data.isReadyToShip"
                      :style="{ 'border-bottom': '20px solid ' + uiComponent.badgeColor }"
                      class="ribbon ribbon--css ribbon--triangle"
                    >Ready To Ship</div>
                  </template>
                  <template v-else>
                    <div
                      :style="{ 'border-bottom': '20px solid #c1b7b8' }"
                      class="ribbon ribbon--css ribbon--triangle"
                    >Out of Stock</div>
                  </template>

                  <div
                    class="hover"
                    :class="grid === 'true' ? 'mb-2': 'mb-0 w_25 w-lg-30 w-md-40 w-xs-100'"
                  >
                    <img
                      class="product-layout-section-image mb-0"
                      :class="grid === 'true' ? '': 'h-100'"
                      :src="data.featuredImage" :alt="data.name"
                    />
                    <div
                      @click="$router.push('/product-details/'+data.urlSlug)"
                      class="hover-show d-flex justify-content-center align-items-center rounded-1"
                      :style="[{'background-color': store.hoverColor, 'cursor': 'pointer'}]"
                    >
                      <div class="w-100 d-flex p-2 gap-3 justify-content-center align-items-center">
                        <a
                          class="btn btn-outline-secondary border-white text-white text-uppercase ff-montserrat700"
                          @click.stop.prevent="$router.push('/product-details/'+data.urlSlug)"
                        >
                          <i class="bi bi-eye"></i>
                        </a>
                        <a
                          class="btn btn-outline-secondary border-white text-white text-uppercase ff-montserrat700"
                          @click.stop.prevent="addToWishList(data, 1)"
                        >
                          <i class="bi bi-heart"></i>
                        </a>
                        <!-- <template v-if="(data.stock > 0)">
                          <a
                            v-if="data.itemDetail.productColorVariants.length > 1 || data.itemDetail.productMaterialVariants.length > 1 || data.itemDetail.productSizeVariants.length > 1"
                            class="btn btn-outline-secondary border-white text-white text-uppercase ff-montserrat700"
                            data-bs-toggle="modal"
                            data-bs-target="#variantChooseModal"
                            @click.stop.prevent="handleVariants(data)"
                          >
                            <i class="bi bi-bag"></i>
                          </a>
                          <button
                            v-else
                            class="btn btn-outline-secondary border-white text-white text-uppercase ff-montserrat700"
                            data-bs-toggle="offcanvas" 
                            data-bs-target="#offcanvasRight" 
                            aria-controls="offcanvasRight"
                            @click.stop.prevent="handleVariants(data)"
                          >
                            <i class="bi bi-bag"></i>
                        </button>
                        </template> -->
                      </div>
                    </div>
                  </div>
                  <div
                    :class="grid === 'true' ? 'w-100': 'w_75 w-lg-70 w-md-60 w-xs-100 p-2 p-sm-0'"
                  >
                    <p
                      class="mb-0 ff-montserrat500"
                      :class="grid === 'true' ? 'fs-xs-14': 'fs-27 fs-md-25 fs-sm-20'"
                      @click="$router.push('/product-details/'+data.urlSlug)"
                      style="cursor: pointer;"
                    >{{data.name}}</p>
                    <div v-if="data.colorCode" class="d-flex">
                      <span
                        class="d-block me-2 lh-1"
                        :style="[{width:'15px',height:'15px', 'background-color': '#' + row}]"
                        v-for="(row, index) in parseColorCode(data.colorCode)"
                        :key="index"
                      ></span>
                    </div>

                    <p
                      class="mb-0"
                      :class="grid === 'true' ? 'fs-xs-14': 'fs-20 fs-md-17 fs-sm-16'"
                    >
                      ${{ data.discountPrice>0 ? data.discountPrice : data.price }} &nbsp;
                      <s
                        v-if="data.discountPrice > 0"
                      >${{ data.price }}</s>
                    </p>
                    <p
                      v-html="data.shortDescription"
                      v-if="grid === 'false'"
                      class="fs-20 fs-md-16 fs-sm-14"
                    ></p>
                  </div>
                </div>
              </div>
              <div class="text-center py-5" v-if="productList.length>0">
                <!-- <nav
                  class="page-navigation justify-content-center d-flex"
                  aria-label="page-navigation"
                >
                  <button
                    class="btn align-self-stretch btn-outline-dark round_5 me-4"
                    @click="previous"
                    :disabled="page == 1"
                  >
                    <i class="fa-solid fa-chevron-left"></i> Previous
                  </button>
                  <button
                    class="btn align-self-stretch btn-outline-dark round_5"
                    @click="next"
                    :disabled="page == totalPage"
                  >
                    Next
                    <i class="fa-solid fa-chevron-right"></i>
                  </button>
                </nav> -->
                <div v-if="totalData >= limit" v-observe-visibility="handleScrolledToBottom" class="loading-more">
                <p>Loading more...</p>
              </div>
              </div>
            </div>
            <div v-else>
              <div
                class="alert alert-info"
                style="margin:0 auto; text-align: center;"
              >No products found!</div>
            </div>
            <!-- modal -->
            <VariantsModal :singleProduct="singleProduct" :singleVariants="singleVariants" />
            <!-- <div
              class="modal fade"
              id="variantChooseModal"
              ref="variantsModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1
                      class="modal-title fs-5"
                      id="variantChooseModal"
                    >Choose Variants For: {{singleProduct.name}}</h1>
                    <button
                      type="button"
                      class="btn-close"
                      id="closeBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-sm-6">
                        <img
                          class="product-layout-section-image mb-0"
                          :src="singleProduct.featuredImage"
                        />
                      </div>
                      <div class="col-sm-6">
                        <div v-if="singleProduct.productColorVariants.length > 0">
                          <h3 class="ff-montserrat500 fs-16 text-site-black">Color:</h3>
                          <div class="color_section py-2 mb-2">
                            <div
                              v-for="(data, index) in singleProduct.productColorVariants"
                              :key="index"
                              class="form-check form-check-inline"
                            >
                              <input
                                class="form-check-input shadow-none color_section-check-input cp"
                                :style="{'background-color': data.colorCode + '!important'}"
                                type="radio"
                                :id="data.colorCode"
                                :value="data.colorName"
                                v-model="singleVariants.color"
                              />
                            </div>
                          </div>
                        </div>
                        <div v-if="singleProduct.productSizeVariants.length > 0">
                          <h3 class="ff-montserrat500 fs-16 text-site-black">Size:</h3>
                          <div class="d-flex gap-3 flex-wrap mb-4">
                            <span
                              v-for="(data, index) in singleProduct.productSizeVariants"
                              :key="index"
                            >
                              <input
                                type="radio"
                                class="btn-check btn_check"
                                :id="'size' + index"
                                :value="data.sizeName"
                                v-model="singleVariants.size"
                              />
                              <label
                                class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16"
                                :for="'size' + index"
                              >{{ data.sizeName }}</label>
                            </span>
                          </div>
                        </div>
                        <div v-if="singleProduct.productMaterialVariants.length > 0">
                          <h3 class="ff-montserrat500 fs-16 text-site-black">Material:</h3>
                          <div class="d-flex gap-3 flex-wrap mb-4">
                            <span
                              v-for="(data, index) in singleProduct.productMaterialVariants"
                              :key="index"
                            >
                              <input
                                type="radio"
                                class="btn-check btn_check"
                                :id="index"
                                :value="data.materialName"
                                v-model="singleVariants.material"
                              />
                              <label
                                class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16"
                                :for="index"
                              >{{ data.materialName }}</label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <a
                      class="btn bg-site-Red border-site-Red ff-montserrat600 fs-14 text-white text-uppercase"
                      @click="processCartData()"
                    >Add to Cart</a>
                  </div>
                </div>
              </div>
            </div>-->

            <!-- modal -->
          </div>
        </div>
      </div>
    </section>
    <ShopSchemaJson :metaKeyword="metaKeyword" :metaDescription="metaDescription"></ShopSchemaJson>
  </div>
</template>

<script src="../js/occasion.js">
</script>

<style scoped>
@import "../css/shop.css";
.loading-more p{
    text-align: center;
    margin: 20px 0px;
}
.uppercase{ 
  text-transform: uppercase;
}
</style>
