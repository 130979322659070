import Loading from "vue-loading-overlay";
import RingSizeTab from "../template/RingSizeTab";
import BraceletsSizeTab from "../template/BraceletsSizeTab";
import NecklaceSizeTab from "../template/NecklaceSizeTab";

export default {
    name: "Learn",
    components: {
        Loading,
        RingSizeTab,
        BraceletsSizeTab,
        NecklaceSizeTab
    },
    async created() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.learnPageTitle ? this.pageTitles?.learnPageTitle : '');
        await this.getLearnPageData();
        await this.getLearnTabData();
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            alphabet: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
            isShow: false,
            pageCMS: {},
            glossary: [],
            currentLetter: '',
            uiComponent: {},
            cmsData: {},
            dataList: [],
            tabWide: 'w-30',
        }
    },
    methods: {
        getLearnPageData: async function () {
            try {
                this.isLoading = true;
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.learn.requestURL
                };
                await this.$axios(config).then(async (response) => {
                    this.isLoading = false;
                    if (response.data.statusCode == 200) {
                        this.pageCMS = response.data.data.pageCMS.length > 0 ? response.data.data.pageCMS[0] : {};
                        this.uiComponent = response.data.data.learnComponentCms.length > 0 ? response.data.data.learnComponentCms[0] : {};
                    }
                }).catch(error => {
                    this.isLoading = false;
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong! Please try again."
                });
            }
        },
        getLearnTabData: async function () {
            try {
                this.isLoading = true;
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.learn.tabURL
                };
                await this.$axios(config).then(async (response) => {
                    this.isLoading = false;
                    if (response.data.statusCode == 200) {
                        this.dataList = response.data.data;
                        switch (response.data.data.length) {
                            case 3:
                                this.tabWide = 'w-30';
                                break;
                            case 4:
                                this.tabWide = 'w-25';
                                break;
                            case 5:
                                this.tabWide = 'w-20';
                                break;
                            case 6:
                                this.tabWide = 'w-15';
                                break;
                            case 7:
                                this.tabWide = 'w-10';
                                break;
                            case 8:
                                this.tabWide = 'w-10';
                                break;
                            case 9:
                                this.tabWide = 'w-10';
                                break;
                            case 10:
                                this.tabWide = 'w-10';
                                break;
                            default:
                                this.tabWide = 'w-30';
                        }
                    }
                }).catch(error => {
                    this.isLoading = false;
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong! Please try again."
                });
            }
        },
        getGlossaryData: async function (letter) {
            try {
                this.isLoading = true;
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.glossary.requestURL + '?alphabet=' + letter
                };
                await this.$axios(config).then(async (response) => {
                    this.isLoading = false;
                    if (response.data.statusCode == 200) {
                        this.glossary = response.data.data.glossary;
                        this.currentLetter = letter;
                    }
                }).catch(error => {
                    this.isLoading = false;
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong! Please try again."
                });
            }
        }
    }
}
