export default {
    props: ["singleProduct", "singleVariants"],
    data() {
        return {
            // singleVariants: {
            //     color: '',
            //     size: '',
            //     material: '',
            // },
            varientModalData: [],
        }
    },
    async created(){
        await this.getVarientModalCmsData();

        // this.singleVariants.color = this.singleProduct.productColorVariants.length > 0 ? this.singleProduct.productColorVariants[0].colorName:'';
    },
    mounted() {

        // this.singleVariants.color = this.singleProduct.productColorVariants.length > 0 ? this.singleProduct.productColorVariants[0].colorName:'';
            
        // this.singleVariants = {
        //     color: this.singleProduct.productColorVariants.length > 0 ? this.singleProduct.productColorVariants[0].colorName:'',
        //     size: this.singleProduct.productSizeVariants.length > 0 ? this.singleProduct.productSizeVariants[0].sizeName:'',
        //     material: this.singleProduct.productMaterialVariants.length > 0 ? this.singleProduct.productMaterialVariants[0].materialName:'',
        // };

    },
    methods: {
        processCartData: function () {
            if (this.singleProduct.productColorVariants.length > 0 && !this.singleVariants.color) {
                this.$swal.fire({
                    text: "Please select product color.",
                    icon: 'info'
                })
                return;
            } else if (this.singleProduct.productSizeVariants.length > 0 && !this.singleVariants.size) {
                this.$swal.fire({
                    text: "Please select product size.",
                    icon: 'info'
                })
                return;
            } else if (this.singleProduct.productMaterialVariants.length > 0 && !this.singleVariants.material) {
                this.$swal.fire({
                    text: "Please select product material.",
                    icon: 'info'
                })
                return;
            }
            this.singleProduct.choosedColor = this.singleVariants.color ? this.singleVariants.color : 'N/A';
            this.singleProduct.choosedSize = this.singleVariants.size ? this.singleVariants.size : 'N/A';
            this.singleProduct.choosedMaterial = this.singleVariants.material ? this.singleVariants.material : 'N/A';
            this.singleProduct.type = 'product';
            if (this.$route.path == "/wish-list") {
                this.moveFromWishListToCart({
                    data: this.singleProduct,
                    quantity: this.singleProduct.quantity
                }, this.singleProduct.index);
            } else {
                this.addToCart(this.singleProduct, 1);
                // this.$router.push('/cart');
            }
            // this.singleVariants = {
            //     color: '',
            //     size: '',
            //     material: '',
            // };
            document.getElementById('closeBtn').click();
        },
        getVarientModalCmsData: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.varientModalCms.varientModalCmsURL,
            };
            await this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.varientModalData = response.data.data[0];
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            })
        },
    },
}