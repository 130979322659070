import Loading from "vue-loading-overlay";
import { googleSdkLoaded } from "vue3-google-login";
import { store } from "@/store";
import LoginSchemaJson from "../../seo/LoginSchemaJson";

export default {
    name: "Login",
    components: {
        Loading,
        LoginSchemaJson
    },
    data() {
        return {
            store,
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            email: null,
            password: null,
            showPassword: false,
            metaKeyword: "",
            metaDescription: ""
        }
    },
    async created() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.loginPageTitle ? this.pageTitles?.loginPageTitle : '');
        this.metaKeyword = this.pageTitles.loginPageMetaKeyword;
        this.metaDescription = this.pageTitles.loginPageMetaDescription;
    },
    methods: {
        toggleShow: function () {
            this.showPassword = !this.showPassword;
        },
        login: function () {
            this.isLoading = true;
            if (!this.email) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter email!"
                });
            }
            else if (!this.password) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter password!"
                });
            }
            else {
                let data = {
                    email: this.email,
                    password: this.password
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.auth.loginURL,
                    data: data
                };
                this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        this.isLoading = false;
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("id", response.data.user.id);
                        localStorage.setItem("name", response.data.user.fullName);
                        localStorage.setItem("email", response.data.user.email);
                        localStorage.setItem("role", response.data.user.role);
                        this.store.token = response.data.token
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: "Successfully logged in.",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.$router.push("/user-dashboard");
                    }
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                })
            }
        },
        processGoogleLogin: async function (data) {
            let config = {
                method: "POST",
                data: data,
                url: this.$serverURL + this.$api.auth.googleLogIn,
            };

            await this.$axios(config).then((response) => {
                if (response.status == 201) {
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("id", response.data.user.id);
                    localStorage.setItem("name", response.data.user.fullName);
                    localStorage.setItem("email", response.data.user.email);
                    localStorage.setItem("role", response.data.user.role);
                    this.store.token = response.data.token
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfuly logged in!!",
                    });
                    this.$router.push('/')
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Google Login Failed!"
                    });
                }
            })
                .catch((error) => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
        },
        loginGoogle: async function () {
            await googleSdkLoaded(async (google) => {
                await google.accounts.oauth2.initTokenClient({
                    client_id: this.$googleSiteClientID,
                    scope: 'email profile openid',
                    callback: async (response) => {
                        //console.log("Handle the response", response.access_token)
                        //this.processGoogleLogin(response.code, google)

                        let config = {
                            method: "GET",
                            url: this.$api.auth.googleSDKHandler,
                            headers: {
                                'Authorization': 'Bearer ' + response.access_token
                            }
                        };

                        await this.$axios(config).then(async (respon) => {

                            let data = {
                                token: response.access_token,
                                email: respon.data.email,
                                name: respon.data.name
                            };

                            await this.processGoogleLogin(data);
                        });

                    }
                }).requestAccessToken()
            })
        },
    }
}
