import Loading from "vue-loading-overlay";

export default {
    name: "Style",
    components: {
        Loading,
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            
        }
    },
    async created() {
        //await this.getPageTitles();
        document.title = 'Coming Soon';
        this.scrollToTop();
    },
    methods: {

    }
}
