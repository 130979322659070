import Loading from "vue-loading-overlay";
import {store} from "@/store";
export default {
    name: "ShoppingCart",
    components: {
        Loading,
    },
    data() {
        return {
            store,
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            quantity: 1,
            token: localStorage.getItem('token'),
        }
    },
    async created() {
        await this.getPageTitles();
        document.title = this.pageTitles?.cartPageTitle ? this.pageTitles?.cartPageTitle : '';
        this.scrollToTop();
        this.calculateTotalAmount();
    },
    methods: {

    }
}