import Loading from "vue-loading-overlay";
import VariantsModal from "@/components/variantsModal/template/VariantsModal";
import {
    store
} from "@/store";
export default {
    name: "ShoppingCart",
    components: {
        Loading,
        VariantsModal
    },
    data() {
        return {
            store,
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            quantity: 1,
            singleProduct: {
                productColorVariants: [],
                productSizeVariants: [],
                productMaterialVariants: [],
            },
            singleVariants: {}
        }
    },
    async created() {
        await this.getPageTitles();
        document.title = this.pageTitles?.wishlistPageTitle ? this.pageTitles?.wishlistPageTitle : '';
        this.scrollToTop();
    },
    methods: {
        handleVariants: async function (data, index, quantity) {
            this.singleProduct = data.itemDetail;
            if(this.singleProduct.productColorVariants.length <= 1 && this.singleProduct.productSizeVariants.length <= 1 && this.singleProduct.productMaterialVariants.length <=1)
            {


                if(this.singleProduct.productColorVariants.length > 0) { 
                    this.singleProduct.choosedColor = this.singleProduct.productColorVariants[0].colorName;
                    
                 }
                else { this.singleProduct.choosedColor = 'N/A'; }

                if(this.singleProduct.productSizeVariants.length > 0) { this.singleProduct.choosedSize = this.singleProduct.productSizeVariants[0].sizeName;  }
                else { this.singleProduct.choosedSize = 'N/A'; }

                if(this.singleProduct.productMaterialVariants.length > 0) { this.singleProduct.choosedMaterial = this.singleProduct.productMaterialVariants[0].materialName;  }
                else { this.singleProduct.choosedMaterial = 'N/A'; }

                this.singleProduct.type = 'product'; 
                this.singleProduct.index = index;
                this.singleProduct.quantity = quantity;
                this.addToCart(this.singleProduct, quantity);
                this.moveFromWishListToCart({
                    data: this.singleProduct,
                    quantity: this.singleProduct.quantity
                }, this.singleProduct.index);
                return false;
            }
            else if (Object.values(this.singleProduct).length > 0 && (this.singleProduct.productColorVariants.length > 0 || this.singleProduct.productSizeVariants.length > 0 || this.singleProduct.productMaterialVariants.length > 0)) {
                this.showModal = true;
                this.singleProduct.index = index;
                this.singleProduct.quantity = quantity;
                this.singleVariants.color = '';
                if(this.singleProduct.productColorVariants.length > 0)
                {
                    this.singleVariants.color = this.singleProduct.productColorVariants[0].colorName;
                }

                this.singleVariants.size = '';
                if(this.singleProduct.productSizeVariants.length > 0)
                {
                    this.singleVariants.size = this.singleProduct.productSizeVariants[0].sizeName;
                }

                this.singleVariants.material = '';
                if(this.singleProduct.productMaterialVariants.length > 0)
                {
                    this.singleVariants.material = this.singleProduct.productMaterialVariants[0].materialName;
                }

            }
        },
    }
}