<template>
  <div class="dashboard">
      <h1 class="ff-heading h-fs-122 h-fs-md-98  fs-xs-27  text-center py-10">My Profile</h1>
      <div class="container">
        <div class="row ">
          <div class="col-12">
            <TabNav/>
            
            <div class="tab-content mt-5" id="tabContent">
              <div class="tab-pane fade show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                <Dashboard/>
              </div>
              <div class="tab-pane fade" id="orders" role="tabpanel" aria-labelledby="orders-tab">
                <Order/>
              </div>
              <div class="tab-pane fade" id="returnProductsTab" role="tabpanel" aria-labelledby="return-product-tab">
                <ReturnProducts/>
              </div>
              <div class="tab-pane fade" id="address" role="tabpanel" aria-labelledby="address-tab">
                <Address/>
              </div>
              <div class="tab-pane fade" id="account" role="tabpanel" aria-labelledby="account-tab">
                <Account/>
              </div>
              <div class="tab-pane fade" id="cardSettings" role="tabpanel" aria-labelledby="card-tab">
                <CardSettings/>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
</template>
<script src="../js/main.js"></script>

<style scoped>

</style>