import Loading from "vue-loading-overlay";
export default {
    name: "Gallery",
    components: {
        Loading,
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            galleryData: [],
            cms: {},
            uiComponent: {}
        }
    },
    async created(){
        await this.getPageTitles();
        document.title = (this.pageTitles?.galleryPageTitle ? this.pageTitles?.galleryPageTitle : '');
        this.scrollToTop();
        await this.getData();
    },
    methods: {
        getData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.gallery.galleryURL
            }
            this.isLoading = true
            await this.$axios(config).then(response => {
                this.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.galleryData = response.data.data.gallery;
                    this.cms = response.data.data.galleryPageCMS[0];
                    this.uiComponent = response.data.data.galleryComponentCMS.length > 0 ? response.data.data.galleryComponentCMS[0] : {};
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            })
        }
    }
}