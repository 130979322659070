<template>
  <div>
    <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
    <section class="bg-site-black py-5">
      <div class="container text-center">
        <h2 class="text-site-silver ff-montserrat500 fs-19">Gift Guide</h2>
        <h1 class="text-site-silver ff-heading h-fs-122 h-fs-md-98 h-fs-sm-69">Gift Guide Necklaces</h1>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="d-flex flex-column flex-md-row align-items-center gap-3 mb-4">
          <h1 class="mb-0 ff-heading h-fs-69">Products</h1>
          <p class="mb-0 ff-montserrat300 fs-16">
            Don’t know what to gift mom? Shower her with unconditional love by choosing the perfect piece to suit her discerning taste. Explore our thoughtful curation of the best mother necklaces and initial jewelry for any style and occasion.
          </p>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-lg-3">
            <div class="px-2 py-3 filter_section" :class="isFilterShow ? 'show' : ''">
              <div class="all_categories">
                <h2 class="ff-montserrat400 fs-20 position-relative">
                  ALL CATEGORIES
                  <a @click="isFilterShow = false" class="btn ff-montserrat900 border-0 position-absolute z-index-3 d-block d-lg-none" style="right: 0;top:-10px"><i class="bi bi-x-lg"></i></a>
                </h2>
                <ul class="list-group filter_section_list">
                  <li v-for="item in 5" :key="item" class="list-group-item border-0 ps-0 py-1">
                    <input class="form-check-input filter_section_check_input me-1 shadow-none cp" type="checkbox" :value="item" :id="item">
                    <label class="form-check-label cp" :for="item">Earrings </label>
                  </li>
                </ul>
              </div>
              <div class="color_section py-2">
                <h2 class="ff-montserrat500 fs-16">COLOR</h2>
                <div v-for="color in 10" :key="color" class="form-check form-check-inline">
                  <input class="form-check-input shadow-none color_section-check-input cp" type="checkbox" name="color" :id="'color'+color" :value="'color'+color">
                </div>
              </div>
              <div class="size_section py-3">
                <h2 class="ff-montserrat500 fs-16">SIZES</h2>
                <div class="d-flex gap-3 flex-wrap">
                  <span v-for="items in 10" :key="items">
                    <input type="checkbox" class="btn-check btn_check" name="size" :id="'size'+items" value="false">
                    <label class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16" :for="'size'+items">XS</label>
                  </span>
                </div>
              </div>
              <div class="highlight_section">
                <h2 class="ff-montserrat500 fs-16">HIGHLIGHT</h2>
                <ul class="list-group filter_section_list">
                  <li v-for="highlight in 5" :key="highlight" class="list-group-item border-0 ps-0 py-1">
                    <input class="form-check-input filter_section_check_input me-1 shadow-none cp" type="checkbox" :value="highlight" :id="highlight">
                    <label class="form-check-label cp" :for="highlight">Promotions</label>
                  </li>
                </ul>
              </div>
              <div class="price_section py-3">
                <h2 class="ff-montserrat500 fs-16">PRICE</h2>
                <MultiRangeSlider
                    baseClassName="multi-range-slider-bar-only"
                    :minValue="minPrice"
                    :maxValue="maxPrice"
                    :max="2000"
                    :min="10"
                    :step="5"
                    :rangeMargin="0"
                    @input="UpdateValues"
                />
                <p class="d-flex align-items-center justify-content-between gap-1">
                  <span class="ff-montserrat300 fs-16">Min. Price: ${{minPrice}}</span>
                  <span class="ff-montserrat300 fs-16">Max. Price: ${{maxPrice}}</span>
                </p>
              </div>
              <div class="material-section py-3">
                <h2 class="ff-montserrat500 fs-16">Material</h2>
                <div class="mb-3">
                  <input type="search" class="form-control" placeholder="Search">
                </div>
                <ul class="list-group filter_section_list">
                  <li v-for="Material in 5" :key="Material" class="list-group-item border-0 ps-0 py-1 d-flex justify-content-between align-items-center">
                    <div>
                      <input class="form-check-input filter_section_check_input me-1 shadow-none cp" type="checkbox" name="material" :value="Material" :id="'material'+Material">
                      <label class="form-check-label cp" :for="'material'+Material">Gold Vermeil</label>
                    </div>
                    <span class="badge rounded-pill ff-montserrat300 fs-16 text-site-black">14</span>
                  </li>
                </ul>
              </div>
              <div class="discount-section px-3 pt-5 pb-4" style="background: #313131;">
                <h1 class="ff-montserrat900 mb-0 lh-1 text-site-gold h-fs-69 text-uppercase">50%</h1>
                <h1 class="ff-montserrat900 mb-0 lh-1 text-site-gold fs-27 text-uppercase">Extra</h1>
                <h1 class="ff-montserrat700 mb-0 lh-1 text-site-gray-800 fs-25 text-uppercase">Discount</h1>
                <h2 class="ff-montserrat600 mb-0 lh-1 text-site-gray-800 fs-20 text-uppercase">20-28 December</h2>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-9">
            <div class="d-flex justify-content-end gap-2 align-items-center mb-4">
              <p class="mb-0 text-site-silver d-none d-md-block">Showing 1 to 15 of 243 products</p>
              <button class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16 d-block d-lg-none" @click="isFilterShow =! isFilterShow"><i class="bi bi-sliders2"></i> Filters</button>
              <select class="form-select w-auto border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16 shadow-none" aria-label="Default select example">
                <option>Sort by:</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <input type="radio" class="btn-check btn_check" name="grid" id="grid1" v-model="grid" value="true">
              <label class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16" for="grid1"><i class="bi bi-grid-3x3-gap"></i></label>

              <input type="radio" class="btn-check btn_check" name="grid" id="grid2" v-model="grid" value="false">
              <label class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16" for="grid2"><i class="bi bi-list-task"></i></label>
            </div>
            <div class="product-layout-section" :class="grid === 'true' ? 'd_grid': 'd_list'">
              <div v-for="item in 5" class="card w-100" :key="item" :class="grid === 'true' ? 'border-0': 'flex-row gap-3 border-1'">
                <div class="hover" :class="grid === 'true' ? 'mb-2': 'mb-0'">
                <img class="product-layout-section-image" src="../../../../public/core/assets/images/StylishRing.png">
                  <div
                      class="hover-show d-flex justify-content-center align-items-center rounded-1"
                  >
                    <div>
                      <a
                          class="btn btn-outline-secondary border-white text-white text-uppercase ff-montserrat700 me-2"
                      >
                        <i class="bi bi-heart"></i>
                      </a>
                      <a
                          class="btn btn-outline-secondary border-white text-white text-uppercase ff-montserrat700 ms-2"
                      >
                        <i class="bi bi-bag"></i>
                      </a>

                    </div>
                  </div>
                </div>
                <div class="w-100">
                  <p class="mb-0 ff-montserrat500" :class="grid === 'true' ? '': 'fs-20'">Stylish Ring</p>
                  <span class="bg-site-goldenrod d-inline-block me-2 lh-1" style="width:15px;height:15px">&nbsp;</span>
                  <span class="bg-site-gray-800 d-inline-block lh-1" style="width:15px;height:15px">&nbsp;</span>
                  <p class="mb-0">80.00$</p>
                </div>
              </div>
            </div>
            <div class="text-center py-5">
              <button class="btn bg-transparent border-site-black fs-15 ff-montserrat600 text-uppercase">load more</button>
            </div>
          </div>
        </div>
        <section class="perfect-match-section">
          <div class="row gy-3">
            <div class="col-12 col-sm-6 col-md-5">
              <div class="h-100 d-flex align-items-center">
                <div class="px-3 pe-sm-0 text-end">
                  <h1 class="ff-heading h-fs-98 h-fs-md-69 text-end">
                    Perfect Match
                    Every Occasion
                  </h1>
                  <p class="ff-montserrat300 fs-16 lh-sm text-end">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                  <button class="btn bg-site-Red border-site-Red text-white text-uppercase">Discover the set</button>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-7">
              <div class="position-relative h-100 perfect-match-section-right">
                <div class="perfect-match-section-right-bg"></div>
                <img class="w-100 h-100 ps-5 ps-sm-0 ps-md-5 position-relative z-index-3" src="../../../../public/core/assets/images/PerfectMatch.png" alt="">
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <GiftGuideSchemaJson :metaKeyword="metaKeyword" :metaDescription="metaDescription"></GiftGuideSchemaJson>
  </div>
</template>

<script src="../js/gift_guide.js">
</script>

<style scoped>
@import "../css/gift_guide.css";
</style>
