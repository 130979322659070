<template>
    <div :style="{'background-color': uiComponent.pageBackgroundColor + '!important'}">
        <div class="page-banner">
        <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
        <div class="banner-title" :style="{'background-color': uiComponent.pageHeaderBackgroundColor}">
            <div class="container">
                <h1 class="ff-heading h-fs-55 h-fs-md-45 h-fs-sm-35 h-fs-xs-32" :style="{'color': uiComponent.pageHeaderFontColor + '!important'}">About Us</h1>
            </div>
        </div>
    </div>
    <div class="container py-4 py-md-5">
        <div class="about-img text-center">
            <img :src="data.image" alt="About GoldenGal">
        </div>
        <div class="about-content">
            <h1 class="ff-heading h-fs-55 h-fs-md-45 h-fs-sm-35 h-fs-xs-32 text-center my-4" :style="{'color': uiComponent.titleFontColor + '!important'}">{{ data.title }}</h1>
            <p v-html="data.description"></p>
        </div>
    </div>
    </div>
    
</template>

<script src="../js/about.js"></script>
