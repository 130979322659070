import Loading from "vue-loading-overlay";
import Newsletter from "../../newsletter/template/Newsletter";
import { store } from "../../../store";
import {
    Carousel,
    Navigation,
    Pagination,
    Slide,
} from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css';
import HomeSchemaJson from "../../seo/HomeSchemaJson";

export default {
    data() {
        return {
            store,
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            sectionFourSliderData: [],
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
                wrapAround: true,
                autoplay: 1000,
                pauseAutoplayOnHover: true,
                transition:2000
            },
            settings2: {
                itemsToShow: 1.2,
                snapAlign: 'start',
                wrapAround: true,
                autoplay: 1000,
                pauseAutoplayOnHover: true,
                transition:2000
            },
            settings3: {
                itemsToShow: 1.2,
                snapAlign: 'start',
                wrapAround: true,
                autoplay: 0,
                pauseAutoplayOnHover: true,
                
            },
            settings4: {
                itemsToShow: 2,
                snapAlign: 'start',
                wrapAround: true,
                autoplay: 0,
                pauseAutoplayOnHover: true
            },
            settings5: {
                itemsToShow: 1,
                snapAlign: 'start',
                wrapAround: true,
                autoplay: 3000,
                pauseAutoplayOnHover: true
            },
            settings6: {
                itemsToShow: 3,
                wrapAround: true,
                autoplay: 0,
                pauseAutoplayOnHover: true
            },
            instaSettings: {
                itemsToShow: 1,
                snapAlign: 'center',
                wrapAround: true,
                autoplay: 1000,
                infinite: true,
                pauseAutoplayOnHover: true,
                transition: 2000
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            instaBreakpoints: {
                // 700px and up
                575: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                767: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 4,
                    snapAlign: 'start',
                },
            },
            breakpoints: {
                // 700px and up
                575: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },
            },
            breakpoints2: {
                // 700px and up
                576: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                768: {
                    itemsToShow: 1,
                    snapAlign: 'start',
                },
                // 1024 and up
                992: {
                    itemsToShow: 1.5,
                    snapAlign: 'start',
                },
                1200: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
            },
            breakpoints3: {
                // 700px and up
                576: {
                    itemsToShow: 2.3,
                    snapAlign: 'start',
                },
                768: {
                    itemsToShow: 3.5,
                    snapAlign: 'start',
                },
                // 1024 and up
                992: {
                    itemsToShow: 4.2,
                    snapAlign: 'start',
                },
            },
            breakpoints4: {
                // 700px and up
                576: {
                    itemsToShow: 2.3,
                    snapAlign: 'start',
                },
                768: {
                    itemsToShow: 3.2,
                    snapAlign: 'start',
                },
                // 1024 and up
                992: {
                    itemsToShow: 3.8,
                    snapAlign: 'start',
                },
            },
            breakpoints6: {
                // 700px and up
                576: {
                    itemsToShow: 3,
                },
                768: {
                    itemsToShow: 5,
                },
                // 1024 and up
                992: {
                    itemsToShow: 7,
                },
            },
            settingsLatest: {
                itemsToShow: 1,
                // snapAlign: 'center',
                // itemsToShow: 1.2,
                snapAlign: 'start',
                wrapAround: true,
                autoplay: 1000,
                pauseAutoplayOnHover: true,
                transition:2000
            },
            breakpointsLatest: {
                // 700px and up
                575: {
                    itemsToShow: 2,
                    snapAlign: 'center',
                },
                768: {
                    itemsToShow: 2.5,
                    snapAlign: 'center',
                },
                // 1024 and up
                992: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
                1200: {
                    itemsToShow: 4,
                    snapAlign: 'start',
                },
            },
            currentSlide: 0,
            pageCms: {},
            slider: [],
            customerReview: [],
            sectionTwo: [],
            sectionThree: {},
            sectionFour: {},
            sectionSix: [],
            sectionSeven: {},
            latestProduct: [],
            instaSection: [],
            newsletterCms: {},
            uiComponent: {},
            categoryList: [],
            categoryCMS: {},
            metaKeyword: "",
            metaDescription: "",
            sectionSevenDotsFeature:[],
            instaData: [
                {
                    id: "18232877146089804",media_type: "IMAGE",
                    permalink: "https://www.instagram.com/p/Cmx8RdcugZR/",
                    media_url: "https://golden-gal.nyc3.cdn.digitaloceanspaces.com/Gallery/goldengal.dbe28869-2b4e-462a-a6ee-4e62a3d653be_do.jpg",
                    username: "goldengal",caption: "",
                    timestamp: "2023-01-25T22:29:26+0000"
                },
                {
                    id: "18232877146089804",media_type: "IMAGE",
                    permalink: "https://www.instagram.com/p/Cmx8RdcugZR/",
                    media_url: "https://golden-gal.nyc3.cdn.digitaloceanspaces.com/Gallery/goldengal.dbe28869-2b4e-462a-a6ee-4e62a3d653be_do.jpg",
                    username: "goldengal",caption: "",
                    timestamp: "2023-01-25T22:29:26+0000"
                },
                {
                    id: "18232877146089804",media_type: "IMAGE",
                    permalink: "https://www.instagram.com/p/ClSuqhdu8vT/",
                    media_url: "https://golden-gal.nyc3.cdn.digitaloceanspaces.com/Gallery/goldengal.16a6b05b-e453-4822-a3fc-0a48ff11ad21_do.jpg",
                    username: "goldengal",caption: "",
                    timestamp: "2023-01-25T22:29:26+0000"
                },
                {
                    id: "18232877146089804",media_type: "IMAGE",
                    permalink: "https://www.instagram.com/p/ClSuqhdu8vT/",
                    media_url: "https://golden-gal.nyc3.cdn.digitaloceanspaces.com/Gallery/goldengal.16a6b05b-e453-4822-a3fc-0a48ff11ad21_do.jpg",
                    username: "goldengal",caption: "",
                    timestamp: "2023-01-25T22:29:26+0000"
                },
                {
                    id: "18232877146089804",media_type: "IMAGE",
                    permalink: "https://www.instagram.com/p/Cke5qoxrG1_/",
                    media_url: "https://golden-gal.nyc3.cdn.digitaloceanspaces.com/Gallery/goldengal.8c5f5071-23d8-43ba-a2d3-13aafe492e70_do.jpg",
                    username: "goldengal",caption: "",
                    timestamp: "2023-01-25T22:29:26+0000"
                }, 
                {
                    id: "18232877146089804",media_type: "IMAGE",
                    permalink: "https://www.instagram.com/p/Cke5qoxrG1_/",
                    media_url: "https://golden-gal.nyc3.cdn.digitaloceanspaces.com/Gallery/goldengal.8c5f5071-23d8-43ba-a2d3-13aafe492e70_do.jpg",
                    username: "goldengal",caption: "",
                    timestamp: "2023-01-25T22:29:26+0000"
                }, 
                {
                    id: "18232877146089804",media_type: "IMAGE",
                    permalink: "https://www.instagram.com/p/ChjFzBnua6F/",
                    media_url: "https://golden-gal.nyc3.cdn.digitaloceanspaces.com/Gallery/goldengal.0dc97f42-7365-40ff-9b38-ffcfebc0f462_do.jpg",
                    username: "goldengal",caption: "",
                    timestamp: "2023-01-25T22:29:26+0000"
                }
            ],
        }
    },
    components: {
        Loading,
        Carousel,
        Slide,
        Navigation,
        Pagination,
        Newsletter,
        isSafari: false,
        HomeSchemaJson
    },
    async created() {
        this.scrollToTop();
        await this.getHomeData();
        await this.getNewsletterCMS();
        this.loadJS();
        await this.getPageTitles();
        document.title = (this.pageTitles?.homePageTitle ? this.pageTitles?.homePageTitle : '');
        this.metaKeyword = this.pageTitles.homePageMetaKeyword;
        this.metaDescription = this.pageTitles.homePageMetaDescription;
        this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        //this.$confetti.start();

        const today = new Date().toLocaleDateString()
        const storedData = localStorage.getItem('instagramData')
        if (storedData && JSON.parse(storedData).date === today) {
            this.instaData = JSON.parse(storedData).data
        } else {
            // Clear localStorage data for previous day
            if (storedData && storedData.date !== today) {
                localStorage.removeItem('instagramData')
            }
            // Make API call and save result in localStorage
            await this.getInstagramData();
        }
        
    },

    methods: {
        slideTo(val) {
            this.currentSlide = val
        },
        loadJS: function () {

            // var scripts = [
            //     "confetti.js"
            // ];
            // scripts.forEach(script => {
            //     let tag = document.createElement("script");
            //     tag.setAttribute("src", script);
            //     document.head.appendChild(tag);
            // });
        },
        getNewsletterCMS: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.newsletter.requestURL
            }
            this.isLoading = true
            this.$axios(config).then(response => {
                this.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.newsletterCms = response.data.data[0];
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            })
        },
        getHomeData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.home.homeURL
            }
            this.isLoading = true
            this.$axios(config).then(response => {
                this.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.pageCms = response.data.data.homePageCms.length > 0 ? response.data.data.homePageCms[0] : {};
                    this.slider = response.data.data.homePageSlider;
                    this.customerReview = response.data.data.customerReview;
                    this.sectionTwo = response.data.data.homePageSectionTwo;
                    this.sectionThree = response.data.data.homePageSectionThree.length > 0 ? response.data.data.homePageSectionThree[0] : {};
                    this.sectionFour = response.data.data.homePageSectionFour.length > 0 ? response.data.data.homePageSectionFour[0] : {};
                    this.sectionSix = response.data.data.homePageSectionSix.length > 0 ? response.data.data.homePageSectionSix : [];
                    this.sectionSeven = response.data.data.homePageSectionSeven.length > 0 ? response.data.data.homePageSectionSeven[0] : {};
                    let sectionSevenDotsFeature = response.data.data.homePageSectionSeven.length > 0 ? response.data.data.homePageSectionSeven[0].productBackgroundDotFeature : '[]';
                    if(sectionSevenDotsFeature)
                    {
                        this.sectionSevenDotsFeature = JSON.parse(sectionSevenDotsFeature);
                    }
                    

                    console.log('sectionSevenDotsFeature = ',sectionSevenDotsFeature);
                    this.categoryCMS = response.data.data.homePageSectionSix.length > 0 ? response.data.data.homePageSectionSix[0] : {};
                    this.instaSection = response.data.data.gallery;
                    this.latestProduct = response.data.data.latestProduct;
                    this.uiComponent = response.data.data.HomeComponentCms.length > 0 ? response.data.data.HomeComponentCms[0] : {};
                    this.sectionFourSliderData = response.data.data.sectionFourSliderData.length > 0 ? response.data.data.sectionFourSliderData : [];
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            })
        },
        countPostedTime: function (dateStr) {
            const now = new Date();
            const diff = now - new Date(dateStr);

            const second = 1000;
            const minute = 60 * second;
            const hour = 60 * minute;
            const day = 24 * hour;
            const month = 30 * day;
            const year = 365 * day;

            if (diff < minute) {
                return 'just now';
            } else if (diff < hour) {
                const minutesAgo = Math.floor(diff / minute);
                return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
            } else if (diff < day) {
                const hoursAgo = Math.floor(diff / hour);
                return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
            } else if (diff < month) {
                const daysAgo = Math.floor(diff / day);
                return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
            } else if (diff < year) {
                const monthsAgo = Math.floor(diff / month);
                return `${monthsAgo} month${monthsAgo > 1 ? 's' : ''} ago`;
            } else {
                const yearsAgo = Math.floor(diff / year);
                return `${yearsAgo} year${yearsAgo > 1 ? 's' : ''} ago`;
            }
        },
        getInstagramData: async function () {
            try {
                let config = {
                    method: "GET",
                    url: "https://v1.nocodeapi.com/neutrixinc/instagram/jbOytJmqtLhfTdMG",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    redirect: "follow",
                }
                await this.$axios(config).then((response) => {
                    //this.instaData = response.data.sliderList;

                    if (response.data?.data.length > 0) {
                        console.log(response.data.data);

                        let dataIns = [];
                        let dataServerIns = response.data.data;
                        let itemCount = 1;
                        for (let i = 0; i < dataServerIns.length; i++) {
                            if (dataServerIns[i].media_type == "IMAGE" && itemCount <= 10) {
                                dataIns.push(dataServerIns[i]);
                                itemCount++;
                            }

                        }

                        const today = new Date().toLocaleDateString();
                        localStorage.setItem('instagramData', JSON.stringify({ data: dataIns, date: today }));

                        this.instaData = dataIns;
                    }
                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        },
        openLinkInNewTab: async function (linkUrl) {
            window.open(linkUrl, '_blank');
        },
    }
}

