import { reactive } from 'vue';

export const store = reactive({
    token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
    footerCMSData: {},
    hoverColor: {},
    pageTitles: {},
    socialPackData: [],
    quickLinksData: [],
    customProducts: [],
    cartValue: (localStorage.getItem('cartList') && localStorage.getItem('cartList') != 'null') ? JSON.parse(localStorage.getItem('cartList')) : [],
    wishListValue: (localStorage.getItem('wishList') && localStorage.getItem('wishList') != 'null') ? JSON.parse(localStorage.getItem('wishList')) : [],
    cartCounter: cartCounterValue(),
    wishlistCounter: wishListCounterValue(),
    totalAmount: 0.00,
    isNewsLetter: sessionStorage.getItem('isNewsLetter') ? sessionStorage.getItem('isNewsLetter') : true,
    defaultCard: {
        name: "",
        number: "",
        securityCode: "",
        expireDate: "",
    },
    storeFilters: {
        categoryId: "",
        subCategoryId: "",
        childCategoryId: "",
        subCategoryList: [],
        childCategoryList: [],
        colors: [],
        sizes: [],
        highlights: [],
        tags: [],
        materials: [],
        minPrice: 0,
        maxPrice: 0,
        sortBy: "",
        filterReadyToShip: "",
        currentPage: "",
    },
    giftGuideFilter: {
        categoryId: "",
        subCategoryId: "",
        childCategoryId: "",
        subCategoryList: [],
        childCategoryList: [],
        colors: [],
        sizes: [],
        highlights: [],
        tags: [],
        materials: [],
        minPrice: 0,
        maxPrice: 0,
        sortBy: "",
        filterReadyToShip: "",
        currentPage: "",
    },
    isNewUser: false,
})

function cartCounterValue() {
    let counter = 0;
    if (localStorage.getItem('cartList') && localStorage.getItem('cartList') != 'null') {
        let cartValue = JSON.parse(localStorage.getItem('cartList'));
        for (let i = 0; i < cartValue.length; i++) {
            counter = counter + cartValue[i].quantity;
        }
        return counter;
    } else {
        return 0;
    }
}

function wishListCounterValue() {
    let counter = 0;
    if (localStorage.getItem('wishList') && localStorage.getItem('wishList') != 'null') {
        let wishListValue = JSON.parse(localStorage.getItem('wishList'));
        for (let i = 0; i < wishListValue.length; i++) {
            counter = counter + wishListValue[i].quantity;
        }
        return counter;
    } else {
        return 0;
    }
}
