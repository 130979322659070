import { createRouter, createWebHistory  } from 'vue-router'
import Home from '../components/Home/template/Home';
import About from '../components/About/template/About';
import Contact from '../components/Contact/template/Contact';
import Login from '../components/Auth/template/Login';
import ForgotPassword from '../components/Auth/template/ForgotPassword';
import Registration from '../components/Auth/template/Registration'
import ResetPassword from '../components/Auth/template/ResetPassword'
import Dashboard from '../components/dashboard/Main/template/Main'
import GiftGuide from "../components/GiftGuide/template/GiftGuide";
import Shop from "../components/Shop/template/Shop";
import Style from "../components/style/template/Style";
import Merch from "../components/merch/template/Merch";
import ProductDetails from "../components/product_details/template/ProductDetails"
import Learn from "../components/Learn/template/Learn";
import Promotional from "../components/promotional/template/Promotional";
import GiftCard from "../components/gift_card/template/GiftCard";
import GiftCardDetails from "../components/gift_card_details/template/GiftCardDetails";
import Gallery from "../components/gallery/template/Gallery";
import Collection from "../components/Collection/template/Collection";
import ShoppingCart from "../components/shopping_cart/template/ShoppingCart";
import Checkout from "../components/checkout/template/Checkout";
import PrivacyPolicy from '@/components/Policies/template/PrivacyPolicy';
import ReturnPolicy from '@/components/Policies/template/ReturnPolicy';
import TermsAndCondition from '@/components/Policies/template/TermsAndCondition';
import WishList from '@/components/wishList/template/WishList';
import Invoice from "../components/dashboard/Main/invoice/Invoice.vue";
import guard from "../guard";
import checkoutGuard from "../checkoutGuard"
import Sales from "../components/Shop/template/Sales";
import Occasion from "../components/Shop/template/Occasion";

const routes = [
    { path: '/', redirect: { name: "Home" } },
    { path: '/', name: "Home", component: Home },
    { path: '/gift-guide', name: "GiftGuide", component: GiftGuide },
    { path: '/shop', name: "Shop", component: Shop },
    { path: '/shop/category/:catName', name: "ShopCategory", component: Shop },
    { path: '/shop/color/:colorName', name: "ShopColor", component: Shop },
    { path: '/shop/size/:sizeName', name: "ShopSize", component: Shop },
    { path: '/shop/tag/:tagName', name: "ShopTag", component: Shop },
    { path: '/shop/highlight/:highlightName', name: "ShopHighlight", component: Shop },
    { path: '/shop/material/:materialName', name: "ShopMaterial", component: Shop },
    { path: '/style', name: "Style", component: Style },
    { path: '/merch', name: "Merch", component: Merch },
    { path: '/product-details/:id', name: "ProductDetails", component: ProductDetails },
    { path: '/learn', name: "Learn", component: Learn },
    { path: '/promotional', name: "Promotional", component: Promotional },
    { path: '/gift-card', name: "GiftCard", component: GiftCard },
    { path: '/gift-card-details/:id', name: "GiftCardDetails", component: GiftCardDetails },
    { path: '/gallery', name: "Gallery", component: Gallery },
    { path: '/collection', name: "Collection", component: Collection },
    { path: '/cart', name: "ShoppingCart", component: ShoppingCart },
    { path: '/wish-list', name: "WishList", component: WishList },
    { path: '/checkout', name: "Checkout", component: Checkout, beforeEnter: checkoutGuard },
    { path: '/about', name: "About", component: About },
    { path: '/contact', name: "Contact", component: Contact },
    { path: '/login', name: "Login", component: Login },
    { path: '/registration', name: "Registration", component: Registration },
    { path: '/forgot-password', name: "ForgotPassword", component: ForgotPassword },
    { path: '/reset-password/:token', name: "ResetPassword", component: ResetPassword },
    { path: '/user-dashboard', name: "Dashboard", component: Dashboard, beforeEnter: guard },
    { path: '/privacy-policy', name: "PrivacyPolicy", component: PrivacyPolicy },
    { path: '/return-policy', name: "ReturnPolicy", component: ReturnPolicy },
    { path: '/terms-condition', name: "TermsAndCondition", component: TermsAndCondition },
    { path: '/order-details/:id', name: "Invoice", component: Invoice },
    { path: '/sales', name: "Sales", component: Sales },
    { path: '/occasion', name: "Occasion", component: Occasion }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0, behavior: 'smooth' }
    },
})

export default router
