// import { data } from "jquery";
import { states } from "../../../../../../config/states-list";
import { authHeader } from "../../../../../auth";
export default {
    data () {
        return {
            shippingAddressData: {},
            billingAddressData: {},
            states: states,
            shippingStateCode: '',
            billingStateCode: ''
        }
    },
    async created() {
        document.title = "Golden Gal - Dashboard";
        await this.getAddress();
    },
    methods: {
        getAddress: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.getAddress,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                if (response.data.statusCode == 200 && response.data.data) {
                    if(response.data.data.shippingAddress) {
                        this.shippingAddressData = response.data.data.shippingAddress;
                        this.shippingStateCode = this.shippingAddressData.stateCode;
                    }
                    if(response.data.data.billingAddress) {
                        this.billingAddressData = response.data.data.billingAddress;
                        this.billingStateCode = this.billingAddressData.stateCode;
                    }
                    
                }
            }).catch(error => {
                this.$swal.hideLoading()
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        updateShippingAddress: async function () {
            let data = {
                name: this.shippingAddressData.name,
                addressOne: this.shippingAddressData.addressOne,
                addressTwo: this.shippingAddressData.addressTwo,
                email: this.shippingAddressData.email,
                phone: this.shippingAddressData.phone,
                city: this.shippingAddressData.city,
                postCode: this.shippingAddressData.postCode,
                stateCode: this.shippingStateCode,
                country: "United States Of America",
                countryCode: "US",
            }
            let config = {
                method: "POST",
                data: data,
                url: this.$serverURL + this.$api.user.shippingAddress,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                if (response.data.statusCode == 200 && response.data.data) {
                    this.shippingAddressData = response.data.data;
                    this.shippingStateCode = this.shippingAddressData.stateCode;
                    document.getElementById("closeShip").click();
                }
            }).catch(error => {
                this.$swal.hideLoading()
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        updateBillingAddress: async function () {
            let data = {
                name: this.billingAddressData.name,
                addressOne: this.billingAddressData.addressOne,
                addressTwo: this.billingAddressData.addressTwo,
                email: this.billingAddressData.email,
                phone: this.billingAddressData.phone,
                city: this.billingAddressData.city,
                postCode: this.billingAddressData.postCode,
                stateCode: this.billingStateCode,
                country: "United States Of America",
                countryCode: "US",
            }
            let config = {
                method: "POST",
                data: data,
                url: this.$serverURL + this.$api.user.billingAddress,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                if (response.data.statusCode == 200 && response.data.data) {
                    this.billingAddressData = response.data.data;
                    this.billingStateCode = this.billingAddressData.stateCode;
                    document.getElementById("closeBill").click();
                }
            }).catch(error => {
                this.$swal.hideLoading()
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        // setBillingAddressOne(locationData) {
        //     let location = locationData.formatted_address;
        //     let addressComponent = locationData.address_components;
        //     let locationSplit = location.split(',');
        //     if (locationSplit[0].trim() != locationData.name) {
        //         location = locationData.name + ', ' + location;
        //     }
        //     this.billingAddressData.addressOne = location;

        //     this.billingAddressData.city = "";
        //     this.billingAddressData.postCode = "";
        //     this.billingAddressData.stateCode = "";

        //     for (let i = 0; i < addressComponent.length; i++) {
        //         if (addressComponent[i].types[0] == "locality") {
        //             this.billingAddressData.city = addressComponent[i].long_name;
        //         } else if (addressComponent[i].types[0] == "postal_code") {
        //             this.billingAddressData.postCode = addressComponent[i].long_name;
        //         } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
        //             this.billingAddressData.stateCode = addressComponent[i].short_name;
        //         }
        //     }
        // },
        // setBillingAddressTwo(locationData) {
        //     let location = locationData.formatted_address;
        //     console.log(locationData)
        //     let locationSplit = location.split(',');
        //     console.log(locationSplit);
        //     if (locationSplit[0].trim() != locationData.name) {
        //         location = locationData.name + ', ' + location;
        //     }
        //     this.billingAddress.addressTwo = location;
        // },
        // setShippingAddressOne(locationData) {
        //     let location = locationData.formatted_address;
        //     let addressComponent = locationData.address_components;
        //     let locationSplit = location.split(',');
        //     if (locationSplit[0].trim() != locationData.name) {
        //         location = locationData.name + ', ' + location;
        //     }

        //     this.shippingAddress.addressOne = location;

        //     this.shippingAddress.city = "";
        //     this.shippingAddress.postCode = "";
        //     this.shippingAddress.stateCode = "";

        //     for (let i = 0; i < addressComponent.length; i++) {
        //         if (addressComponent[i].types[0] == "locality") {
        //             this.shippingAddress.city = addressComponent[i].long_name;
        //         } else if (addressComponent[i].types[0] == "postal_code") {
        //             this.shippingAddress.postCode = addressComponent[i].long_name;
        //         } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
        //             this.shippingAddress.stateCode = addressComponent[i].short_name;
        //         }
        //     }
        //     // this.checkSB();
        // },
        // setShippingAddressTwo(locationData) {
        //     let location = locationData.formatted_address;
        //     let locationSplit = location.split(',');
        //     if (locationSplit[0].trim() != locationData.name) {
        //         location = locationData.name + ', ' + location;
        //     }
        //     this.shippingAddress.addressTwo = location;
        //     //this.checkSB();
        // },
    }
}
