<template>
  <div class="tab_content">
    <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
    <table class="table w-100">
      <thead>
        <tr class="table-header">
          <th class="cell" scope="col">Order Id</th>
          <th class="cell" scope="col">Product Name</th>
          <th class="cell" scope="col">Quantity</th>
          <th class="cell" scope="col">Item Price</th>
          <th class="cell" scope="col">Total Price</th>
          <th class="cell" scope="col">Return Status</th>
        </tr>
      </thead>
      <tbody v-if="returnList.length > 0">
        <tr v-for="(data, index) in returnList" :key="index">
          <td><a href="javascript:void(0)" @click="$router.push('/order-details/' + data.orderId)"
              style="color:black">#{{
                data.orderId
              }}</a> </td>
          <td>{{ data.productName }}</td>
          <td>{{ data.quantity }}</td>
          <td>{{ data.actualPrice }}</td>
          <td>{{ data.totalPrice }}</td>
          <td>{{ data.status }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="12">
            <div class="alert alert-primary" role="alert" style="text-align: center">
              No order found!
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="text-center py-5">
    <nav class="page-navigation justify-content-center d-flex" aria-label="page-navigation">
      <v-pagination v-if="returnList.length > 0" v-model="page" @update:modelValue="updateHandler" :pages="totalPage"
        :range-size="1" active-color="#FF8400"></v-pagination>
    </nav>
  </div>
</template>

<script src="../js/return-products.js"></script>
