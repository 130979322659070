import {
    store
} from './store';

export default {
    data() {
        return {
            store,
        }
    },
    methods: {
        addToCart: function (data, quantity) {
            let duplicate = false;
            let outOfStck = false;
            let stock = 0;


            this.store.cartValue.forEach((el, index) => {
                if (el.data.id == data.id) {
                    if (data.type == "product") {
                        if (el.data.stock > el.quantity) {
                            if (el.data.choosedColor === data.choosedColor) {
                                if (el.data.choosedSize === data.choosedSize) {
                                    if (el.data.choosedMaterial === data.choosedMaterial) {
                                        this.store.cartValue[index].quantity = this.store.cartValue[index].quantity + quantity;
                                        this.store.cartCounter = this.store.cartCounter + quantity;
                                        duplicate = true;
                                    }
                                }
                            }
                        } else {
                            outOfStck = true;
                            stock = el.data.stock;

                        }
                    } else if (data.type == 'giftCard') {
                        if (el.data.price == data.price) {
                            this.store.cartValue[index].quantity = this.store.cartValue[index].quantity + quantity;
                            this.store.cartCounter = this.store.cartCounter + quantity;
                            duplicate = true;
                        }

                    }

                }
            });
            if (!outOfStck) {
                if (duplicate == true) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: data.type == 'product' ? "Product added to cart successfully." : "Gift card added to cart successfully.",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    localStorage.setItem(this.$cartList, JSON.stringify(this.store.cartValue));
                }
                if (!duplicate) {
                    this.store.cartValue.push({
                        data: data,
                        quantity: quantity
                    });
                    //this.store.cartCounter++;
                    this.store.cartCounter = this.store.cartCounter + quantity;
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: data.type == 'product' ? "Product added to cart successfully." : "Gift card added to cart successfully.",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    localStorage.setItem(this.$cartList, JSON.stringify(this.store.cartValue));
                }
            } else {
                this.$swal.fire({
                    icon: "info",
                    text: "In-stock only " + stock + " items are available"
                })
                //document.getElementById('offCanvasCloseBtn').click();
            }
            this.store.cartValue = JSON.parse(localStorage.getItem('cartList'));
            this.calculateTotalAmount();
        },

        calculateTotalAmount: function () {
            this.store.totalAmount = 0.00;
            if (this.store.cartValue) {
                this.store.cartValue.forEach((el) => {
                    this.store.totalAmount = this.store.totalAmount + ((el.data.discountPrice === 0.00 ? el.data.discountPrice : el.data.price) *
                        el.quantity);
                });
                return parseFloat(this.store.totalAmount).toFixed(2);

            } else {
                return parseFloat(this.store.totalAmount).toFixed(2);
            }
        },

        increaseProductQuantity: function (index) {
            if (this.store.cartValue[index].data.stock > this.store.cartValue[index].quantity) {
                this.store.cartValue[index].quantity++;
                this.store.cartCounter++;
                localStorage.setItem(this.$cartList, JSON.stringify(this.store.cartValue));
                this.calculateTotalAmount();
            }
            else {
                this.$swal.fire({
                    icon: "info",
                    text: "In-stock only " + this.store.cartValue[index].data.stock + " items are available"
                })
            }

        },

        decreaseProductQuantity: function (index) {
            (this.store.cartValue[index].quantity == 1) ? (this.store.cartValue[index].quantity = 1) : (this.store.cartValue[index].quantity--, this.store.cartCounter--);
            localStorage.setItem(this.$cartList, JSON.stringify(this.store.cartValue));
            this.calculateTotalAmount();
        },

        removeFromCart(index) {

            var c = confirm("Are you sure to remove this item from your cart!");
            if (c) {
                this.store.cartCounter = this.store.cartCounter - this.store.cartValue[index].quantity;
                this.store.cartValue.splice(index, 1);
                localStorage.setItem(this.$cartList, JSON.stringify(this.store.cartValue));
                this.calculateTotalAmount();
            }


        },

    }
}