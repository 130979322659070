<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <section class="mb-4">
      <div class="container">
        <div class="row my-0 g-3">
          <div class="col-12" data-aos="fade-up" data-aos-duration="1500">
              <div class="h-100 position-relative">

                <div class="container">
                  <div class="wrapper">
                    <div class="content">
                      <div class="item pt-5" style="padding-top: 20vh;">
                        <h1 class="h1-cs">COMING SOON</h1>
                        <p class="p-cs">This page is under maintainence.</p>
                        <p class="p-cs">Come visit later.</p>
                      </div>
                    </div>
                  </div>
                </div>
                  
              </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script src="../js/comingSoon.js"></script>

<style scoped>
/* @import "../css/style.css"; */
@import "../css/coming-soon.css";
</style>
