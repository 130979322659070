<template>
  <div :style="{'background-color': uiComponent.pageBackgroundColor}">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <section class="bg-site-black py-5"  v-if="tagList.length > 0" :style="{'background-color': uiComponent.pageHeaderBackgroundColor +'!important'}">
      <div class="container">
        <div class="collection-section-carousel">
          <Carousel :settings="settings" :breakpoints="breakpoints">
            <Slide v-for="(data, index) in tagList" :key="index">
              <div class="carousel__item w-100" @click="$router.push('/shop?tag=' + data.name)">
                <a class="card w-100 border-0 bg-transparent text-center justify-content-center cp">
                  <img width="64" height="63" :src="data.image" class="mx-auto" :alt="data.name" />
                  <div class="card-body p-0">
                    <h5 class="ff-montserrat400 fs-13 text-uppercase text-white">{{ data.name }}</h5>
                  </div>
                </a>
              </div>
            </Slide>
            <template #addons></template>
          </Carousel>
        </div>
      </div>
    </section>
    <section class="py-5" v-if="dataList.length > 0">
      <div class="container" v-for="(data, index) in dataList" :key="index">
        <div class="text-center">
          <h1 class="ff-heading h-fs-49 h-fs-md-39 h-fs-sm-30" :style="{'color': uiComponent.pageTitleFontColor + '!important' }">{{ data.title }}</h1>
          <p :style="{'color': uiComponent.pageSubTitleFontColor + '!important' }">{{ data.subTitle }}</p>
          <div class="shop-section-heading-footer"></div>
          <div class="d-flex gap-3 flex-column flex-md-row justify-content-between py-5">
            <div class="w-40 w-sm-60 pb-0 pb-md-5 position-relative" data-aos="zoom-in" data-aos-duration="2000">
              <img class="w-100" :src="data.blockOneImage" :alt="data.blockOneTitle" />
              <div class="collection-section-left w-60 w-md-70 w-sm-80 h-40 h-md-50 h-sm-60 p-2">
                <div class="text-start">
                  <h2 class="ff-heading h-fs-40 h-fs-md-30">{{ data.blockOneTitle }}</h2>
                  <button
                    class="btn border-site-black"
                    @click="$router.push(data.blockOneLink)"
                  >{{ data.buttonText }}</button>
                </div>
              </div>
            </div>
            <div class="w-35 w-sm-60 pt-0 pt-md-5 position-relative" data-aos="zoom-in" data-aos-duration="2000">
              <span class="pt-0 pt-lg-5 d-block"></span>
              <img class="w-100 mt-0 mt-md-5" :src="data.blockTwoImage" :alt="data.blockTwoTitle" />
              <div class="collection-section-right w-60 w-md-70 w-sm-80 h-40 h-md-50 h-sm-60 p-2">
                <div class="text-start">
                  <h1 class="ff-heading h-fs-40 h-fs-md-30">{{ data.blockTwoTitle }}</h1>
                  <button
                    class="btn border-site-black"
                    @click="$router.push(data.blockTwoLink)"
                  >{{ data.buttonText }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
  </div>
</template>

<script src="../js/collection.js">
</script>

<style scoped>
@import "../css/collection.css";
</style>
