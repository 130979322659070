import Loading from "vue-loading-overlay";
import MultiRangeSlider from "multi-range-slider-vue"
import "multi-range-slider-vue/MultiRangeSliderBlack.css";
import "multi-range-slider-vue/MultiRangeSliderBarOnly.css";
import GiftGuideSchemaJson from "../../seo/GiftGuideSchemaJson";

export default {
    name: "GiftGuide",
    components: {
        Loading,
        MultiRangeSlider,
        GiftGuideSchemaJson
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            grid: 'true',
            minPrice: 290,
            maxPrice: 929,
            isFilterShow: false,
            metaKeyword: "",
            metaDescription: ""
        }
    },
    async created() {
        await this.getPageTitles();
        this.metaKeyword = this.pageTitles.giftGuidePageMetaKeyword;
        this.metaDescription = this.pageTitles.giftGuidePageMetaDescription;
    },
    methods: {
        UpdateValues(e) {
            this.minPrice = e.minValue;
            this.maxPrice = e.maxValue;
        }
    }
}
