import Loading from "vue-loading-overlay";
import MultiRangeSlider from "multi-range-slider-vue"
import "multi-range-slider-vue/MultiRangeSliderBlack.css";
import "multi-range-slider-vue/MultiRangeSliderBarOnly.css";
export default {
    name: "Merch",
    components: {
        Loading,
        MultiRangeSlider
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            grid: 'true',
            minPrice: 290,
            maxPrice: 929,
            isFilterShow: false,
        }},
    methods: {
        UpdateValues(e) {
            this.minPrice = e.minValue;
            this.maxPrice = e.maxValue;
        }
    }
}
