import Loading from "vue-loading-overlay";
import { authHeader } from "../../../../../auth";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    name: "Order",
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            dataList: [],
            page: 1,
            limit: 10,
            totalPage: 0
        }
    },
    async created() {
        document.title = "Golden Gal - Dashboard";
        let params = '?page=' + this.page + '&limit=' + this.limit;
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.order.requestURL + params,
            headers: authHeader()
        };
        await this.getOrderList(config);
    },
    methods: {
        updateHandler: async function () {
            let params = '?page=' + this.page + '&limit=' + this.limit;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.order.requestURL + params,
                headers: authHeader()
            };
            await this.getOrderList(config);
        },
        getOrderList: async function (config) {
            this.isLoading = true;
            this.$axios(config).then(response => {
                this.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.dataList = response.data.data.data;
                    this.totalPage = response.data.data.pageCount;
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            })
        }
    }
}
