<template>
  <div>
<!--    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />-->
    <!-- <SplashScreen v-if="isSplashScreen" :isLoading="isSplashScreen"/> -->
    <!-- <div v-if="!isSplashScreen"> -->
    <Header />
    <div class="main-body">
      <CartComponent />
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <Footer />
    <!-- </div> -->
  </div>
</template>

<script>
import Header from "@/components/layouts/template/Header";
import Footer from "@/components/layouts/template/Footer";
//import SplashScreen from "@/components/splash-screen/template/SplashScreen"
import CartComponent from "./components/cartComponent/template/CartComponent";
import { store } from "./store";
// import Loading from "vue-loading-overlay";
import { authHeader } from "./auth";
export default {
  components: {
    Header,
    Footer,
    CartComponent,
   // SplashScreen,
    // Loading
  },
  data() {
    return {
      isSplashScreen: true,
      watchLoginCheck: false,
      store,
      token: localStorage.getItem("token"),
      isLoading: false,
      fullPage: true,
      loader: "spinner"
    };
  },
  mounted() {

      setTimeout(() => {
        localStorage.setItem('isSplashScreen', true);
        this.isSplashScreen = false;
      }, 6000);

  },
  async created() {
    const isSplash = localStorage.getItem('isSplashScreen');
    if(isSplash){
      this.loadData();
    }
    
    
    if (!localStorage.getItem(this.$cartList)) {
      localStorage.setItem(this.$cartList, null);
    }
    if (!localStorage.getItem(this.$wishList)) {
      localStorage.setItem(this.$wishList, null);
    }
    this.token ? await this.checkUserType() : null;
    await this.getFooterData();
    await this.getHoverColor();
    if (this.$route.path == "/login" || this.$route.path == "/registration") {
      if (this.token) {
        this.$router.push("/");
      }
    }
  },
  computed: {
    checkUser() {
      return this.store.token;
    },
  },
  methods: {
    loadData() {
      // Load your data here
      // Once the data is loaded, set loading to false
      this.isSplashScreen = false;
    }
  },
  watch: {
    $route: function() {
      if (this.$route.path == "/login" || this.$route.path == "/registration") {
        if (this.token) {
          this.$router.push("/");
        }
      } else {
        if (this.watchLoginCheck == false) {
          let config = {
            method: "POST",
            url: this.$serverURL + this.$api.auth.validateTokenURL,
            headers: authHeader()
          };
          this.$axios(config)
            .then(response => {
              if (response.status == 201) {
                if (response.data === false) {
                  this.clearLocalStorage();
                  this.watchLoginCheck = true;
                } else {
                  this.watchLoginCheck = false;
                }
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    },
    checkUser: function() {
      this.checkUserType();
    },
  }
};
</script>

<style scoped>
@import "../public/core/assets/css/common.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "../public/core/assets/css/all.min.scss";
@import "../public/core/assets/css/vue-loading.scss";
@import "../public/core/assets/css/style.scss";
</style>
