import Loading from "vue-loading-overlay";
import MultiRangeSlider from "multi-range-slider-vue"
import "multi-range-slider-vue/MultiRangeSliderBlack.css";
import "multi-range-slider-vue/MultiRangeSliderBarOnly.css";
import VariantsModal from "../../variantsModal/template/VariantsModal";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { store } from "@/store";
import ShopSchemaJson from "../../seo/ShopSchemaJson";

export default {
    name: "Occasion",
    components: {
        Loading,
        MultiRangeSlider,
        VariantsModal,
        VPagination,
        ShopSchemaJson
    },
    data() {
        return {
            store,
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            grid: 'true',
            minPrice: 0,
            maxPrice: 5000,
            isFilterShow: false,
            pageCms: {},
            page: 1,
            limit: 15,
            totalPage: 0,
            productList: [],
            categoryId: '',
            subCategoryId: '',
            childCategoryId: '',
            categoryList: [],
            subCategoryList: [],
            childCategoryList: [],
            colorList: [],
            colors: [],
            sizes: [],
            sizeList: [],
            tagList: [],
            tags: [],
            highlightList: [],
            highlights: [],
            materialList: [],
            materials: [],
            keyword: "",
            routeName: "",
            merchTitle: "",
            sortBy: '',
            filterReadyToShip: false,
            pageTitle: '',
            singleProduct: {
                productColorVariants: [],
                productSizeVariants: [],
                productMaterialVariants: [],
            },
            showModal: false,
            totalData: 0,
            uiComponent: {},
            singleVariants: {},
            isMerch: false,
            selected: "",
            isGiftGuide: false,
            metaKeyword: "",
            metaDescription: ""
        }
    },
    async created() {
        this.isLoading = true;
        this.scrollToTop();
        await this.getPageTitles();
        document.title = this.setPageTitle(this.$route.query.routeName);
        this.metaKeyword = this.pageTitles.shopPageMetaKeyword;
        this.metaDescription = this.pageTitles.shopPageMetaDescription;
        await this.getAllVariant();
        let params = '?page=' + this.page + '&limit=' + this.limit;

        if (this.$route.query.routeName) {
            this.routeName = this.$route.query.routeName;
            this.merchTitle = this.routeName.toUpperCase();
            params += '&routeName=' + this.$route.query.routeName;
            if (this.routeName == 'merch') {
                this.isMerch = true;
                //this.clearFilter();
            } else {
                this.isMerch = false;
            }
            if (this.routeName == 'giftGuide') {
                this.isGiftGuide = true;
            } else {
                this.isGiftGuide = false;
            }
        }

        if (this.isMerch == false) {
            if (this.$route.params.catName) {

                //console.log(this.$route.params.catName)

                if (this.categoryList.length > 0) {
                    for (let i = 0; i < this.categoryList.length; i++) {
                        if (this.categoryList[i].categorySlug == this.$route.params.catName) {
                            this.categoryId = this.categoryList[i].id;
                            this.$route.query.categoryId = this.categoryList[i].id;
                            break;
                        }
                    }
                }

                // for (let index = 0; index < this.categoryList.length; index++) {
                //     const element = this.categoryList[index];
                //     if (element.name.toLowerCase() == this.$route.params.catName.toLowerCase()) {
                //         this.categoryId = element.id;
                //         this.$route.query.categoryId = element.id;
                //     }
                // }
            }
            if (this.$route.query.categoryId) {
                this.categoryId = this.$route.query.categoryId;
                this.getSubCategoryList(this.categoryId);
                if (this.isGiftGuide == false) {
                    this.store.storeFilters.categoryId = "";
                    this.store.storeFilters.subCategoryId = "";
                    this.store.storeFilters.childCategoryId = "";
                } else if (this.isGiftGuide == true) {
                    this.store.giftGuideFilter.categoryId = "";
                    this.store.giftGuideFilter.subCategoryId = "";
                    this.store.giftGuideFilter.childCategoryId = "";
                }



                this.subCategoryId = ""
                this.childCategoryId = ""
                this.childCategoryList = [];
                params += '&parentCategoryId=' + this.$route.query.categoryId;
            }
            this.assignFilters();
            if (this.$route.params.colorName) {

                for (let index = 0; index < this.colorList.length; index++) {
                    const element = this.colorList[index];
                    //console.log('element =', element);
                    if (element.name.toLowerCase() == this.$route.params.colorName.toLowerCase()) {
                        this.colors.push(element.name);
                    }
                }
            }
            if (this.$route.params.sizeName) {

                for (let index = 0; index < this.sizeList.length; index++) {
                    const element = this.sizeList[index];
                    //console.log('element =', element);
                    if (element.name.toLowerCase() == this.$route.params.sizeName.toLowerCase()) {
                        //console.log('sizeName =', element);
                        this.sizes.push(element.name);

                    }
                }
            }
            if (this.$route.params.tagName) {

                for (let index = 0; index < this.tagList.length; index++) {
                    const element = this.tagList[index];
                    //console.log('element =', element);
                    if (element.name.toLowerCase() == this.$route.params.tagName.toLowerCase()) {
                        this.tags.push(element.name);
                        this.$route.query.tag = element.name;
                    }
                }
            }
            if (this.$route.params.highlightName) {
                for (let index = 0; index < this.highlightList.length; index++) {
                    const element = this.highlightList[index];
                    //console.log('element =', element);
                    if (element.name.toLowerCase() == this.$route.params.highlightName.toLowerCase()) {
                        this.highlights.push(element.name);
                        this.$route.query.highlight = element.name;
                    }
                }
            }
            if (this.$route.params.materialName) {

                for (let index = 0; index < this.materialList.length; index++) {
                    const element = this.materialList[index];
                    //console.log('element =', element);
                    if (element.name.toLowerCase() == this.$route.params.materialName.toLowerCase()) {
                        this.materials.push(element.name);
                    }
                }
            }
            if (this.$route.query.subCategoryId) {
                this.subCategoryId = this.$route.query.subCategoryId;
                params += '&subCategoryId=' + this.$route.query.subCategoryId;
            }
            if (this.$route.query.highlight) {
                params += '&highlight=' + this.$route.query.highlight;
            }
            if (this.$route.query.tag) {
                this.tags.push(this.$route.query.tag);
                params += '&tag=' + this.$route.query.tag;
            }
            if (this.$route.query.keyword) {
                this.keyword = this.$route.query.keyword
                params += '&keyword=' + this.$route.query.keyword;
            }
            if (this.$route.query.maxPrice) {
                this.maxPrice = parseFloat(this.$route.query.maxPrice);
                params += '&maxPrice=' + this.$route.query.maxPrice;
            } else if (!this.$route.query.maxPrice && this.maxPrice > 0) {
                params += '&maxPrice=' + this.maxPrice
            }
            if (this.$route.query.minPrice) {
                this.minPrice = parseFloat(this.$route.query.minPrice);
                params += '&minPrice=' + this.$route.query.minPrice;
            } else if (!this.$route.query.minPrice && this.minPrice > 0) {
                params += '&minPrice=' + this.minPrice
            }
            if (!this.$route.params.catName && !this.$route.params.categoryId && this.categoryId) {
                params += '&parentCategoryId=' + this.categoryId;
            }
            if (this.subCategoryId) {
                params += '&subCategoryId=' + this.subCategoryId;
            }
            if (this.childCategoryId) {
                params += '&childCategoryId=' + this.childCategoryId;
            }
            if (!this.$route.params.colorName && this.colors) {
                if (this.colors.length > 0) {
                    params += '&color=' + this.colors;
                }
            }
            if (!this.$route.params.sizeName && this.sizes) {
                if (this.sizes.length > 0) {
                    params += '&size=' + this.sizes;
                }
            }
            if (!this.$route.params.highlightName && this.highlights) {
                if (this.highlights.length > 0) {
                    params += '&highlight=' + this.highlights;
                }
            }
            if (!this.$route.params.materialName && this.materials) {
                if (this.materials.length > 0) {
                    params += '&material=' + this.materials;
                }
            }
            if (!this.$route.query.tag && this.tags) {
                if (this.tags.length > 0) {
                    params += '&tag=' + this.tags;
                }
            }
            if (this.sortBy) {
                params += '&sortBy=' + this.sortBy;
            }
            if (this.filterReadyToShip) {
                params += '&readyToShip=1';
            }
        }

        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.shop.getShopByOccasionURL + params,
        }
        await this.getProduct(config);
        this.isLoading = false;
    },
    methods: {
        setPageTitle(routeName) {
            if (routeName == 'giftGuide') {
                return this.pageTitles.giftGuidePageTitle
            } else if (routeName == 'merch') {
                return this.pageTitles.merchPageTitle
            } else {
                return this.pageTitles.shopPagetitle
            }
            // (this.$route.query.routeName ? this.$route.query.routeName.toUpperCase() : 'SHOP')
        },
        // next: async function () {
        //     if (this.page < parseInt(this.totalPage)) {
        //         this.page++;
        //         this.store.storeFilters.currentPage = this.page;
        //         console.log(this.store.storeFilters.currentPage)
        //     }
        //     await this.filterFunction();
        // },
        // previous: async function () {
        //     if (this.page > 1) {
        //         this.page--;
        //         this.store.storeFilters.currentPage = this.page;
        //         console.log(this.store.storeFilters.currentPage)
        //     }
        //     await this.filterFunction();
        // },

        handleScrolledToBottom(isVisible) {
            if (!isVisible) {
                return;
            }
            this.getMoreProduct();
        },
        getMoreProduct: async function () {
            this.limit += 15;
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.routeName) {
                params += "&routeName=" + this.routeName;
            }
            if (this.categoryId) {
                params += "&parentCategoryId=" + this.categoryId;
            }
            if (this.subCategoryId) {
                params += "&subCategoryId=" + this.subCategoryId;
            }
            if (this.childCategoryId) {
                params += "&childCategoryId=" + this.childCategoryId;
            }
            if (this.$route.query.keyword) {
                params += "&keyword=" + this.$route.query.keyword;
            }
            if (this.colors.length > 0) {
                params += "&color=" + this.colors;
            }
            if (this.sizes.length > 0) {
                params += "&size=" + this.sizes;
            }
            if (this.tags.length > 0) {
                params += "&tag=" + this.tags;
            }
            if (this.highlights.length > 0) {
                params += "&highlight=" + this.highlights;
            }
            if (this.materials.length > 0) {
                params += "&material=" + this.materials;
            }
            if (this.minPrice) {
                params += "&minPrice=" + this.minPrice;
            }
            if (this.maxPrice) {
                params += "&maxPrice=" + this.maxPrice;
            }
            if (this.sortBy) {
                params += "&sortBy=" + this.sortBy;
            }
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.shop.getShopByOccasionURL + params,
            };
            await this.$axios(config)
                .then(async (response) => {
                    this.isLoading = false;
                    this.productList = response.data.products.data;
                    this.totalPage = response.data.products.pageCount;
                    this.totalData = response.data.products.totalDataFound;
                })
        },

        assignFilters: function () {
            if (this.isGiftGuide == false) {
                if (this.store.storeFilters.categoryId) {
                    this.categoryId = this.store.storeFilters.categoryId
                    this.getSubCategoryList(this.categoryId);
                    if (this.store.storeFilters.subCategoryId) {
                        this.subCategoryId = this.store.storeFilters.subCategoryId;
                        this.getChildCategoryList(this.subCategoryId);
                        if (this.store.storeFilters.childCategoryId) {
                            this.childCategoryId = this.store.storeFilters.childCategoryId;
                        }
                    }
                }
                if (this.store.storeFilters.colors.length > 0) {
                    this.colors = this.store.storeFilters.colors
                }
                if (this.store.storeFilters.sizes.length > 0) {
                    this.sizes = this.store.storeFilters.sizes
                }
                if (this.store.storeFilters.highlights.length > 0) {
                    this.highlights = this.store.storeFilters.highlights
                }
                if (this.store.storeFilters.tags.length > 0) {
                    this.tags = this.store.storeFilters.tags
                }
                if (this.store.storeFilters.materials.length > 0) {
                    this.materials = this.store.storeFilters.materials
                }
                if (this.store.storeFilters.minPrice > 0) {
                    this.minPrice = this.store.storeFilters.minPrice
                }
                if (this.store.storeFilters.maxPrice > 0) {
                    this.maxPrice = this.store.storeFilters.maxPrice
                }
                if (this.store.storeFilters.sortBy) {
                    this.sortBy = this.store.storeFilters.sortBy;
                }
                if (this.store.storeFilters.filterReadyToShip) {
                    this.filterReadyToShip = this.store.storeFilters.filterReadyToShip;
                }
                if (this.store.storeFilters.currentPage) {
                    this.page = this.store.storeFilters.currentPage;
                }
            } else if (this.isGiftGuide == true) {
                if (this.store.giftGuideFilter.categoryId) {
                    this.categoryId = this.store.giftGuideFilter.categoryId
                    this.getSubCategoryList(this.categoryId);
                    if (this.store.giftGuideFilter.subCategoryId) {
                        this.subCategoryId = this.store.giftGuideFilter.subCategoryId;
                        this.getChildCategoryList(this.subCategoryId);
                        if (this.store.giftGuideFilter.childCategoryId) {
                            this.childCategoryId = this.store.giftGuideFilter.childCategoryId;
                        }
                    }
                }
                if (this.store.giftGuideFilter.colors.length > 0) {
                    this.colors = this.store.giftGuideFilter.colors
                }
                if (this.store.giftGuideFilter.sizes.length > 0) {
                    this.sizes = this.store.giftGuideFilter.sizes
                }
                if (this.store.giftGuideFilter.highlights.length > 0) {
                    this.highlights = this.store.giftGuideFilter.highlights
                }
                if (this.store.giftGuideFilter.tags.length > 0) {
                    this.tags = this.store.giftGuideFilter.tags
                }
                if (this.store.giftGuideFilter.materials.length > 0) {
                    this.materials = this.store.giftGuideFilter.materials
                }
                if (this.store.giftGuideFilter.minPrice > 0) {
                    this.minPrice = this.store.giftGuideFilter.minPrice
                }
                if (this.store.giftGuideFilter.maxPrice > 0) {
                    this.maxPrice = this.store.giftGuideFilter.maxPrice
                }
                if (this.store.giftGuideFilter.sortBy) {
                    this.sortBy = this.store.giftGuideFilter.sortBy;
                }
                if (this.store.giftGuideFilter.filterReadyToShip) {
                    this.filterReadyToShip = this.store.giftGuideFilter.filterReadyToShip;
                }
                if (this.store.giftGuideFilter.currentPage) {
                    this.page = this.store.giftGuideFilter.currentPage;
                }
            }


        },
        UpdateValues(e) {
            this.minPrice = e.minValue;
            this.maxPrice = e.maxValue;
        },
        parseColorCode: function (code) {
            let colorCode = code.split(",");
            return colorCode;
        },
        getProduct: async function (config) {
            this.isLoading = true;
            await this.$axios(config).then(async (response) => {
                this.isLoading = false;
                if (response.status == 200) {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    this.productList = response.data.products.data;
                    this.totalPage = response.data.products.pageCount;
                    this.totalData = response.data.products.totalDataFound;
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            });
        },
        updateHandler: async function () {
            this.page = 1;
            await this.filterFunction();
        },
        //for category section toogle
        radioToogle: function (val) {
            if (val == this.categoryId) {
                this.categoryId = false;
                this.subCategoryList = [];
                this.childCategoryList = [];
                this.subCategoryId = '';
                this.childCategoryId = '';
                this.updateHandler();
            } else {
                this.categoryId = val;
                this.getSubCategoryList(this.categoryId);
                this.updateHandler();
            }
        },
        radioToogle2: function (val) {
            if (val == this.subCategoryId) {
                this.subCategoryId = false;
                this.childCategoryList = [];
                this.childCategoryId = '';
                this.updateHandler();
            } else {
                this.subCategoryId = val;
                this.getChildCategoryList(this.subCategoryId);
                this.updateHandler();
            }
        },
        radioToogle3: function (val) {
            if (val == this.childCategoryId) {
                this.childCategoryId = false;
                this.updateHandler();
            } else {
                this.childCategoryId = val;
                this.updateHandler();
            }
        },

        filterFunction: async function () {
            let params = '?page=' + this.page + '&limit=' + this.limit;
            if (this.routeName) {
                params += '&routeName=' + this.routeName;
            }

            if (this.categoryId) {
                params += '&parentCategoryId=' + this.categoryId;

            }

            if (this.subCategoryId) {
                params += '&subCategoryId=' + this.subCategoryId;

            }

            if (this.childCategoryId) {
                params += '&childCategoryId=' + this.childCategoryId;

            }

            if (this.$route.query.keyword) {
                params += '&keyword=' + this.$route.query.keyword;
            }

            if (this.colors.length > 0) {
                params += '&color=' + this.colors;
            }
            if (this.sizes.length > 0) {
                params += '&size=' + this.sizes;

            }
            if (this.tags.length > 0) {
                params += '&tag=' + this.tags;

            }
            if (this.highlights.length > 0) {
                params += '&highlight=' + this.highlights;

            }
            if (this.materials.length > 0) {
                params += '&material=' + this.materials;

            }
            if (this.minPrice) {
                params += '&minPrice=' + this.minPrice;

            }
            if (this.maxPrice) {
                params += '&maxPrice=' + this.maxPrice;

            }
            if (this.sortBy) {
                params += '&sortBy=' + this.sortBy;
            }
            if (this.filterReadyToShip) {
                params += '&readyToShip=1';
            }

            if (this.isGiftGuide == false) {
                this.store.storeFilters.categoryId = this.categoryId
                this.store.storeFilters.subCategoryId = this.subCategoryId
                this.store.storeFilters.childCategoryId = this.childCategoryId
                this.store.storeFilters.colors = this.colors;
                this.store.storeFilters.sizes = this.sizes;
                this.store.storeFilters.tags = this.tags;
                this.store.storeFilters.highlights = this.highlights;
                this.store.storeFilters.materials = this.materials;
                this.store.storeFilters.minPrice = this.minPrice;
                this.store.storeFilters.maxPrice = this.maxPrice;
                this.store.storeFilters.sortBy = this.sortBy;
                this.store.storeFilters.filterReadyToShip = this.filterReadyToShip;
            } else if (this.isGiftGuide == true) {
                this.store.giftGuideFilter.categoryId = this.categoryId
                this.store.giftGuideFilter.subCategoryId = this.subCategoryId
                this.store.giftGuideFilter.childCategoryId = this.childCategoryId
                this.store.giftGuideFilter.colors = this.colors;
                this.store.giftGuideFilter.sizes = this.sizes;
                this.store.giftGuideFilter.tags = this.tags;
                this.store.giftGuideFilter.highlights = this.highlights;
                this.store.giftGuideFilter.materials = this.materials;
                this.store.giftGuideFilter.minPrice = this.minPrice;
                this.store.giftGuideFilter.maxPrice = this.maxPrice;
                this.store.giftGuideFilter.sortBy = this.sortBy;
                this.store.giftGuideFilter.filterReadyToShip = this.filterReadyToShip;
            }



            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.shop.getShopByOccasionURL + params,
            }
            await this.getProduct(config);
        },


        // updatePage: async function () {
        //     //let params = "?page=" + this.page + "&limit=" + this.limit;
        //     let params = '?page=' + this.page + '&limit=' + this.limit;
        //     if (this.routeName) {
        //         params += '&routeName=' + this.routeName;
        //     }
        //     if (this.categoryId) {
        //         params += '&parentCategoryId=' + this.categoryId;
        //     }
        //     if (this.subCategoryId) {
        //         params += '&subCategoryId=' + this.subCategoryId;
        //     }
        //     if (this.childCategoryId) {
        //         params += '&childCategoryId=' + this.childCategoryId;
        //     }
        //     if (this.$route.query.keyword) {
        //         params += '&keyword=' + this.$route.query.keyword;
        //     }
        //     if (this.colors.length > 0) {
        //         params += '&color=' + this.colors;
        //     }
        //     if (this.sizes.length > 0) {
        //         params += '&size=' + this.sizes;
        //     }
        //     if (this.tags.length > 0) {
        //         params += '&tag=' + this.tags;
        //     }
        //     if (this.highlights.length > 0) {
        //         params += '&highlight=' + this.highlights;
        //     }
        //     if (this.materials.length > 0) {
        //         params += '&material=' + this.materials;
        //     }
        //     if (this.minPrice) {
        //         params += '&minPrice=' + this.minPrice;
        //     }
        //     if (this.maxPrice) {
        //         params += '&maxPrice=' + this.maxPrice;
        //     }
        //     if (this.sortBy) {
        //         params += '&sortBy=' + this.sortBy;
        //     }
        //     let config = {
        //         method: "GET",
        //         url: this.$serverURL + this.$api.shop.getProductsURL + params,
        //     }
        //     await this.getProduct(config);
        // },

        checkZero: function (e) {
            if (this.minPrice == 0) {
                if (e.keyCode == 8)
                    e.stopPropagation();
            }
        },


        clearFilter: function () {
            this.categoryId = "";
            this.subCategoryId = "";
            this.subCategoryList = [];
            this.childCategoryList = [];
            this.childCategoryId = "";
            this.colors = [];
            this.sizes = [];
            this.highlights = [];
            this.tags = [];
            this.materials = [];
            this.minPrice = 0;
            this.maxPrice = 5000;
            this.filterReadyToShip = false;
            this.sortBy = "";

            if (this.isGiftGuide == false) {
                this.store.storeFilters = {
                    categoryId: "",
                    subCategoryId: "",
                    subCategoryList: [],
                    childCategoryList: [],
                    childCategoryId: "",
                    colors: [],
                    sizes: [],
                    highlights: [],
                    tags: [],
                    materials: [],
                    minPrice: 0,
                    maxPrice: 0,
                    filterReadyToShip: false,
                    sortBy: "",
                    currentPage: ""
                }
            } else if (this.isGiftGuide == true) {
                this.store.giftGuideFilter = {
                    categoryId: "",
                    subCategoryId: "",
                    subCategoryList: [],
                    childCategoryList: [],
                    childCategoryId: "",
                    colors: [],
                    sizes: [],
                    highlights: [],
                    tags: [],
                    materials: [],
                    minPrice: 0,
                    maxPrice: 0,
                    filterReadyToShip: false,
                    sortBy: "",
                    currentPage: ""
                }
            }

            this.updateHandler();
        },
        getAllVariant: async function () {
            let url = this.$serverURL + this.$api.shop.filterVariantsURL;
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    let res = response.data.data
                    this.pageCms = res.productPageCms.length > 0 ? res.productPageCms[0] : {}
                    this.pageTitle = this.pageCms.pageTitle.toUpperCase();
                    this.categoryList = res.productParentCategory;
                    this.colorList = res.productColor;
                    this.sizeList = res.productSize;
                    this.tagList = res.productTag;
                    this.highlightList = res.productHighlight;
                    this.materialList = res.productMaterial;
                    this.uiComponent = res.shopComponentCms.length > 0 ? res.shopComponentCms[0] : {};
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getSubCategoryList: async function (id) {
            this.subCategoryList = [];
            this.subCategoryId = "";
            this.childCategoryList = [];
            this.childCategoryId = "";
            let url = this.$serverURL + this.$api.category.getSubCategoryByParentCategory + '?parentCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.subCategoryList = response.data.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getChildCategoryList: async function (id) {
            this.childCategoryList = [];
            this.childCategoryId = "";
            let url = this.$serverURL + this.$api.category.getChildCategoryBySubCategory + '?subCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.childCategoryList = response.data.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        checkModalElegible: async function (data) {
            let singleProduct = data.itemDetail;
            if (singleProduct.productColorVariants.length == 1 && singleProduct.productMaterialVariants.length == 1) {
                return 0;
            }
            else {
                return 1;
            }

        },
        handleVariants: async function (data) {
            // console.log('data.itemDetail =', data.itemDetail);
            this.singleProduct = data.itemDetail;
            // console.log('this.singleProduct = ', this.singleProduct);
            // console.log('this.singleProduct.productColorVariants = ', this.singleProduct.productColorVariants);
            // console.log('this.singleProduct.productMaterialVariants = ', this.singleProduct.productMaterialVariants);

            if (this.singleProduct.productColorVariants.length <= 1 && this.singleProduct.productSizeVariants.length <= 1 && this.singleProduct.productMaterialVariants.length <= 1) {


                if (this.singleProduct.productColorVariants.length > 0) {
                    this.singleProduct.choosedColor = this.singleProduct.productColorVariants[0].colorName;

                }
                else { this.singleProduct.choosedColor = 'N/A'; }

                if (this.singleProduct.productSizeVariants.length > 0) { this.singleProduct.choosedSize = this.singleProduct.productSizeVariants[0].sizeName; }
                else { this.singleProduct.choosedSize = 'N/A'; }

                if (this.singleProduct.productMaterialVariants.length > 0) { this.singleProduct.choosedMaterial = this.singleProduct.productMaterialVariants[0].materialName; }
                else { this.singleProduct.choosedMaterial = 'N/A'; }

                this.singleProduct.type = 'product';

                this.addToCart(this.singleProduct, 1);
                // this.$router.push('/cart')
                return false;
            }
            else if (Object.values(this.singleProduct).length > 0 && (this.singleProduct.productColorVariants.length > 0 || this.singleProduct.productSizeVariants.length > 0 || this.singleProduct.productMaterialVariants.length > 0)) {
                this.showModal = true;

                this.singleVariants.color = '';
                if (this.singleProduct.productColorVariants.length > 0) {
                    this.singleVariants.color = this.singleProduct.productColorVariants[0].colorName;
                }

                this.singleVariants.size = '';
                if (this.singleProduct.productSizeVariants.length > 0) {
                    this.singleVariants.size = this.singleProduct.productSizeVariants[0].sizeName;
                }

                this.singleVariants.material = '';
                if (this.singleProduct.productMaterialVariants.length > 0) {
                    this.singleVariants.material = this.singleProduct.productMaterialVariants[0].materialName;
                }

            }

        },

    },
    watch: {
        checkAmount: function () {
            this.calculateTotalAmount()
        }
        // categoryId: function () {
        //     if (this.categoryId) {
        //         this.getSubCategoryList(this.categoryId);
        //     }

        // },
        // subCategoryId: function () {
        //     if (this.subCategoryId) {
        //         this.getChildCategoryList(this.subCategoryId);
        //     }
        // }
    }
}
