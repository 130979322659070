<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <section class="py-5">
      <div class="container">
        <div class="row g-3">
          <div class="col-12 col-md-12 col-lg-12">
            <h1 class="ff-heading h-fs-98 h-fs-md-69 fs-xs-27">Wish List</h1>
            <div class="table-container">
              <div class="table-section mt-2">
                <div class="table-responsive">
                  <table class="custom_table">
                    <thead>
                      <tr>
                        <th class="sorting_asc">Product</th>
                        <th class="sorting">Price</th>
                        <th class="sorting">Quantity</th>
                        <th class="sorting">Subtotal</th>
                        <th style="min-width: 120px;">Add to Cart</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody v-if="store.wishListValue.length > 0">
                      <tr v-for="(data, index) in store.wishListValue" :key="index">
                        <td>
                          <div class="d-flex gap-2 align-items-center" style="width: 300px">
                            <img :src="data.data.featuredImage" :style="{ 'cursor': 'pointer' }" height="100" width="100"
                              @click.stop.prevent="$router.push('/product-details/' + data.data.urlSlug)" />
                            <p class="mb-0 w-auto" :style="{ 'cursor': 'pointer' }"
                              @click.stop.prevent="$router.push('/product-details/' + data.data.urlSlug)">
                              {{ data.data.name }}</p>

                          </div>

                        </td>
                        <td class="ff-montserrat500 fs-16">${{
                          parseFloat(data.data.discountPrice === 0.00 ?
                            data.data.discountPrice : data.data.price).toFixed(2)
                        }}</td>
                        <td style="text-align:center;">

                          <template v-if="(data.data.stock > 0)">

                            <div class="form-control d-flex align-items-center justify-content-between py-2"
                              style="min-width: 100px">
                              <a @click="decreaseWishListProductQuantity(index)"
                                class="btn border-0 py-0 px-0 bg-transparent">
                                <i class="bi bi-dash-lg"></i>
                              </a>
                              <span class="ff-montserrat400 fs-17">{{ data.quantity }}</span>

                              <a @click="increaseWishListProductQuantity(index)"
                                class="btn border-0 py-0 px-0 bg-transparent">
                                <i class="bi bi-plus-lg"></i>
                              </a>
                            </div>
                          </template>
                          <template v-else>
                            {{ data.quantity }}
                          </template>
                        </td>
                        <td class="ff-montserrat500 fs-16">${{
                          parseFloat(data.quantity * (data.data.discountPrice === 0.00
                            ? data.data.discountPrice : data.data.price)).toFixed(2)
                        }}</td>
                        <td>
                          <template v-if="(data.data.stock > 0)">
                            <template v-if="(data.data.itemDetail)">
                              <button
                                v-if="data.data.itemDetail.productColorVariants.length > 1 || data.data.itemDetail.productMaterialVariants.length > 1 || data.data.itemDetail.productSizeVariants.length > 1"
                                class="btn btn-outline-dark round_5" type="button" id="button-addon2"
                                data-bs-toggle="modal" data-bs-target="#variantChooseModal"
                                @click="handleVariants(data.data, index, data.quantity)">Add to cart</button>

                              <button v-else class="btn btn-outline-dark round_5" type="button" id="button-addon2"
                                @click="handleVariants(data.data, index, data.quantity)">Add to cart</button>
                            </template>
                            <template v-else>
                              <button
                                v-if="data.data.productColorVariants.length > 1 || data.data.productMaterialVariants.length > 1 || data.data.productSizeVariants.length > 1"
                                class="btn btn-outline-dark round_5" type="button" id="button-addon2"
                                data-bs-toggle="modal" data-bs-target="#variantChooseModal"
                                @click="handleVariants(data.data, index, data.quantity)">Add to cart</button>

                              <button v-else class="btn btn-outline-dark round_5" type="button" id="button-addon2"
                                @click="handleVariants(data.data, index, data.quantity)">Add to cart</button>
                            </template>
                          </template>
                          <template v-else>
                            <button disabled class="btn btn-outline-danger text-uppercase ff-montserrat600 fs-14"
                              title="Item is out of stock now, once available you can see add to cart button.">Out of
                              stock </button>
                          </template>

                        </td>
                        <td>
                          <button title="delete" class="btn btn-sm btn-danger action_btn"
                            @click="removeFromWishList(index)">
                            <i class="bi bi-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="6">
                          <div class="alert alert-info" role="alert" style="text-align:center">WishList Is Empty!!</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="d-flex py-3 gap-3 justify-content-between">
              <div class="input-group mb-3 gap-3 w-55">
                <!-- <input
                  type="text"
                  class="form-control w-55 round_5"
                  placeholder="Coupon Code"
                  aria-label
                  aria-describedby="button-addon2"
                />
                <button
                  class="btn btn-outline-dark round_5"
                  type="button"
                  id="button-addon2"
                >Apply Coupon Code</button>-->
              </div>
              <div>
                <!-- <span>Total:${{store.totalAmount}}</span> -->
              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-md-5 col-lg-3">
        <h1 class="ff-montserrat300 fs-27">Cart Total</h1>
        <div class="d-flex align-items-center justify-content-between bg-site-gray-500 py-2 px-2 mb-3">
          <p class="mb-0 ff-montserrat500 fs-18">Subtotal:</p>
          <p class="mb-0 ff-montserrat500 fs-18">$76.00</p>
        </div>
        <div class="d-flex align-items-start justify-content-between bg-site-gray-500 py-2 px-2 mb-3">
          <p class="mb-0 ff-montserrat500 fs-18">Shipping</p>
          <div>
            <div class="form-check">
              <input class="form-check-input shadow-none Shipping_cart_radio cp" type="radio" name="Shipping" id="Shipping1">
              <label class="form-check-label ff-montserrat300 fs-16 cp" for="Shipping1">
                Flat rate
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input shadow-none Shipping_cart_radio cp" type="radio" name="Shipping" id="Shipping2">
              <label class="form-check-label ff-montserrat300 fs-16 cp" for="Shipping2">
                Free shipping
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input shadow-none Shipping_cart_radio cp" type="radio" name="Shipping" id="Shipping3">
              <label class="form-check-label ff-montserrat300 fs-16 cp" for="Shipping3">
                Local Pickup
              </label>
            </div>
            <p class="mb-1 ff-montserrat300 fs-16">Shipping to CA</p>
            <button class="btn btn-change-address">Change address</button>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label ff-montserrat300 fs-16">Country / Region *</label>
          <select class="form-select rounded-2 ff-montserrat500 fs-16">
            <option class="ff-montserrat500 fs-16">United States (US)</option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label ff-montserrat300 fs-16">State</label>
          <select class="form-select rounded-2 opacity-50 ff-montserrat500 fs-16">
            <option class="ff-montserrat500 fs-16">State</option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label ff-montserrat300 fs-16">Town / City</label>
          <input type="text" class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16" placeholder="Town / City">
        </div>
        <div class="mb-3">
          <label class="form-label ff-montserrat300 fs-16">Postal / Zip Code</label>
          <input type="text" class="form-control rounded-2 opacity-50 ff-montserrat500 fs-16" placeholder="Postal / Zip Code">
        </div>
        <div class="mb-3">
          <button class="btn btn-outline-dark text-uppercase">update</button>
        </div>
        <div class="d-flex align-items-center justify-content-between bg-site-gray-500 py-2 px-2 mb-5">
          <p class="mb-0 ff-montserrat600 fs-18">Total:</p>
          <p class="mb-0 ff-montserrat600 fs-18">$76.00</p>
        </div>
        <div class="">
          <button class="btn bg-site-Red border-site-Red ff-montserrat600 fs-14 text-white text-uppercase">proceed to checkout</button>
        </div>
          </div>-->
        </div>

        <!-- modal -->
        <VariantsModal :singleProduct="singleProduct" :singleVariants="singleVariants" />
        <!-- <div
          class="modal fade"
          id="variantChooseModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1
                  class="modal-title fs-5"
                  id="variantChooseModal"
                >Choose Variants For: {{singleProduct.name}}</h1>
                <button
                  type="button"
                  class="btn-close"
                  id="closeBtn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-sm-6">
                    <img
                      class="product-layout-section-image mb-0"
                      :src="singleProduct.featuredImage"
                    />
                  </div>
                  <div class="col-sm-6">
                    <div v-if="singleProduct.productColorVariants.length > 0">
                      <h3 class="ff-montserrat500 fs-16 text-site-black">Color:</h3>
                      <div class="color_section py-2 mb-2">
                        <div
                          v-for="(data, index) in singleProduct.productColorVariants"
                          :key="index"
                          class="form-check form-check-inline"
                        >
                          <input
                            class="form-check-input shadow-none color_section-check-input cp"
                            :style="{'background-color': data.colorCode + '!important'}"
                            type="radio"
                            :id="data.colorCode"
                            :value="data.colorName"
                            v-model="singleVariants.color"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="singleProduct.productSizeVariants.length > 0">
                      <h3 class="ff-montserrat500 fs-16 text-site-black">Size:</h3>
                      <div class="d-flex gap-3 flex-wrap mb-4">
                        <span
                          v-for="(data, index) in singleProduct.productSizeVariants"
                          :key="index"
                        >
                          <input
                            type="radio"
                            class="btn-check btn_check"
                            :id="'size' + index"
                            :value="data.sizeName"
                            v-model="singleVariants.size"
                          />
                          <label
                            class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16"
                            :for="'size' + index"
                          >{{ data.sizeName }}</label>
                        </span>
                      </div>
                    </div>
                    <div v-if="singleProduct.productMaterialVariants.length > 0">
                      <h3 class="ff-montserrat500 fs-16 text-site-black">Material:</h3>
                      <div class="d-flex gap-3 flex-wrap mb-4">
                        <span
                          v-for="(data, index) in singleProduct.productMaterialVariants"
                          :key="index"
                        >
                          <input
                            type="radio"
                            class="btn-check btn_check"
                            :id="index"
                            :value="data.materialName"
                            v-model="singleVariants.material"
                          />
                          <label
                            class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16"
                            :for="index"
                          >{{ data.materialName }}</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <a
                  class="btn bg-site-Red border-site-Red ff-montserrat600 fs-14 text-white text-uppercase"
                  @click="processCartData()"
                >Add to Cart</a>
              </div>
            </div>
          </div>
        </div>-->

        <!-- end modal -->
      </div>
    </section>
  </div>
</template>

<script src="../js/wish-list.js">
</script>

<style scoped>
@import "../css/shopping_cart.css";
</style>
