<template>
  <div :style="{ 'background-color': uiComponent.pageBackgroundColor }">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <section class="py-3">
      <div class="container">
        <div class="row gy-3">
          <div class="col-12 col-md-6">
            <div
              class="card h-100 justify-content-center align-items-center position-relative py-5"
            >
              <a
                class="btn border-0 btn-fullscreen"
                data-bs-toggle="modal"
                data-bs-target="#fullscreenModal"
              >
                <img src="../../../../public/core/assets/images/fullscreen.svg" />
              </a>
              <img class="w-80 h-80" :src="cardDetails.image" />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <h1
              class="ff-montserrat500 fs-26 mb-4"
              :style="{ 'color': uiComponent.giftCardTitleFontColor + '!important' }"
            >{{ cardDetails.name }}</h1>
            <div class="mb-4">
              <!-- <select class="form-select ff-montserrat500 fs-16 rounded-2 w-90">
                                <option>100 $</option>
              </select>-->
              <!-- <select
                v-model="price"
                class="form-select ff-montserrat500 fs-16 rounded-2"
                style="width: 165px"
              >
                <option style="display: none" value selected>Select Price</option>
                <option v-for="(data,index) in priceList" :key="index" :value="data">{{ data }}</option>
              </select> -->
              <!-- price field changes start -->
              <input type="number" class="w-25 form-control" v-model="price" placeholder="Price">
              <!-- price field changes end -->
              <!-- <p class="ff-montserrat500 fs-16 rounded-2 w-90">{{ cardDetails.price }} $</p> -->
            </div>
            <div
              class="form-control d-flex align-items-center justify-content-between py-3 mb-4"
              style="width: 165px"
            >
              <a @click="decrease" class="btn border-0 py-1 px-2 rounded-0 bg-site-gray-800">
                <i class="bi bi-dash-lg"></i>
              </a>
              <span class="ff-montserrat400 fs-17">{{ quantity }}</span>
              <a @click="quantity++" class="btn border-0 py-1 px-2 rounded-0 bg-site-gray-800">
                <i class="bi bi-plus-lg"></i>
              </a>
            </div>
            <div v-if="isSendGift" class="mb-3 w-35">
              <label class for="email">Receiver Email Address</label>
              <input
                type="email"
                v-model="giftEmail"
                class="form-control"
                placeholder="Email Address"
                aria-describedby="button-addon2"
              />
            </div>
            <div class="d-flex gap-3 mb-4">
              <button
                class="btn bg-site-Red border-site-Red text-white text-uppercase ff-montserrat600 py-3 fs-14 w-50"
                :disabled="!price"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                @click="processCartData(cardDetails)"
              >add to cart</button>
              <button
                v-if="isSendGift === false"
                @click="sendGift()"
                class="btn btn-outline-dark text-uppercase ff-montserrat600 fs-14 py-3 w-50"
              >
                Send a
                gift
              </button>
            </div>

            <div class>
              <ul class="nav nav-pills mb-3 gap-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link rounded-pill gift-card-details-tan-link active"
                    id="pills-description-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-description"
                    type="button"
                    role="tab"
                    aria-controls="pills-description"
                    aria-selected="true"
                  >Description</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link rounded-pill gift-card-details-tan-link"
                    id="pills-warranty-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-warranty"
                    type="button"
                    role="tab"
                    aria-controls="pills-warranty"
                    aria-selected="false"
                  >
                    {{
                    cardDetails.warranty
                    }}
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-description"
                  role="tabpanel"
                  aria-labelledby="pills-description-tab"
                  tabindex="0"
                >
                  <p class>{{ cardDetails.description }}</p>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-warranty"
                  role="tabpanel"
                  aria-labelledby="pills-warranty-tab"
                  tabindex="0"
                >
                  <p>{{ cardDetails.warranty }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Button trigger modal -->
    <!-- Modal -->
    <div
      class="modal fade"
      id="fullscreenModal"
      tabindex="-1"
      aria-labelledby="fullscreenModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header p-0 border-0 align-items-end justify-content-end">
            <button
              type="button"
              class="btn-close shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="margin: 5px 5px 0 0"
            ></button>
          </div>
          <div class="modal-body">
            <img class="w-100 h-100" src="../../../../public/core/assets/images/GiftcardJD.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/gift_card_details.js">
</script>

<style scoped>
@import "../css/gift_card_details.css";
</style>
