import Loading from "vue-loading-overlay";
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
    name: "Collection",
    components: {
        Loading,
        Carousel,
        Slide,
        Navigation,
        Pagination,
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            settings: {

                snapAlign: 'start',
                wrapAround: true,
                autoplay: 0,
                pauseAutoplayOnHover: true
            },
            settings3: {
                itemsToShow: 1,
                snapAlign: 'start',
                wrapAround: true,
                autoplay: 2000,
                pauseAutoplayOnHover: true
            },
            breakpoints: {
                // 700px and up
                57: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
                576: {
                    itemsToShow: 5,
                    snapAlign: 'start',
                },
                768: {
                    itemsToShow: 8,
                    snapAlign: 'start',
                },
                // 1024 and up
                992: {
                    itemsToShow: 10,
                    snapAlign: 'start',
                },
            },
            breakpoints3: {
                // 700px and up
                450: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                768: {
                    itemsToShow: 2.5,
                    snapAlign: 'start',
                },
                // 1024 and up
                992: {
                    itemsToShow: 3.5,
                    snapAlign: 'start',
                },
            },
            tagList: [],
            homeData: {},
            productList: [],
            categoryList: [],
            uiComponent: {},
            dataList: []
        }
    },
    async created(){
        await this.getPageTitles();
        document.title = (this.pageTitles?.collectionPageTitle ? this.pageTitles?.collectionPageTitle : '');
    },
    async mounted() {
        this.scrollToTop();
        await this.getCollectionData();
    },
    methods: {
        getCollectionData: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.collection.requestURL,
              };
              this.isLoading = true;
              await this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.dataList = response.data.data.collectionPage;
                    this.homeData = response.data.data.homePageSectionTwo;
                    this.productList = response.data.data.lastestTenProduct;
                    this.categoryList = response.data.data.productCategories;
                    this.tagList = response.data.data.productTag;
                    this.uiComponent = response.data.data.collectionComponentCms.length > 0 ? response.data.data.collectionComponentCms[0] : {};
                  
                } else {
      
                  this.$swal.fire({
                    icon: "error",
                    text: "Failed! Please try again."
                  });
                }
              }).catch(error => {
                this.isLoading = false;
                console.log(error);
                this.$swal.fire({
                  icon: "error",
                  text: error.response.data.message
                });
              })
        }
    }

}
