<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="row" v-if="Object.values(cmsData).length>0">
      <div class="col-12 col-sm-7">
        <!-- <ul class="list-group overflowX-hidden overflowY-auto h-500">
          <li class="list-group-item d-flex justify-content-between align-items-center border-0">
            <span class="w-40 ff-montserrat600">US Size</span>
            <span class="w-60 ff-montserrat600">Inside circumference(mm)</span>
          </li>
          <li
            v-for="(data, index) in dataList"
            :key="index"
            class="list-group-item d-flex justify-content-between align-items-center border-0"
          >
            <span class="w-40 ff-montserrat600">{{ data.size }}</span>
            <span class="w-60 ff-montserrat600">{{ data.circumference }}</span>
          </li>
        </ul> -->
        <p v-html="cmsData.neckalceDescription"></p>
      </div>
      <!-- <div class="col-12 col-sm-5">
        <div class="h-100">
          <img class="w-100 h-100" :src="tabImage" />
        </div>
      </div> -->
    </div>
    <div v-else>
      <div class="alert alert-info" role="alert" style="text-align:center">No data found!</div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
  name: "NecklaceSizeTab",
  components: {
    Loading
  },
  props: ["tabImage", 'cmsData'],
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "spinner",
      dataList: []
    };
  },
  async created() {
    await this.getNecklaceSizeData();
  },
  methods: {
    getNecklaceSizeData: async function() {
      try {
        this.isLoading = true;
        let config = {
          method: "GET",
          url: this.$serverURL + this.$api.learn.necklaceSizeURL
        };
        await this.$axios(config)
          .then(async response => {
            if (response.data.statusCode == 200) {
              this.dataList = response.data.data;
              this.isLoading = false;
            }
          })
          .catch(error => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message
            });
          });
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          text: "Something went wrong! Please try again."
        });
      }
    }
  }
};
</script>

