<template>
    <div class="page-banner">
        <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
        <div class="banner-title">
            <h1 class="ff-heading h-fs-122 h-fs-md-69 fs-xs-27">{{ data.title }}</h1>
        </div>
    </div>
    <div class="container py-4 py-md-5">
        <div class="about-content ">
            <p v-html="data.description"></p>
        </div>
    </div>
</template>

<script src="../js/terms-condition.js"></script>
