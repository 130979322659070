<template>
    <div class="newsletter p-3" v-if="Object.values(cms).length > 0">
        <div class="position-relative" style="max-width: 900px; width: 100%">
            <a class="close-popup cp float-end bg-site-Red text-white fs-20" @click="closePopup">
                <i class="bi bi-x"></i>
            </a>
            <div class="row g-0">
                <div class="col-5 col-md-5 bg-white">
                    <div class="h-100">
                        <img class="w-100 h-100" :src="cms.image" />
                    </div>
                </div>
                <div class="col-7 col-md-7 bg-white">
                    <div class="h-100 d-flex align-items-center w-100">
                        <div class="p-3 w-100">
                            <h1 class="ff-heading fs-27 fs-md-24 fs-xs-18">{{ cms.title }}</h1>
                            <div class="mb-3">
                                <label class="form-label">{{ cms.emailTtile }}</label>
                                <input type="email" v-model="email" class="form-control"
                                    :placeholder="cms.emailPlaceHolderText" />
                            </div>
                            <button class="btn bg-site-Red border-site-Red text-white text-uppercase"
                                @click="addSubscription">{{ cms.buttonText }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/newsletter.js">
</script>

<style scoped>
@import "../css/newsletter.css";
</style>
