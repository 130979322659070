<template>
    <div :style="{ 'background-color': uiComponent.pageBackgroundColor }">
        <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
        <section class="py-3" v-if="Object.values(productData).length > 0">
            <div class="container">
                <div class="row gy-3">
                    <span style="cursor:pointer" @click="goBack()"><i class="fa-solid fa-arrow-left"></i> &nbsp;Go
                        Back</span>
                    <div class="col-12 col-md-6">
                        <div class="product-details-section-carousel">
                            <Carousel id="gallery" :settings="productImageSettings" v-model="currentSlide" ref="carousel">
                                <Slide v-for="(data, index) in productData.productImages" :key="index">
                                    <div class="carousel__item">
                                        <div class="product-details-section-carousel-card w-100 p-2 min-height-210"
                                            style="object-fit: cover;">
                                            <vue-image-zoomer :regular="data.image" :zoom="data.image" :zoom-amount=2
                                                img-class="img-fluid" />
                                        </div>
                                    </div>
                                </Slide>
                            </Carousel>
                        </div>
                        <div class="product-details-section-carousel-thumbnails">
                            <Carousel id="thumbnails" :settings="thumbnailsSettings" :breakpoints="Productbreakpoints"
                                v-model="currentSlide" ref="carousel">
                                <Slide v-for="(data, index) in productData.productImages" :key="index">
                                    <div class="carousel__item cp h-100" @click="slideTo(index)">
                                        <div class="product-details-section-carousel-card w-100 p-2 h-100">
                                            <img class="w-100" height="100" :src="data.image" :alt="productData.name" />
                                        </div>
                                    </div>
                                </Slide>
                                <template #addons>
                                    <navigation />
                                </template>
                            </Carousel>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="d-flex justify-content-between gap-2">

                            <h3 class="mb-0 ff-montserrat300 fs-16 text-site-gray-900">{{ productData.parentCategoryName
                            }}</h3>
                            <div class="d-flex gap-2 flex-row flex-md-row">
                                <!-- <vue3-star-ratings v-model="rating" :showControl="false" :starSize="'15'"
                                    :disableClick="true" :inactiveColor="'#888888'" :starColor="'#FAC130'" />
                                <span class="ff-montserrat500 fs-16 text-site-dark">{{ rating }} Review(s)</span> -->
                                <!-- <div class="dropdown" style="width: 25px!important;">
                                <button class="btn p-0 border-0 share" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="fas fa-share-alt"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-end share_dropdown">
                                  <div class="d-flex align-items-center py-4 px-3 gap-1">
                                    <p class="mb-0 fs-15 text-site-dark ff-montserrat500">Share :</p>
                                    <div class="d-flex gap-1">
                                      <ShareNetwork
                                          v-for="network in networks"
                                          :network="network.network"
                                          :key="network.network"
                                          :style="{color: network.color+' !important'}"
                                          :url="sharing.url"
                                          :image="sharing.image"
                                          :title="sharing.title"
                                          :description="sharing.description"
                                          :quote="sharing.quote"
                                          :hashtags="sharing.hashtags"
                                          :twitterUser="sharing.twitterUser"
                                          class="sharing-icon"
                                      >
                                        <i :class="network.icon"></i>
                                      </ShareNetwork>
                                    </div>
                                  </div>
                                </div>
                              </div> -->

                            </div>



                        </div>
                        <h1 class="ff-heading h-fs-40 h-fs-md-40 h-fs-sm-30"
                            :style="{ 'color': uiComponent.productTitleFontColor + '!important' }">{{ productData.name
                            }}
                        </h1>
                        <p class="ff-montserrat300 fs-16 text-site-dark">
                            ${{ productData.discountPrice > 0 ? productData.discountPrice : productData.price }} &nbsp;
                            <s v-if="productData.discountPrice > 0"><b>${{ productData.price }}</b></s>
                            <span v-if="productData.stock > 1" class="ms-2 text-site-silver">(In stock)</span>
                        </p>
                        <p v-html="productData.shortDescription"
                            class="border-top border-bottom py-3 border-site-gray-700 ff-montserrat300 fs-16 text-site-dark">
                        </p>
                        <h3 v-if="productData.isReadyToShip" class="btn btn-danger"
                            :style="{ 'background-color': uiComponent.readyToShipButtonColor + '!important', 'border': uiComponent.readyToShipButtonColor + '!important' }">
                            Ready to Ship</h3>
                        <!-- <h3 v-if="productData.isReadyToShip" class="ff-montserrat500 fs-16 text-site-black">Ready to Ship</h3> -->
                        <h3 class="ff-montserrat500 fs-16 text-site-black">Color:</h3>
                        <div class="color_section py-2 mb-2">
                            <div v-for="(data, index) in productData.productColorVariants" :key="index"
                                class="form-check form-check-inline">
                                <input class="form-check-input shadow-none color_section-check-input cp"
                                    :style="{ 'background-color': '#' + data.colorCode + '!important' }" type="radio"
                                    :id="data.colorCode" :value="data.colorName" v-model="color" />
                            </div>
                        </div>
                        <h3 class="ff-montserrat500 fs-16 text-site-black">Size:</h3>
                        <div class="d-flex gap-3 flex-wrap mb-4">
                            <!-- <span v-for="(data, index) in productData.productSizeVariants" :key="index"> -->
                            <span v-for="(data, index) in sizeObject" :key="index"
                                :style="{ '--sizeButton-color': uiComponent.sizeButtonColor }">
                                <input type="radio" class="btn-check btn_check2" :id="'size' + index" :value="data.sizeName"
                                    v-model="size" />
                                <label class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16"
                                    :for="'size' + index">{{ data.sizeName }}</label>
                                <!-- :style="{'background-color': uiComponent.sizeButtonColor + '!important'}" -->
                            </span>
                        </div>
                        <h3 class="ff-montserrat500 fs-16 text-site-black">Material:</h3>
                        <div class="d-flex gap-3 flex-wrap mb-4">
                            <span v-for="(data, index) in productData.productMaterialVariants" :key="index"
                                :style="{ '--sizeButton-color': uiComponent.materialButtonColor }">
                                <input type="radio" class="btn-check btn_check" :id="index" :value="data.materialName"
                                    v-model="material" />
                                <label class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16"
                                    :for="index">{{ data.materialName }}</label>
                            </span>
                        </div>

                        <div class="d-flex gap-3 py-3">
                            <div class="form-check">
                                <input v-if="(productData.stock > 0)" v-model="isThisGift"
                                    class="form-check-input product-details-checkbox cp" type="checkbox"
                                    id="flexCheckDefault" />
                                <input v-else class="form-check-input product-details-checkbox cp" disabled type="checkbox"
                                    id="flexCheckDefault" />
                                <label class="form-check-label ff-montserrat300 fs-16 cp" for="flexCheckDefault"
                                    data-bs-toggle="modal" data-bs-target="#showModal"
                                    @click="updateModalValue(data.id, data.name, data.metaKeyword, data.metaDescription)">Is
                                    This
                                    a Gift</label>

                            </div>
                            <span><i class="bi bi-gift-fill"></i></span>
                        </div>

                        <div class="d-flex flex-wrap gap-2 align-items-center justify-content-between">
                            <div v-if="(productData.stock > 0)" style="width: 150px!important;"
                                class="form-control d-flex align-items-center justify-content-between">
                                <a @click="decrease" class="btn border-0 py-0 px-1 rounded-0 bg-site-gray-800">
                                    <i class="bi bi-dash-lg"></i>
                                </a>
                                <span class="ff-montserrat400 fs-17">{{ quantity }}</span>
                                <a @click="increase" class="btn border-0 rounded-0 py-0 px-1 bg-site-gray-800">
                                    <i class="bi bi-plus-lg" :disabled="quantity == productData.stock"></i>
                                </a>
                            </div>

                            <button v-if="(productData.stock > 0)"
                                class="btn align-self-stretch bg-site-Red border-site-Red text-white text-uppercase ff-montserrat600 fs-14"
                                data-bs-target="#offcanvasRight" data-bs-toggle="offcanvas"
                                @click="processCartData(productData)"
                                :style="{ 'background-color': uiComponent.addToCartButtonColor + '!important', 'border': uiComponent.addToCartButtonColor + '!important' }">Add
                                to Cart
                            </button>
                            <button v-else disabled class="btn btn-outline-danger text-uppercase ff-montserrat600 fs-14">Out
                                of stock
                            </button>
                            <button class="btn align-self-stretch btn-outline-dark round_5"
                                @click="addToWishList(productData, 1)">Add to Wish List</button>

                            <button type="button" class="btn align-self-stretch btn-outline-dark round_5"
                                data-bs-toggle="modal" data-bs-target="#hintModal">Drop a Hint</button>
                            <!-- Hint Modal -->
                            <div class="modal fade" id="hintModal" tabindex="-1" aria-labelledby="hintModalLabel"
                                aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content p-4">
                                        <div>
                                            <div class="text-end">
                                                <button type="button" id="closeButton" class="btn-close"
                                                    data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <h2 class="text-center" id="hintModalLabel">Drop a Hint</h2>
                                            <div class="d-flex justify-content-center">
                                                <p class="text-center modal-paragraph">To send your friend information about
                                                    your selection, complete the information below and click the Send
                                                    button.</p>
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                            <form>
                                                <div class="row g-3">
                                                    <div class="col-md-6">
                                                        <div>
                                                            <input type="text" v-model="friendEmail" class="form-control"
                                                                placeholder="Friend's Email Address">
                                                        </div>
                                                        <div class="mt-3">
                                                            <input type="text" v-model="secondFriendEmail"
                                                                class="form-control"
                                                                placeholder="Second Friend's Email (Optional)">
                                                        </div>
                                                        <div class="mt-3">
                                                            <textarea v-model="message" class="form-control" rows="3"
                                                                placeholder="Your Message"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div>
                                                            <input type="text" v-model="senderName" class="form-control"
                                                                placeholder="Your Name">
                                                        </div>
                                                        <div class="mt-3">
                                                            <input type="text" v-model="senderEmail" class="form-control"
                                                                placeholder="Your Email Address">
                                                        </div>
                                                        <div class="mt-3">
                                                            <select v-model="ringSize" class="form-select"
                                                                aria-label="Default select example">
                                                                <option selected value="">Your Size (Optional)</option>
                                                                <option value="I Don't Know">I Don't Know</option>
                                                                <option value="Less Than 3">Less Than 3</option>
                                                                <option value="3">3</option>
                                                                <option value="3 1/2">3 1/2</option>
                                                                <option value="4">4</option>
                                                                <option value="4 1/2">4 1/2</option>
                                                                <option value="5">5</option>
                                                                <option value="5 1/2">5 1/2</option>
                                                                <option value="6">6</option>
                                                                <option value="6 1/2">6 1/2</option>
                                                                <option value="7">7</option>
                                                                <option value="7 1/2">7 1/2</option>
                                                                <option value="8">8</option>
                                                                <option value="8 1/2">8 1/2</option>
                                                                <option value="9">9</option>
                                                                <option value="9 1/2">9 1/2</option>
                                                                <option value="10">10</option>
                                                                <option value="10 1/2">10 1/2</option>
                                                                <option value="11">11</option>
                                                                <option value="11 1/2">11 1/2</option>
                                                                <option value="12">12</option>
                                                                <option value="12 1/2">12 1/2</option>
                                                                <option value="Greater Than 12">Greater Than 12</option>
                                                            </select>
                                                        </div>
                                                        <div class="mt-4">
                                                            <input type="checkbox" v-model="isEmailCopy"
                                                                class="form-check-input" id="exampleCheck1">
                                                            <label class="form-check-label ms-2" for="exampleCheck1"> Send
                                                                me a copy of this email</label>
                                                        </div>
                                                        <div class="mt-3">
                                                            <button type="button" @click="saveHint()"
                                                                class="btn btn-dark w-100 fw-bold py-2">Send</button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <p class="text-center">Your email address will only be used to
                                                            inform your friend who sent this email.</p>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Hint Modal -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="container" v-if="Object.values(productData).length > 0">

            <div class="container py-3">
                <ul class="nav nav-pills mb-3 justify-content-left w-100 flex-wrap" id="pills-tab" role="tablist">
                    <li class="nav-item w-30 w-xs-auto" role="presentation">
                        <button class="nav-link learn-nav-link w-100 active" id="pills-rings-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-product" type="button" role="tab" aria-controls="pills-rings"
                            aria-selected="true">
                            Product Description
                        </button>
                    </li>
                    <li class="nav-item w-20 w-xs-auto" role="presentation" v-if="productData.video">
                        <button class="nav-link learn-nav-link w-100" id="pills-video-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-video" type="button" role="tab" aria-controls="pills-video"
                            aria-selected="false">
                            Product Video
                        </button>
                    </li>
                        <li class="nav-item w-20 w-xs-auto" role="presentation" v-for="(data, index) in extraDetails"
                            :key="index">
                            <button class="nav-link learn-nav-link w-100" id="pills-details" data-bs-toggle="pill"
                                :data-bs-target="'#pills-details' + index" type="button" role="tab"
                                aria-controls="pills-bracelets" aria-selected="false"
                                v-if="data.title"
                                >
                                {{ data.title }}
                            </button>
                        </li>

                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-product" role="tabpanel"
                        aria-labelledby="pills-rings-tab" tabindex="0">
                        <p v-html="productData.longDescription" class="ff-montserrat300 fs-16 mb-4"></p>
                        <div class="row g-0 mb-4">
                            <div class="col-6 col-sm-4 d-flex justify-content-center" style="margin: 0 auto;">
                                <img class="w-50" :src="productData.featuredImage" :alt="productData.name" />
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id='pills-video' role="tabpanel" aria-labelledby="pills-video-tab"
                        tabindex="0">
                        <div class="row">
                            <video width="500" height="400" v-if="productData.video" controls>
                                <source :src="productData.video" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                    <div class="tab-pane fade" :id="'pills-details' + index" role="tabpanel"
                        aria-labelledby="pills-bracelets-tab" tabindex="0" v-for="(data, index) in extraDetails"
                        :key="index">
                        <p v-html="data.description" class="ff-montserrat300 fs-16 mb-4"></p>
                    </div>
                </div>
            </div>
        </section>

        <section class="RelevantProduct-section" v-if="Object.values(relevantProducts).length > 0">
            <div class="container">
                <h1 class="ff-heading h-fs-98 h-fs-xs-69 text-center"
                    :style="{ 'color': uiComponent.relatedProductTitleFontColor + '!important' }">Relevant Product</h1>
                <div class="RelevantProduct-section-carousel">
                    <Carousel :settings="relevantProductSettings" :breakpoints="relevantProductbreakpoints">
                        <Slide v-for="(data, index) in relevantProducts" :key="index">
                            <div class="carousel__item h-100">
                                <div class="RelevantProduct-section-carousel-card h-100 col-md-4">
                                    <img :src="data.featuredImage" style="height: 250px; width:250px" :alt="data.name" />
                                    <a class="btn bg-site-Red border-site-Red ff-montserrat600 fs-14 text-white text-uppercase"
                                        @click="$router.push('/product-details/' + data.relatedProductID)">Shop Now</a>
                                </div>
                            </div>
                        </Slide>
                        <template #addons>
                            <navigation />
                        </template>
                    </Carousel>
                </div>
            </div>
        </section>


    </div>
    <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="showDModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel" v-html="cmsData.hoverTitle"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <p v-html="cmsData.hoverText" class="border-top border-site-gray-700 ff-montserrat300 fs-16 text-site-dark"
                    :style="{ 'padding': '10px' }">
                </p>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/product-details.js"></script>

<style scoped>@import "../css/product_details.css";</style>
