<template>
  <div>
    <div class="container-xl card border-0 px-md-4 px-lg-5 py-5 rounded-0 shadow-sm" id="invoice"
      v-if="Object.values(dataList).length > 0">
      <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
      <!-- <h1 class="fst-italic ff-poppins700">
        Golden
        <span class="text-site-Red">Gal</span>
      </h1> -->
      <a vclass="navbar-brand p-0 me-0 me-lg-2">
          <img :src="store.footerCMSData.logo" alt="Goldengal Jewelry" />
      </a>
      <div class="row g-3">
        <div class="col-12 col-sm-6">
          <p class="ff-poppins400 fs-20" style="min-width: 150px;color: #8DABC4">{{ userDetails.fullName }}</p>
          <p class="mb-0 fs-15">
            <span style="min-width: 150px; color: #8DABC4">Street Address:</span>
            <br />
            {{ shippingAddress?.addressOne? shippingAddress?.addressOne: "N/A" }}
            <br />
            <span style="min-width: 150px; color: #8DABC4">City:</span>
            {{ shippingAddress?.city? shippingAddress?.city: "N/A" }}
            <br />
            <span style="min-width: 150px; color: #8DABC4">Zip Code:</span>
            {{ shippingAddress?.postCode? shippingAddress?.postCode: "N/A" }}
          </p>
          <p class="fs-15">
            <span style="min-width: 150px; color: #8DABC4">Email:</span>
            {{ userDetails?.email? userDetails?.email: "N/A" }}
          </p>
        </div>
        <div class="col-12 col-sm-6">
          <p class="ff-poppins400 fs-20">Invoice</p>
          <table border="0">
            <tr>
              <td style="color: #8DABC4">Invoice:</td>
              <td>#{{ dataList.orderId }}</td>
            </tr>
            <tr>
              <td style="min-width: 150px; color: #8DABC4">Billed On:</td>
              <td>{{ $filters.formatDate(dataList.createdAt) }}</td>
            </tr>
            <tr>
              <td style="color: #8dabc4">Payment Status:</td>
              <td>{{ dataList.paymentStatus }}</td>
            </tr>
            <tr>
              <td style="color: #8dabc4">Delivery Status:</td>
              <td>
                <b>{{ dataList.deliveryStatus }}</b>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row g-3">
        <div class="col-12 col-sm-6">
          <p class="ff-poppins400 fs-20">Bill To</p>
          <p class="ff-poppins500 fs-20 mb-2">{{ billingAddress.name }}</p>
          <p class="mb-0 ff-poppins500 fs-16">
            {{ billingAddress.addressOne }}
            <br />
            {{ billingAddress.city }}, {{ billingAddress.stateCode }}
            {{ billingAddress.postCode }}
            <br />
            {{ billingAddress.country }}
          </p>
        </div>
        <div class="col-12 col-sm-6">
          <div class="card border-2 rounded-0">
            <div class="d-flex">
              <span v-if="dataList.paymentStatus != 'PAID'"
                class="bg-site-Red px-5 py-2 text-center fs-20 fs-sm-18 text-white">{{ dataList.paymentStatus }}</span>
              <span v-else class="bg-site-black px-5 py-2 text-center fs-20 fs-sm-18 text-white">{{
                dataList.paymentStatus
              }}</span>
              <span class="w-100 p-2 text-center fs-20 fs-sm-18"
                style="background: #E8E8E9; border: 1.5px solid #BDBDBD;">on {{
                  $filters.formatDate(dataList.createdAt)
                }}</span>
            </div>
            <div class="card-body">
              <h2 class="mb-0 text-center">
                ${{ parseFloat(dataList.paymentAmount).toFixed(2) }}
                <span style="ont-weight: 400; font-size: 20px; line-height: 30px; color: #A2A2A2;">USD</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <table border="0" class="w-100 table border-0">
          <thead style="background: #E8E8E9; border: 2px solid #BDBDBD; padding: 5px 10px">
            <tr>
              <th class="py-2 px-3">Sl</th>
              <th style="min-width: 150px">Name</th>
              <th style="min-width: 100px">Type</th>
              <th style="min-width: 100px">Unit Price</th>
              <th>Quantity</th>
              <th style="min-width: 100px">Sub Total</th>
            </tr>
          </thead>
          <tbody>
            <tr style="border: 2px solid #BDBDBD;" v-for="(data, index) in itemList" :key="index">
              <td class="text-center">{{ index+ 1 }}</td>
              <td class>{{ data.name }} {{ data.email ? '[ Gift Receiver: ' + data.email + ' ]' : '' }}
                <br>
                {{ data.variants }}
                <br>
                <p v-if="data.returnQuantity > 0 && (data.returnStatus == 'Approved' || data.returnStatus == 'Unapproved')"
                  style="color:green;">
                  <span style="color: red">*</span>
                  {{`${data.returnQuantity} Item has been` + (data.returnStatus == 'Approved' ? ` returned
                  (${data.returnStatus})` : ` requested for return (${data.returnStatus})`)}}
                </p>
              </td>
              <td>{{ data.type }} {{ data.itemGift ? '(Gift)' : '' }}</td>
              <td>${{ parseFloat(data.price).toFixed(2) }}</td>
              <td>{{ data.quantity }}</td>
              <td>${{ parseFloat(data.amount).toFixed(2) }}</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td colspan="3">
                <div class="d-flex gap-4">
                  <span style="min-width: 150px;">Sub Total</span>
                  <span>${{ parseFloat(dataList.subTotal).toFixed(2) }}</span>
                </div>
                <div class="d-flex gap-4" v-if="dataList.salesTaxAmount">
                  <span style="min-width: 150px;">(+) {{ dataList.salesTaxTitle }}</span>
                  <span>${{ parseFloat(dataList.salesTaxAmount).toFixed(2) }}</span>
                </div>
                <div class="d-flex gap-4" v-if="dataList.couponDiscount">
                  <span style="min-width: 150px;">(-) Coupon Discount</span>
                  <span>${{ parseFloat(dataList.couponDiscount).toFixed(2) }}</span>
                </div>
                <hr class="my-2" style="border-top: 2px solid #BDBDBD!important;" />
                <div class="d-flex gap-4"
                  v-if="(dataList.shippingMethodCost > 0 || dataList.shippingMethodName == 'Free Shipping')">
                  <span style="min-width: 150px;">
                    Shipping Cost
                    <br />
                    (-) ({{ dataList.shippingMethodName }})
                  </span>
                  <span>${{ parseFloat(dataList.shippingMethodCost).toFixed(2) }}</span>
                </div>
                <hr class="my-2" style="border-top: 2px solid #BDBDBD!important;" />
                <div class="d-flex gap-4">
                  <span class="ff-poppins500" style="min-width: 150px;">Amount Paid</span>
                  <span>${{ parseFloat(dataList.paymentAmount).toFixed(2) }}</span>
                </div>
                <hr class="my-2" style="border-top: 2px solid #BDBDBD!important;" v-if="refundAmount > 0" />
                <div class="d-flex gap-4" v-if="refundAmount > 0">
                  <span class="ff-poppins500" style="min-width: 150px;">Refund</span>
                  <span>(-)${{ refundAmount.toFixed(2) }}</span>
                </div>
                <hr class="my-2" style="border-top: 2px solid #BDBDBD!important;" />
                <div class="d-flex gap-4">
                  <span style="min-width: 150px;">Total</span>
                  <span>${{ calculateTotal }}</span>
                </div>

                <hr class="my-2" style="border-top: 2px solid #BDBDBD!important;" v-if="refundAmount > 0" />

              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2 class="ff-poppins400 fs-16">Remarks:</h2>
        <p v-if="dataList.remarks" class="w-50">{{ dataList.remarks }}</p>
        <p v-else class="w-50">N/A</p>
      </div>
    </div>
    <div class="text-center mt-3">
      <button @click="generatePDF()"
        class="btn btn-outline-dark text-uppercase ff-montserrat600 fs-14 py-3 px-5 m-2">Download</button>
      <button
        v-if="filteredRetrunList.length > 0 && dataList.paymentStatus == 'PAID' && dataList.isGlobalRefunded == false"
        type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"
        class="btn btn-outline-dark text-uppercase ff-montserrat600 fs-14 py-3 px-5 m-2">Return</button>
    </div>

    <!-- return modal -->
    <div class="modal fade mt-5" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Return Product</h5>
            <button type="button" class="btn-close" id="close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="table">
              <table class="custom_table">
                <thead>
                  <tr>
                    <th>
                      <input class="form-check-input" v-model="selectAll" @change="handleSelectAll" type="checkbox"
                        value id="flexCheckDefault" />
                    </th>
                    <th>Product</th>
                    <th>Reason</th>
                    <th>Quantity</th>
                  </tr>
                </thead>

                <tbody v-if="filteredRetrunList.length > 0">
                  <template v-for="(data, index) in filteredRetrunList" :key="index">
                    <tr class>
                      <td>
                        <input class="form-check-input" type="checkbox" v-model="selectedReturnProducts[index]" @change="
                          () => {
                            handleSelect(
                              index,
                              selectedReturnProducts[index],
                              data
                            );
                          }
                        " value id="flexCheckDefault" />
                      </td>
                      <td>
                        <div class="d-flex gap-1 align-items-center">
                          <img :src="data.featuredImage ? data.featuredImage : data.image" height="100" width="100"
                            :style="{ 'cursor': 'pointer' }" />
                          <p class="mb-0 w-auto" :style="{ 'cursor': 'pointer' }">{{ data.name }}</p>
                        </div>
                      </td>
                      <td>
                        <textarea class="form-control" id="exampleFormControlTextarea1" v-model="data.note"
                          rows="1"></textarea>
                      </td>
                      <td>
                        <div class="form-control d-flex align-items-center justify-content-between py-2"
                          style="min-width: 100px">
                          <a @click="decreaseQuantity(data)" class="btn border-0 py-0 px-0 bg-transparent">
                            <i class="bi bi-dash-lg"></i>
                          </a>
                          <span class="ff-montserrat400 fs-17">{{ data.insertQty }}</span>

                          <a @click="increaseQuantity(data)" class="btn border-0 py-0 px-0 bg-transparent">
                            <i class="bi bi-plus-lg"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6">
                      <div class="alert alert-info" role="alert" style="text-align:center">No Product Found!!</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>
                      <input
                        class="form-check-input"
                        v-model="selectAll"
                        type="checkbox"
                        value
                        id="flexCheckDefault"
                      />
                    </th>
                    <th>Product</th>
                    <th>Reason</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in filteredRetrunList" :key="index">
                    <td>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="selectedReturnProducts[index]"
                        value
                        id="flexCheckDefault"
                      />
                    </td>
                    <td style="width: 35% !important;">
                      <a class="itemside" href="#">
                        <div class="left">
                          <img
                            :src="data.featuredImage"
                            width="40"
                            height="40"
                            class="img-xs"
                            alt="Item"
                          />
                        </div>
                        <div class="info">{{ data.name }}</div>
                      </a>
                    </td>
                    <td>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        v-model="data.note"
                        rows="1"
                      ></textarea>
                    </td>
                    <td>
                      <div class="numbers-row">
                        <input
                          type="text"
                          :value="data.quantity"
                          id="quantity_1"
                          class="qty2"
                          name="quantity_1"
                        />
                        <div class="inc button_inc">+</div>
                        <div class="dec button_inc">-</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>-->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="color:black">Close</button>
            <button type="button" @click="confirmReturn" class="btn btn-primary">Confirm Return</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./invoice.js"></script>

