import {store} from "@/store";
export default {
    name: "Newsletter",
    props: ['cms'],
    data() {
        return {
            store,
            url: this.$serverURL + this.$api.newsletter.subscription,
            email: ""
        }
    },

    methods: {
        closePopup() {
            sessionStorage.setItem('isNewsLetter', false);
            this.store.isNewsLetter = false;
        },
        addSubscription: async function () {
            if (this.email) {
                let data = {
                    email: this.email
                };
                let config = {
                    method: "POST",
                    url: this.url,
                    data: data
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201) {

                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: "Subscription Successfull",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.closePopup();
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } else {
                this.$swal.fire({
                    icon: "error",
                    text: " Enter email address!"
                });
            }

        },
    }
}