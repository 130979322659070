import { authHeader } from "../../../../../auth";
import { states } from "../../../../../../config/states-list";
export default {
    data() {
        return {
            userDetails: {},
            id: localStorage.getItem('id'),
            stateCode: '',
            states: states
        }
    },
    async created() {
        document.title = "Golden Gal - Dashboard";
        await this.getUserInfo();
    },
    methods: {
        setStreetAddress(locationData) {

            let location = locationData.formatted_address;
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            //this.billingAddress.addressOne = location;
            this.userDetails.streetAddress="";
            for (let i = 0; i < addressComponent.length; i++) {

                if (addressComponent[i].types[0] == "street_number") {
                    this.userDetails.streetAddress = addressComponent[i].long_name;
                }

                if (addressComponent[i].types[0] == "route") {

                    this.userDetails.streetAddress +=" "+addressComponent[i].long_name;
                }

                if (addressComponent[i].types[0] == "locality") {
                    this.userDetails.city = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.userDetails.postCode = addressComponent[i].long_name;
                } 
            }
        },
        getUserInfo: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.singleUserDetails + this.id,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.userDetails = await response.data.data;
                    this.stateCode = this.userDetails.stateCode;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        save: async function () {
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("fullName", this.userDetails.fullName);
            formData.append("displayName", this.userDetails.displayName);
            formData.append("phone", this.userDetails.phone);
            formData.append("streetAddress", this.userDetails.streetAddress);
            formData.append("city", this.userDetails.city);
            formData.append("postCode", this.userDetails.postCode);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.user.updateProfile,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    localStorage.setItem("fullName", this.userDetails.fullName);
                    localStorage.setItem("displayName", this.userDetails.displayName);
                    localStorage.setItem("email", this.userDetails.email);
                    localStorage.setItem("phone", this.userDetails.phone);
                    this.$swal.fire({
                        icon: "success",
                        text: "Data updated successfully!"
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}
