import Loading from "vue-loading-overlay";
export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            data: {}
        }
    },
    async created() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.termsPageTitle ? this.pageTitles?.termsPageTitle : '');
        this.scrollToTop();
        await this.getData();
    },
    methods: {
        getData: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.policies.termsConditionURL
            };
            await this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.status == 200) {
                    this.data = response.data.data[0] ? response.data.data[0] : {};
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}