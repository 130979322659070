import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import moment from "moment";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import mixin from "./mixin";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap";
import vue3StarRatings from "vue3-star-ratings";
import vue3GoogleLogin from "vue3-google-login";
import cartMixin from "./cartMixin";
import wishListMixin from "./wishListMixin";
import VueImageZoomer from "vue-image-zoomer";
import "vue-image-zoomer/dist/style.css";
import VueSocialSharing from "vue-social-sharing";
import AOS from "aos";
import "aos/dist/aos.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueObserveVisibility from "vue3-observe-visibility";

const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$filters = {
  formatDate(date) {
    return moment(String(date)).format("MM/DD/YYYY");
  },
};
app.config.globalProperties.$api = require("../config/api.json");
const server = require("../config/server.json");

// if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
//     app.config.globalProperties.$serverURL = server.staging.SERVER_URL;
//     app.config.globalProperties.$hostURL = server.staging.IMAGE_URL;
// }
// else {
app.config.globalProperties.$serverURL = server.server_host.SERVER_URL;
app.config.globalProperties.$hostURL = server.server_host.IMAGE_URL;
// }

const googleKey = server.google.logInKey;
app.config.globalProperties.$googleKey = googleKey;
const clientID = googleKey + ".apps.googleusercontent.com";
app.config.globalProperties.$googleSiteClientID = clientID;
app.config.globalProperties.$cartList = "cartList";
app.config.globalProperties.$wishList = "wishList";
app.component("vue3-star-ratings", vue3StarRatings);
app.use(vue3GoogleLogin, {
  clientId: clientID,
});
app.use(VueSocialSharing);
app.use(VueSweetalert2);
app.use(VueObserveVisibility);
//app.use(VueConfetti);
app.use(VueImageZoomer);
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCzeiU5pW1Ja8-pJiSz8b-XTx3MBWNCThA",
    libraries: "places",
  },
});
app.mixin(mixin);
app.mixin(cartMixin);
app.mixin(wishListMixin);
AOS.init({
  offset: 220,
  mirror: true,
});

app.use(router).mount("#app");
