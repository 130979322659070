<template>
  <div class="login">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <h1 class="ff-heading h-fs-122 h-fs-md-98 fs-xs-27 text-center py-10">
      Reset Password
    </h1>
    <div class="container">
      <div class="row">
        <form class="col-xl-8 col-lg-8 col-md-8 col-sm-10 mx-auto form">
          <div class="row pb-4">
            <div class="form-group col-xs-12 col-sm-12" v-if="resetFlag">
              <label class="mb-3">Password</label>
              <input :type="[showPassword ? 'text' : 'password']" v-model="password" placeholder="Password"
                class="form-control mb-3" />
              <span class="icon" @click="toggleShow" >
                <i class="fas field-icon" style="margin-top:-50px" :class="{
                  'fa-eye': showPassword,
                  'fa-eye-slash': !showPassword,
                }"></i>
              </span>
            </div>
            <div class="form-group col-xs-12 col-sm-12" v-if="resetFlag">
              <label class="mb-3">Re-Type Password</label>
              <input :type="[showPasswordAnother ? 'text' : 'password']" v-model="confirm_password"
                placeholder="Re-Type Password" class="form-control" />
              <span class="icon" @click="toggleShowAnother">
                <i class="fas field-icon" :class="{
                  'fa-eye': showPasswordAnother,
                  'fa-eye-slash': !showPasswordAnother,
                }"></i>
              </span>
            </div>
            <div class="form-group col-xs-12 col-sm-12 mt-4" v-if="resetFlag">
              <input type="button" @click="updatePassword"  class="
                  btn btn-primary
                  w-100
                  btn-contact
                  border-site-Red
                  bg-site-Red
                " value="Submit" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script src="../js/reset-password.js"></script>
