<template>
  <div
    class="modal fade"
    id="variantChooseModal"
    ref="variantsModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1
            class="modal-title fs-5"
            id="variantChooseModal"
          >Choose Variants For: {{singleProduct.name}}</h1>
          <button
            type="button"
            class="btn-close"
            id="closeBtn"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-6">
              <img class="img-fluid mb-0" :src="singleProduct.featuredImage" />
            </div>
            <div class="col-sm-6">
              <div v-if="singleProduct.productColorVariants.length > 0">
                <h3 class="ff-montserrat500 fs-16 text-site-black">Color:</h3>
                <div class="color_section py-2 mb-2" >
                  <div
                    v-for="(data, index) in singleProduct.productColorVariants"
                    :key="index"
                    class="form-check form-check-inline"
                  >
                    <input 
                      class="form-check-input shadow-none color_section-check-input cp"
                      :style="{'background-color': '#'+data.colorCode + '!important'}"
                      type="radio"
                      :id="data.colorCode"
                      :value="data.colorName"
                      v-model="singleVariants.color"
                    />
                    
                  </div>
                </div>
              </div>
              <div v-if="singleProduct.productSizeVariants.length > 0">
                <h3 class="ff-montserrat500 fs-16 text-site-black">Size:</h3>
                <div class="d-flex gap-3 flex-wrap mb-4">
                  <span v-for="(data, index) in singleProduct.productSizeVariants" :key="index" :style="{'--sizeButtonModal-color': varientModalData.sizeButtonColor}">
                    <input
                      type="radio"
                      class="btn-check btn_check3"
                      :id="'size' + index"
                      :value="data.sizeName"
                      v-model="singleVariants.size"
                    />
                    <label
                      class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16"
                      :for="'size' + index"
                    >{{ data.sizeName }}</label>
                  </span>
                </div>
              </div>
              <div v-if="singleProduct.productMaterialVariants.length > 0">
                <h3 class="ff-montserrat500 fs-16 text-site-black">Material:</h3>
                <div class="d-flex gap-3 flex-wrap mb-4">
                  <span v-for="(data, index) in singleProduct.productMaterialVariants" :key="index" :style="{'--materialButtonModal-color': varientModalData.materialButtonColor}">
                    <input
                      type="radio"
                      class="btn-check btn_check4"
                      :id="index"
                      :value="data.materialName"
                      v-model="singleVariants.material"
                    />
                    <label
                      class="btn border-site-silver bg-transparent rounded-0 ff-montserrat500 fs-16"
                      :for="index"
                    >{{ data.materialName }}</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :style="{ 'background-color': varientModalData.closeButtonColor + '!important', 'border-color': varientModalData.closeButtonColor + '!important' }">Close</button>
          <button
            class="btn bg-site-Red border-site-Red ff-montserrat600 fs-14 text-white text-uppercase"
            @click="processCartData()"
               data-bs-toggle="offcanvas" 
               data-bs-target="#offcanvasRight" 
               aria-controls="offcanvasRight"
               :style="{ 'background-color': varientModalData.addToCartButtonColor + '!important', 'border-color': varientModalData.addToCartButtonColor + '!important' }" 
          >Add to Cart</button>
          <!-- <a
            class="btn bg-site-Red border-site-Red ff-montserrat600 fs-14 text-white text-uppercase"
            @click="processCartData()"
          >Add to Cart</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/variants-modal.js"></script>

<style scoped>
.btn_check3:checked+.btn {
    color: var(--white) !important;
    background-color: var(--sizeButtonModal-color) !important;
    border-color: var(--sizeButtonModal-color) !important;
}
.btn_check4:checked+.btn {
    color: var(--white) !important;
    background-color: var(--materialButtonModal-color) !important;
    border-color: var(--materialButtonModal-color) !important;
}
</style>