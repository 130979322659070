import Loading from "vue-loading-overlay";
export default {
    name: "GiftCardDetails",
    components: {
        Loading,
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            currentSlide: 0,
            rating: 5,
            isGift: false,
            quantity: 1,
            cardDetails: {},
            id: this.$route.params.id,
            uiComponent: {},
            isSendGift: false,
            giftEmail: "",
            priceList: [],
            price: ""
        }
    },
    async created() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.giftCardPageTitle ? this.pageTitles?.giftCardPageTitle : '');
        this.scrollToTop();
    },
    async mounted() {
        await this.getGiftCardDetails();
    },
    methods: {
        decrease() {
            this.quantity--
            if (this.quantity <= 0) {
                this.quantity = 1
            }
        },
        getGiftCardDetails: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.giftCard.requestDetails + this.id,
            };
            await this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.cardDetails = response.data.data.giftCardDetails;
                    this.priceList = this.cardDetails.price.split(',');
                    this.uiComponent = response.data.data.giftCardDetailsComponentCms.length > 0 ? response.data.data.giftCardDetailsComponentCms[0] : {};

                } else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! Please try again."
                    });
                }
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            })
        },
        sendGift() {
            this.isSendGift = true;
        },
        processCartData(data) {
            data.type = "giftCard";
            if(this.isSendGift && !this.giftEmail) {
                this.$swal.fire({
                    icon: "warning",
                    text: "Please enter the gift receiver email address!"
                });
                return false;
            }
            if(this.isSendGift && this.giftEmail) {
                data.giftEmail = this.giftEmail;
            }
            if(!this.isSendGift && !this.price) {
                this.$swal.fire({
                    icon: "warning",
                    text: "Please select price!"
                });
                return false;
            }
            data.price = this.price;
            this.addToCart(data, this.quantity);
            this.$router.push('/gift-card');
        },

    }
}