import {
    store
} from "./store";
export default {
    data() {
        return {
            store,
            //wishList: (localStorage.getItem(this.$wishList) && localStorage.getItem(this.$wishList) != 'null') ? JSON.parse(localStorage.getItem(this.$wishList)) : [],
        }
    },
    methods: {
        addToWishList(data, quantity) {
            let duplicate = false;
            this.store.wishListValue.forEach((el, index) => {
                if (el.data.id == data.id) {
                    this.store.wishListValue[index].quantity = this.store.wishListValue[index].quantity + quantity;
                    this.store.wishlistCounter = this.store.wishlistCounter + quantity;
                    duplicate = true
                }
            });
            if (duplicate == true) {
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    text: "Product added to whishlist successfully.",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                    animation: false,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    }
                });
                localStorage.setItem(this.$wishList, JSON.stringify(this.store.wishListValue));
            }
            if (!duplicate) {
                this.store.wishListValue.push({
                    data: data,
                    quantity: quantity
                });
                this.store.wishlistCounter = this.store.wishlistCounter + quantity;
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    text: "Product added to whishlist successfully.",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                    animation: false,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    }
                });
                localStorage.setItem(this.$wishList, JSON.stringify(this.store.wishListValue));
                return;
            }
        },
        increaseWishListProductQuantity: function (index) {
            this.store.wishListValue[index].quantity++;
            this.store.wishlistCounter++;
            localStorage.setItem(this.$wishList, JSON.stringify(this.store.wishListValue));
        },
        decreaseWishListProductQuantity: function (index) {
            (this.store.wishListValue[index].quantity == 1) ? (this.store.wishListValue[index].quantity = 1) : (this.store.wishListValue[index].quantity--, this.store.wishlistCounter--);
            localStorage.setItem(this.$wishList, JSON.stringify(this.store.wishListValue));
        },
        moveFromWishListToCart: function (data, index) {
            let duplicate = false;
            this.store.cartValue.forEach((el, elindex) => {
                if (el.data.id == data.data.id) {
                    if (el.data.choosedColor == data.data.choosedColor) {
                        if (el.data.choosedSize == data.data.choosedSize) {
                            if (el.data.choosedMaterial == data.data.choosedMaterial) {
                                this.store.cartValue[elindex].quantity = this.store.cartValue[elindex].quantity + data.quantity;
                                this.store.cartCounter = this.store.cartCounter + data.quantity;
                                duplicate = true;
                            }
                        }
                    }
                }
            });
            if (duplicate == true) {
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    text: "Product added to cart successfully.",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                    animation: false,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    }
                });
                localStorage.setItem(this.$cartList, JSON.stringify(this.store.cartValue));
                this.removeFromWishList(index);
            }
            if (!duplicate) {
                this.store.cartValue.push(data);
                this.store.cartCounter = this.store.cartCounter + data.quantity;
                this.removeFromWishList(index);
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    text: "Product added to cart successfully.",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                    animation: false,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    }
                });
                localStorage.setItem(this.$cartList, JSON.stringify(this.store.cartValue));
            }
            this.calculateTotalAmount();
        },
        removeFromWishList(index) {
            this.store.wishlistCounter = this.store.wishlistCounter - this.store.wishListValue[index].quantity;
            this.store.wishListValue.splice(index, 1);
            localStorage.setItem(this.$wishList, JSON.stringify(this.store.wishListValue));
        },
    }
}