<template>
    <div class="login">
        <loading :active="isLoading" :is-full-page="fullPage" :loader="loader"/>
        <h1 class="ff-heading h-fs-122 h-fs-md-98 fs-xs-27 text-center py-10">Forgot Password</h1>
        <div class="container">
            <div class="row">
                <form class="col-xl-8 col-lg-8 col-md-8 col-sm-10 mx-auto form">
                    <div class="row pb-4">
                        <div class="form-group col-xs-12 col-sm-12">
                            <label class="mb-3">Email</label>
                            <input v-model="forgotEmail" name="email" placeholder="Email" class="form-control mb-3" type="text"/>
                        </div>
                        <div class="form-group col-xs-12 col-sm-12 mt-4">
                            <input type="button" class="btn btn-primary w-100 btn-contact border-site-Red bg-site-Red" @click="forgotPassword" value="Send Reset Link"/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script src="../js/forgot-password.js"></script>
