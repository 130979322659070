<template>
  <div class="account-detail">
    <form class="col-xl-8 col-lg-8 col-md-8 col-sm-10 mx-auto form">
      <h4 class="fw-bold pb-2">Account Details</h4>
      <div class="row pb-4">
        <div class="form-group col-xs-12 col-sm-12">
          <label>Full Name</label>
          <input name="fName" v-model="userDetails.fullName" placeholder="Enter full name" class="form-control" type="text" />
        </div>
        <!-- <div class="form-group col-xs-12 col-sm-6">
          <label>Display Name</label>
          <input name="lName" v-model="userDetails.displayName" placeholder="Display Name" class="form-control" type="text" />
        </div> -->
      </div>
      <div class="row pb-4">
        <div class="form-group col-sm-6">
          <label>Email Address</label>
          <input name="fName" v-model="userDetails.email" placeholder="Enter email address" class="form-control" type="text" disabled />
        </div>
        <div class="form-group col-sm-6">
          <label>Phone Number</label>
          <input name="fName" v-model="userDetails.phone" placeholder="Enter phone no." class="form-control" type="text" />
        </div>
      </div>
      <div class="row pb-4">
        <div class="form-group col-xs-12 col-sm-12">
          <label>Street Address</label>
          <!-- <input class="form-control" v-model="userDetails.streetAddress" placeholder="Address" type="text"> -->
          <GMapAutocomplete
            @place_changed="setStreetAddress"
            :value="userDetails.streetAddress"
            :class="'form-control'"
          ></GMapAutocomplete>
        </div>
      </div>
      <div class="row pb-4">
        <div class="form-group col-xs-12 col-sm-6">
          <label>Town / City</label>
          <input name="fName" v-model="userDetails.city" placeholder="Enter town / city" class="form-control" type="text" />
        </div>
        <div class="form-group col-xs-12 col-sm-6">
          <label>Postal Zip Code</label>
          <input name="lName" placeholder="Enter postal zip code" v-model="userDetails.postCode" class="form-control" type="text" />
        </div>
      </div>
      <button type="button" class="btn btn-primary btn-contact border-site-Red bg-site-Red" @click="save">Update Profile</button>
    </form>
  </div>
</template>
<script src="../js/account.js"></script>