import Loading from "vue-loading-overlay";
import { googleSdkLoaded } from "vue3-google-login";
import { store } from "@/store";
import RegistrationSchemaJson from "../../seo/RegistrationSchemaJson";

export default {
    data() {
        return {
            store,
            isLoading: false,
            fullPage: true,
            loader: 'spinner',
            fullName: "",
            phone: "",
            email: "",
            password: "",
            confirmPassword: "",
            metaKeyword: "",
            metaDescription: ""
        }
    },
    components: {
        Loading,
        RegistrationSchemaJson
    },
    async created() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.registrationPageTitle ? this.pageTitles?.registrationPageTitle : '');
        this.metaKeyword = this.pageTitles.registrationPageMetaKeyword;
        this.metaDescription = this.pageTitles.registrationPageMetaDescription;
    },
    methods: {
        validate: function () {
            if (!this.fullName) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please enter full name!"
                })
                return false;
            } else if (!this.phone) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please enter contact number!"
                })
                return false;
            } else if (this.phone.length <= 9) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Phone number must be atleast 10 digit!"
                })
                return false;
            } else if (!this.email) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please enter email!"
                })
                return false;
            } else if (!this.password) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please enter password!"
                })
                return false;
            } else if (this.password.length <= 5) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Password must be atleast 6 digit!"
                })
                return false;
            } else if (!this.confirmPassword) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please re-type password!"
                })
                return false;
            } else if (this.password != this.confirmPassword) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Password and confirm password doesn't match!"
                })
                return false;
            }
            return true;
        },
        register: async function () {
            if (this.validate()) {
                let data = {
                    fullName: this.fullName,
                    phone: this.phone,
                    email: this.email,
                    password: this.password
                }
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.auth.registrationURL,
                    data: data
                }
                this.isLoading = true;
                this.$axios(config).then((response) => {
                    this.isLoading = false;
                    if (response.data.statusCode == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        localStorage.setItem("token", response.data.data.token);
                        localStorage.setItem("id", response.data.data.user.id);
                        localStorage.setItem("name", response.data.data.user.fullName);
                        localStorage.setItem("email", response.data.data.user.email);
                        localStorage.setItem("role", response.data.data.user.role);
                        this.store.token = response.data.data.token
                        this.fullName = "";
                        this.phone = "";
                        this.email = "";
                        this.password = "";
                        this.confirmPassword = "";
                        this.$router.push('/');
                    }
                }).catch((error) => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    })
                    console.log(error)
                })
            }
        },
        processGoogleLogin: async function (data) {
            let config = {
                method: "POST",
                data: data,
                url: this.$serverURL + this.$api.auth.googleLogIn,
            };

            await this.$axios(config).then((response) => {
                if (response.status == 201) {
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("id", response.data.user.id);
                    localStorage.setItem("name", response.data.user.fullName);
                    localStorage.setItem("email", response.data.user.email);
                    localStorage.setItem("role", response.data.user.role);
                    this.store.token = response.data.token
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfuly logged in!!",
                    });
                    this.$router.push('/')
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Google Login Failed!"
                    });
                }
            })
                .catch((error) => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
        },
        loginGoogle: async function () {
            await googleSdkLoaded(async (google) => {
                await google.accounts.oauth2.initTokenClient({
                    client_id: this.$googleSiteClientID,
                    scope: 'email profile openid',
                    callback: async (response) => {
                        //console.log("Handle the response", response.access_token)
                        //this.processGoogleLogin(response.code, google)

                        let config = {
                            method: "GET",
                            url: this.$api.auth.googleSDKHandler,
                            headers: {
                                'Authorization': 'Bearer ' + response.access_token
                            }
                        };

                        await this.$axios(config).then(async (respon) => {

                            let data = {
                                token: response.access_token,
                                email: respon.data.email,
                                fullName: respon.data.name
                            };

                            await this.processGoogleLogin(data);
                        });

                    }
                }).requestAccessToken()
            })
        },
    }
}