<template>
  <div :style="{'background-color': uiComponent.pageBackgroundColor}">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <section class="bg-site-gray-700 py-5" v-if="Object.values(cms).length > 0"
      :style="{'background-color': uiComponent.pageHeaderBackgroundColor + '!important'}">
      <div class="container text-center">
        <h1 class="text-site-black ff-heading h-fs-98 h-fs-md-98 h-fs-sm-69 fs-xs-27"
          :style="{'color': uiComponent.pageHeaderFontColor + '!important' }">{{cms.pageTitle}}</h1>
      </div>
    </section>
    <section class="py-3">
      <div class="container">
        <div class="d-flex flex-column flex-md-row align-items-center gap-3 mb-5">
          <h1 class="mb-0 ff-heading h-fs-69 w-100" :style="{'color': uiComponent.pageTitleFontColor + '!important' }">
            {{cms.headerTitle}}</h1>
          <p class="mb-0 ff-montserrat300 fs-16" :style="{'color': uiComponent.pageSubTitleFontColor + '!important' }">
            {{cms.headerSubTitle}}</p>
        </div>
        <div class="promotional-layout-section" v-if="cardList.length > 0">
          <div @click="$router.push('/gift-card-details/' + data.id)" v-for="(data, index) in cardList"
            class="card w-100 p-3 cp" :key="index">
            <img class="promotional-layout-section-image" style="height:400px" :src="data.image" :alt="data.name" />
            <div>
              <p class="mb-0 ff-montserrat500 fs-20">{{data.name}}</p>
              <p class="mb-0 ff-montserrat400 fs-18">USD: {{data.price}}</p>
            </div>
          </div>
        </div>
        <div v-else style="margin:0 auto;">
          <div class="alert alert-info" role="alert" style="text-align:center">No gift card found!</div>
        </div>
        <div class="text-center py-5" v-if="cardList.length > 0">
          <!-- <button class="btn bg-transparent border-site-black fs-15 ff-montserrat600 text-uppercase">load more</button> -->
          <nav class="page-navigation justify-content-center d-flex" aria-label="page-navigation">
            <!-- <v-pagination
              v-if="cardList.length > 0"
              v-model="page"
              @update:modelValue="updateHandler"
              :pages="totalPage"
              :range-size="1"
              active-color="#FF8400"
            ></v-pagination> -->
            <button class="btn align-self-stretch btn-outline-dark round_5 me-4" @click="previous"
              :disabled="page == 1"><i class="fa-solid fa-chevron-left"></i> Previous
            </button>
            <button class="btn align-self-stretch btn-outline-dark round_5" @click="next" :disabled="page == totalPage">Next <i
                class="fa-solid fa-chevron-right"></i>
            </button>
          </nav>
        </div>
      </div>
    </section>
  </div>
</template>

<script src="../js/gift_card.js">
</script>

<style scoped>
  @import url("../../../assets/pagination.css");
  @import "../css/gift_card.css";
</style>